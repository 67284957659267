/*
* ink colors (with variations only for black and white)
*/
.digital-product__card {
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
  position: relative; }
  .digital-product__card .service__name {
    position: absolute;
    left: 20%;
    top: 45%;
    text-transform: capitalize;
    color: #ffffff; }
