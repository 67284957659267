/*
* ink colors (with variations only for black and white)
*/
.cashback__form {
  color: #333333; }
  .cashback__form--item {
    margin-bottom: calc(0.75 * 1rem); }
    .cashback__form--item .item__label {
      font-weight: 600;
      color: red; }
