/*
* ink colors (with variations only for black and white)
*/
.beneficiaries__wrapper {
  padding-top: calc(0.75 * 1rem); }
  @media (min-width: 768px) {
    .beneficiaries__wrapper {
      padding: 0 calc(0.75 * 1rem); } }
  .beneficiaries__wrapper .member__form {
    padding-bottom: calc(0.75 * 1rem); }
  .beneficiaries__wrapper--header {
    border-bottom: 1px solid #dfdfdf;
    display: flex; }
    .beneficiaries__wrapper--header .header__title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500; }
    .beneficiaries__wrapper--header .header__icon {
      cursor: pointer;
      margin-left: auto;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #E81A1A; }
  .beneficiaries__wrapper--body {
    padding: calc(1.25 * 1rem) calc(0.75 * 1rem); }

@media (max-width: 768px) {
  .beneficiaries__button {
    margin-bottom: 180px; } }

.beneficiaries__button--add {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(0.25 * 1rem);
  padding: calc(1.25 * 1rem);
  font-weight: 500;
  transition: 0.3s;
  background: #ffffff;
  border: 1px solid #1D66DD;
  color: #1D66DD; }
  .beneficiaries__button--add:hover {
    background: #1D66DD;
    color: #ffffff; }
