/*
* ink colors (with variations only for black and white)
*/
.bengkel__wrapper {
  width: 100%; }
  .bengkel__wrapper--bottom {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    grid-gap: calc(2 * 1rem); }
    @media (max-width: 991.98px) {
      .bengkel__wrapper--bottom {
        grid-template-columns: 1fr;
        grid-gap: 0;
        padding: 0 !important; } }

.bengkel__footer {
  box-shadow: 0 0 4px 0 #dfdfdf; }

.bengkel__modal {
  width: 300px;
  margin: calc(1.25 * 1rem) auto; }
  .bengkel__modal--closeBtn {
    font-size: 42px;
    border: none;
    background: none;
    position: absolute;
    top: -10px;
    right: 5px; }
  .bengkel__modal--thank {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .bengkel__modal--thank img {
      margin: calc(1.25 * 1rem) 0;
      width: 120px;
      height: 120px; }
    .bengkel__modal--thank--title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      margin: calc(0.75 * 1rem) 0; }
    .bengkel__modal--thank--subtitle {
      text-align: center;
      margin-bottom: calc(0.75 * 1rem); }
    .bengkel__modal--thank--backBtn {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      border: none;
      margin-top: calc(0.75 * 1rem);
      width: 100%;
      padding: calc(0.75 * 1rem);
      background: #1D66DD;
      color: #ffffff;
      border-radius: calc(0.25 * 1rem); }

.bengkel__leadform__modal .modal-content {
  width: 340px;
  padding: 0;
  margin: 15% auto; }

.bengkel__leadform__modal--thank {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: calc(0.75 * 1rem); }
  .bengkel__leadform__modal--thank img {
    margin: calc(1.25 * 1rem) 0;
    width: 120px;
    height: 120px; }
  .bengkel__leadform__modal--thank--title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin: calc(0.75 * 1rem) 0; }
  .bengkel__leadform__modal--thank--subtitle {
    text-align: center;
    margin-bottom: calc(0.75 * 1rem); }
  .bengkel__leadform__modal--thank--okBtn {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    border: none;
    margin-top: calc(0.75 * 1rem);
    width: 100%;
    padding: calc(0.75 * 1rem);
    background: #1D66DD;
    color: #ffffff;
    border-radius: calc(0.25 * 1rem); }

.bengkel__banner {
  width: 100%;
  cursor: pointer; }
  .bengkel__banner img {
    width: 100%; }
