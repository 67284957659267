/*
* ink colors (with variations only for black and white)
*/
.registration__background {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: calc(3.25 * 1rem) 0;
  z-index: 99999; }

.registration__modal {
  position: absolute;
  top: 30px;
  margin-top: 34px;
  width: 340px;
  padding: unset;
  border-radius: calc(0.5 * 1rem);
  box-shadow: 0 1px 4px 0 rgba(51, 51, 51, 0.16);
  background: #1855B8; }
  .registration__modal .field__text {
    margin-bottom: calc(0.75 * 1rem); }
  .registration__modal .field__birthdate {
    display: grid;
    grid-gap: 3% !important;
    grid-template-columns: 31% 31% 31% !important;
    margin-top: calc(0.25 * 1rem);
    margin-bottom: 16px !important; }
    .registration__modal .field__birthdate select {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      width: 100%; }
      .registration__modal .field__birthdate select:hover {
        border: 1px solid #1D66DD; }
  .registration__modal .field__term {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: calc(0.75 * 1rem); }
    .registration__modal .field__term span {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400; }
      .registration__modal .field__term span a {
        color: #1D66DD; }
  .registration__modal .gender__label,
  .registration__modal .birthdate__label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; }
    .registration__modal .gender__label::after,
    .registration__modal .birthdate__label::after {
      content: "*";
      color: #E81A1A; }
  .registration__modal .lp-radio__wrapper {
    border: 1px solid #707070 !important;
    border-radius: 30px !important;
    margin: 0px calc(0.75 * 1rem) 16px 0 !important;
    padding: 4px 10px 4px 10px !important;
    height: 28px !important; }
    .registration__modal .lp-radio__wrapper label {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400; }
    .registration__modal .lp-radio__wrapper input {
      margin-bottom: 0; }
  .registration__modal input[type="tel"] {
    padding: 0 45px !important; }
  .registration__modal .error-msg {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #E81A1A; }
