/*
* ink colors (with variations only for black and white)
*/
.call-button-container {
  border-radius: 4px;
  padding: 14px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  align-self: stretch;
  color: #fff;
  border: none;
  width: 100%; }

.whatsapp-button {
  background: #1EC494; }
  @media (min-width: 768px) {
    .whatsapp-button {
      font-weight: 600 !important; } }

.callme-button {
  background: #1D66DD; }
  @media (min-width: 768px) {
    .callme-button {
      font-weight: 600 !important; } }
