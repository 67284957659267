/*
* ink colors (with variations only for black and white)
*/
.how-to-buy-wrapper {
  background-color: #E4EDFB;
  position: relative; }
  .how-to-buy-wrapper .how-to-buy-accordion-section {
    padding: 6px 0px; }
  .how-to-buy-wrapper .accordion-wrapper:last-of-type {
    padding-bottom: 16px; }
