/*
* ink colors (with variations only for black and white)
*/
.notification__wrapper {
  width: 100%;
  padding-right: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  padding-left: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  margin-right: auto;
  margin-left: auto;
  margin-top: calc(2 * 1rem);
  height: calc(100vh - 75px); }
  @media (min-width: 768px) {
    .notification__wrapper {
      max-width: 1140px; } }
  .notification__wrapper--row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .notification__wrapper--col {
    position: relative;
    width: 100%;
    padding-right: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    padding-left: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    flex: 0 0 70%;
    max-width: 70%; }
    @media (min-width: 768px) {
      .notification__wrapper--col {
        margin-left: 15%; } }
    @media (max-width: 991.98px) {
      .notification__wrapper--col {
        flex: 0 0 100%;
        max-width: 100%; } }
  .notification__wrapper--card {
    display: flex;
    flex-wrap: wrap;
    padding: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem));
    border-radius: calc(0.25 * 1rem);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border: solid 1px #d3d3d3;
    background-color: #ffffff; }
    .notification__wrapper--card .card__left {
      width: 35%; }
      @media (max-width: 991.98px) {
        .notification__wrapper--card .card__left {
          display: flex;
          justify-content: center;
          width: 100%; } }
      .notification__wrapper--card .card__left--image {
        width: 200px !important;
        height: 180px !important; }
    .notification__wrapper--card .card__right {
      display: flex;
      align-items: center;
      width: 65%; }
      .notification__wrapper--card .card__right p.body {
        color: #717171; }
      @media (max-width: 991.98px) {
        .notification__wrapper--card .card__right {
          justify-content: center;
          width: 100%; }
          .notification__wrapper--card .card__right p {
            text-align: center; } }
