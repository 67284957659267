/*
* ink colors (with variations only for black and white)
*/
.credit-card__form {
  margin-top: calc(0.75 * 1rem); }
  .credit-card__form--item {
    margin: calc(2 * 1rem) 0; }
    .credit-card__form--item .cc-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .credit-card__form--item--expiry {
      display: flex; }
      .credit-card__form--item--expiry .field.FieldForm {
        width: 100%;
        margin: 0; }
      .credit-card__form--item--expiry span {
        margin-left: calc(calc(0.5 * 1rem) + calc(0.25 * 1rem));
        margin-top: calc(0.75 * 1rem);
        color: #dfdfdf; }
      .credit-card__form--item--expiry .field:nth-last-child(1) {
        margin-left: calc(calc(0.5 * 1rem) + calc(0.25 * 1rem)); }
  .credit-card__form--premium {
    padding: calc(0.75 * 1rem) calc(1.25 * 1rem);
    margin: calc(2 * 1rem) 0;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgba(68, 68, 68, 0.1); }
    .credit-card__form--premium p {
      font-weight: bold;
      background-image: linear-gradient(to right, #2c95f6, #844a8d 100%, #9b4572 100%, #d6362b 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .credit-card__form--button {
    width: 100% !important;
    margin-top: calc(0.75 * 1rem); }
  .credit-card__form p {
    margin-bottom: 0; }
  .credit-card__form .cc__brand--logo {
    max-height: 25px;
    width: auto;
    margin-left: calc(0.75 * 1rem); }
