/*
* ink colors (with variations only for black and white)
*/
.auto__specifications {
  border-radius: calc(0.25 * 1rem);
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  margin-bottom: calc(1.25 * 1rem); }
  .auto__specifications--header {
    display: flex;
    align-items: center;
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(1.25 * 1rem);
    font-weight: 500; }
    .auto__specifications--header .header__arrow {
      margin-left: auto;
      font-size: calc(1.25 * 1rem);
      color: #1D66DD;
      cursor: pointer; }
    .auto__specifications--header.open {
      border-bottom: 1px solid #c2c2c2; }
  .auto__specifications--body {
    padding: calc(1.25 * 1rem); }
    .auto__specifications--body .body__item {
      margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
      .auto__specifications--body .body__item span {
        display: block; }
      .auto__specifications--body .body__item--label {
        font-weight: 500;
        color: #333333; }
    .auto__specifications--body .body__info--child .child__error {
      color: #E81A1A;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400; }
    .auto__specifications--body .body__info--label {
      display: flex;
      align-items: center;
      margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
      .auto__specifications--body .body__info--label p {
        font-weight: bold;
        margin-bottom: 0;
        margin-right: calc(0.5 * 1rem); }
      .auto__specifications--body .body__info--label .lifepal-edit {
        cursor: pointer;
        color: #b6b6b6; }
      .auto__specifications--body .body__info--label .label__action {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-right: calc(0.5 * 1rem);
        cursor: pointer;
        color: #1D66DD; }
        .auto__specifications--body .body__info--label .label__action:last-child {
          color: #E81A1A; }
