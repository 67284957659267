/*
* ink colors (with variations only for black and white)
*/
.how-to-buy-hero-wrapper {
  display: flex;
  justify-content: center;
  padding: 16px;
  background-image: linear-gradient(to bottom, #4380E3, #1D66DD, #1855B8); }
  @media (min-width: 576px) {
    .how-to-buy-hero-wrapper {
      padding: 24px 0px; } }
  .how-to-buy-hero-wrapper .how-to-buy-hero-container {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 576px) {
      .how-to-buy-hero-wrapper .how-to-buy-hero-container {
        width: 1120px;
        margin: 0 auto; } }
    .how-to-buy-hero-wrapper .how-to-buy-hero-container .title {
      font-size: 20px;
      font-weight: 700;
      color: white;
      margin: unset; }
      @media (min-width: 576px) {
        .how-to-buy-hero-wrapper .how-to-buy-hero-container .title {
          font-size: 48px; } }
    .how-to-buy-hero-wrapper .how-to-buy-hero-container .description {
      font-size: 14px;
      margin-top: 6px;
      color: white;
      font-weight: 400;
      margin-bottom: 0px; }
      @media (min-width: 576px) {
        .how-to-buy-hero-wrapper .how-to-buy-hero-container .description {
          font-size: 20px; } }
