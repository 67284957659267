/*
* ink colors (with variations only for black and white)
*/
.payment-info__view--header {
  padding: calc(0.75 * 1rem) 0;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: calc(0.75 * 1rem); }
  .payment-info__view--header .header__item {
    display: flex; }
    .payment-info__view--header .header__item span {
      width: 50%; }

.payment-info__view--body {
  margin-bottom: calc(2 * 1rem); }
  .payment-info__view--body .body__text {
    margin-bottom: calc(2 * 1rem); }

.payment-info__view--footer .footer__button {
  width: 100%; }
