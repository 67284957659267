/*
* ink colors (with variations only for black and white)
*/
.agent__banner {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.75 * 1rem);
  margin-bottom: calc(1.25 * 1rem);
  display: flex;
  align-items: flex-end;
  overflow: hidden; }
  .agent__banner--icon {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .agent__banner--icon {
        width: 20%; } }
  .agent__banner--info {
    width: 75%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    transform-origin: bottom left;
    transform: skew(-30deg);
    display: flex;
    justify-content: center;
    padding: 0 calc(2 * 1rem);
    align-items: center;
    min-height: 50px; }
    @media (max-width: 991.98px) {
      .agent__banner--info {
        width: 80%; } }
    .agent__banner--info .info__wrapper {
      transform: skew(30deg);
      color: #ffffff;
      padding: calc(0.5 * 1rem); }
      .agent__banner--info .info__wrapper span {
        display: block;
        font-weight: bold; }
