/*
* ink colors (with variations only for black and white)
*/
.balance__info {
  display: inline-flex;
  flex-direction: column;
  padding: 0 calc(0.75 * 1rem) calc(0.75 * 1rem); }
  .balance__info--summary {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: calc(0.5 * 1rem); }
    .balance__info--summary:first-child {
      margin: calc(0.5 * 1rem) 0; }
  .balance__info .withdraw {
    background: #f2f7ff;
    border-radius: 4px; }
    .balance__info .withdraw span {
      color: #1D66DD; }
  .balance__info button {
    margin-top: calc(0.75 * 1rem);
    padding: calc(0.5 * 1rem); }
