/*
* ink colors (with variations only for black and white)
*/
.kyc__id__card {
  width: 100%;
  padding: calc(0.75 * 1rem) 0;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24); }
  .kyc__id__card .head {
    padding: calc(0.75 * 1rem) 0;
    border-bottom: 1px dotted #333333; }
    .kyc__id__card .head img {
      width: 40px;
      height: 40px; }
    .kyc__id__card .head .title {
      font-weight: bold;
      margin-left: calc(0.75 * 1rem); }
  .kyc__id__card .ktp__number__section {
    padding: calc(0.75 * 1rem) 0;
    display: flex;
    flex-direction: column; }
    .kyc__id__card .ktp__number__section label {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500; }
    .kyc__id__card .ktp__number__section input {
      padding: calc(0.5 * 1rem);
      border-radius: calc(0.25 * 1rem);
      border: 1px solid #c2c2c2; }
  .kyc__id__card .ktp__section {
    padding: calc(0.75 * 1rem) 0; }
    .kyc__id__card .ktp__section .title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: calc(0.75 * 1rem); }
    .kyc__id__card .ktp__section .img__wrap {
      width: 50%;
      margin: 0 25%; }
    .kyc__id__card .ktp__section button {
      border: none;
      border-radius: calc(0.5 * 1rem);
      width: 50%;
      margin: 0 25%; }
