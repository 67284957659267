/*
* ink colors (with variations only for black and white)
*/
.policyMobile__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .policyMobile__wrapper--info {
    padding: calc(1.25 * 1rem);
    display: flex;
    border-bottom: 1px solid #dfdfdf; }
    .policyMobile__wrapper--info .head__item {
      width: 50%; }
      .policyMobile__wrapper--info .head__item .pull-right {
        float: right; }
  .policyMobile__wrapper--body {
    padding: calc(1.25 * 1rem);
    border-bottom: 1px solid #dfdfdf; }
    .policyMobile__wrapper--body .body__item {
      display: flex; }
      .policyMobile__wrapper--body .body__item--left {
        width: 30%; }
      .policyMobile__wrapper--body .body__item--right {
        width: 70%; }
        .policyMobile__wrapper--body .body__item--right .category__name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #b6b6b6; }
    .policyMobile__wrapper--body .body__button {
      border: 1px solid #717171;
      background: #ffffff;
      width: 100%; }
