/*
* ink colors (with variations only for black and white)
*/
.benefits__form {
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0; }
  .benefits__form--row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: calc(0.75 * 1rem); }
    .benefits__form--row .range__col {
      width: 100%;
      padding: 0 calc(0.5 * 1rem);
      margin-bottom: calc(0.75 * 1rem); }
    .benefits__form--row .summaryPrice__col {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: calc(0.75 * 1rem); }
      .benefits__form--row .summaryPrice__col .DropdownForm, .benefits__form--row .summaryPrice__col .InputForm {
        width: 100%; }
        @media (min-width: 768px) {
          .benefits__form--row .summaryPrice__col .DropdownForm, .benefits__form--row .summaryPrice__col .InputForm {
            width: 235px; } }
      .benefits__form--row .summaryPrice__col--label {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500; }
    .benefits__form--row .questions__col {
      width: 48%;
      margin-bottom: calc(0.75 * 1rem); }
      @media (max-width: 991.98px) {
        .benefits__form--row .questions__col {
          width: 100%;
          padding: 0 calc(0.5 * 1rem); } }
      .benefits__form--row .questions__col .DropdownForm {
        margin-top: calc(0.75 * 1rem);
        margin-bottom: calc(0.75 * 1rem); }
