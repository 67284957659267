.locked__user--container {
  padding: 8px 0px 8px 12px;
  border: 1px solid #F44336;
  border-radius: 5px;
  background-color: #FFEBEE;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row; }
  .locked__user--container p {
    margin: 0px 15px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px; }
  .locked__user--container .subtitle {
    font-size: 12px;
    color: #EA4B4B;
    margin-top: 4px; }
  .locked__user--container .icon {
    width: 16px;
    height: 16px;
    align-self: center; }
