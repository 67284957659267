/*
* ink colors (with variations only for black and white)
*/
.payment-card__wrapper {
  padding: 0 !important; }

.paymentDetails__wrapper {
  border-radius: calc(0.5 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .paymentDetails__wrapper--header {
    padding: calc(0.75 * 1rem);
    border-bottom: 1px solid #dfdfdf; }
  .paymentDetails__wrapper--body {
    padding: 0 !important; }
    .paymentDetails__wrapper--body .body__item {
      padding: calc(0.75 * 1rem);
      border-bottom: 1px solid #dfdfdf; }
      @media (max-width: 991.98px) {
        .paymentDetails__wrapper--body .body__item.last {
          border-bottom: none; } }
      .paymentDetails__wrapper--body .body__item--info {
        text-align: end; }
    .paymentDetails__wrapper--body .body--blue {
      background-color: rgba(29, 102, 221, 0.04); }
    .paymentDetails__wrapper--body .border-none {
      border: 0; }
  .paymentDetails__wrapper--footer {
    padding: calc(1.25 * 1rem) calc(calc(0.5 * 1rem) + calc(1.25 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
    .paymentDetails__wrapper--footer .button {
      width: 100%;
      height: 40px; }
      .paymentDetails__wrapper--footer .button-save {
        background-color: #ffffff;
        border-radius: calc(0.25 * 1rem); }
      .paymentDetails__wrapper--footer .button-cancel {
        border: none;
        background: #ffffff;
        color: #E81A1A; }

.charges__description:not(:last-child) {
  margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
