/*
* ink colors (with variations only for black and white)
*/
.price__radio--header {
  display: flex;
  align-items: center; }
  .price__radio--header h1 {
    width: 90%; }
  .price__radio--header .DropdownForm {
    width: 100%;
    margin-left: calc(0.5 * 1rem); }
    .price__radio--header .DropdownForm .css-1li0unn-control,
    .price__radio--header .DropdownForm .css-1jgn1w8-control,
    .price__radio--header .DropdownForm .css-g1d714-ValueContainer,
    .price__radio--header .DropdownForm .css-1hb7zxy-IndicatorsContainer {
      height: calc(2 * 1rem);
      min-height: calc(2 * 1rem); }

.price__radio .lp-radio__wrapper:not(:last-child) {
  margin-bottom: calc(0.5 * 1rem); }

.price__radio .lp-radio__wrapper label {
  margin-bottom: 0; }
