/*
* ink colors (with variations only for black and white)
*/
.accordion-wrapper {
  background-color: white;
  padding: 16px 16px 0px 16px; }
  @media (min-width: 576px) {
    .accordion-wrapper {
      padding-top: 16px; } }
  .accordion-wrapper .accordion-container {
    display: flex;
    width: 100%; }
    @media (min-width: 576px) {
      .accordion-wrapper .accordion-container {
        justify-content: center;
        width: 1120px;
        margin: 0 auto; } }
    .accordion-wrapper .accordion-container .accordion-section {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .accordion-wrapper .accordion-container .accordion-section .accordion {
        padding: 16px 12px;
        border-bottom: 1px solid #D6D6D6;
        display: flex;
        flex-direction: column;
        transition: all 300ms linear; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .accordion-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .accordion-header .accordion-label {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 16px;
            color: #222222; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .accordion-header .accordion-icon {
            height: 20px;
            width: 20px; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .carousel__inner-slide {
          width: 220px !important; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .carousel__slide {
          width: 220px !important; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card {
          display: flex;
          flex-direction: column;
          background-color: white;
          height: 296px;
          width: 204px;
          border-radius: 8px;
          border: 1px solid #D6D6D6; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .carousel-content {
            padding: 0px 16px; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .image {
            width: 120px; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .text {
            font-size: 12px;
            font-weight: 400;
            margin-top: 22px;
            color: #222222; }
          .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .corner-frame-container {
            position: relative;
            text-align: right;
            color: white; }
            .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .corner-frame-container .corner-frame-image {
              width: 41px;
              height: 36px;
              border-top-right-radius: 8px; }
            .accordion-wrapper .accordion-container .accordion-section .accordion .carousel-card .corner-frame-container .corner-number {
              position: absolute;
              top: 8px;
              right: 12px;
              font-size: 14px;
              color: white;
              background-color: #1D66DD;
              width: 10px; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .document-title {
          font-size: 14px;
          font-weight: bold;
          margin-top: 16px;
          color: #222222; }
        .accordion-wrapper .accordion-container .accordion-section .accordion .document-subtitle {
          white-space: pre-line;
          color: #222222; }
      .accordion-wrapper .accordion-container .accordion-section .active {
        background-color: #F5F5F5; }
