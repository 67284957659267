/*
* ink colors (with variations only for black and white)
*/
.media-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 32px 16px; }
  @media (min-width: 576px) {
    .media-wrapper {
      padding: 48px 0; } }
  .media-wrapper .media-container {
    width: 100%; }
    @media (min-width: 576px) {
      .media-wrapper .media-container {
        width: 1120px;
        margin: 0 auto; } }
    .media-wrapper .media-container .media-title {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      width: 100%; }
      @media (min-width: 576px) {
        .media-wrapper .media-container .media-title {
          font-size: 28px; } }
    .media-wrapper .media-container .media-content-web {
      display: none; }
      @media (min-width: 576px) {
        .media-wrapper .media-container .media-content-web {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 24px;
          width: 100%; } }
      .media-wrapper .media-container .media-content-web .media-img-web {
        width: 100%;
        height: auto; }
    .media-wrapper .media-container .media-content-mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 16px;
      width: 100%; }
      @media (min-width: 576px) {
        .media-wrapper .media-container .media-content-mobile {
          display: none; } }
      .media-wrapper .media-container .media-content-mobile .media-img-mobile {
        height: auto;
        width: 100%; }
