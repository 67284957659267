/*
* ink colors (with variations only for black and white)
*/
.callme-modal-content {
  padding: unset !important;
  background: linear-gradient(180deg, #4380E3 0%, #1D66DD 48.5%, #1855B8 100%); }

.call-button-container {
  border-radius: 4px;
  padding: 14px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  align-self: stretch;
  color: #fff;
  border: none;
  width: 100%; }

.whatsapp-button {
  background: #1EC494; }
  @media (min-width: 768px) {
    .whatsapp-button {
      font-weight: 600 !important; } }

.callme-button {
  background: #1D66DD; }
  @media (min-width: 768px) {
    .callme-button {
      font-weight: 600 !important; } }

.cm-body .btn-red {
  background-color: #EA4B4B;
  border: unset; }

.cm-header-logo {
  display: flex;
  flex-direction: column; }
  .cm-header-logo .check_logo {
    align-self: center; }

.subtext-wrapper {
  gap: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .subtext-wrapper .subtext-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left; }
  .subtext-wrapper .subtext-icon {
    height: 12px;
    width: 12px; }

.lead__callme_group {
  margin-bottom: 12px; }
  .lead__callme_group ::placeholder {
    color: #141414; }

.cm-announce-wrapper {
  background: #E4EDFB;
  display: flex;
  flex-direction: row;
  width: 288px;
  padding: 4px;
  gap: 4px;
  border-radius: 4px;
  margin-bottom: 10px; }
  .cm-announce-wrapper .cm-announce-text {
    color: #1855B8;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center; }

.cm-non-mv-text {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 2px 6px 10px 6px; }
