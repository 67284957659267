/*
* ink colors (with variations only for black and white)
*/
@media (max-width: 991.98px) {
  .digital-product__notes {
    border-bottom: 1px solid #dfdfdf; } }

.digital-product__notes--row {
  display: flex;
  width: 100%; }
  .digital-product__notes--row:not(:last-child) {
    margin-bottom: calc(0.75 * 1rem); }
  .digital-product__notes--row .row__label {
    width: 40%; }
  .digital-product__notes--row .row__value {
    width: 60%; }
