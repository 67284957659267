/*
* ink colors (with variations only for black and white)
*/
.col-small .card {
  padding: 0 !important; }
  @media (max-width: 991.98px) {
    .col-small .card {
      border: none; } }
  .col-small .card > div:not(.box-divider-2) {
    padding: 16px; }

.digital__product--voucher .box__discount {
  margin-bottom: 0 !important; }

.responsive__checkout {
  width: 30vw;
  margin: 20px auto; }
  @media (max-width: 991.98px) {
    .responsive__checkout {
      width: 100vw;
      margin: 0; }
      .responsive__checkout .border-radius-xs {
        border-radius: 0 !important; } }
  .responsive__checkout .card {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25); }
    @media (max-width: 991.98px) {
      .responsive__checkout .card {
        box-shadow: none; } }
    @media (min-width: 768px) {
      .responsive__checkout .card {
        border: none; } }
  .responsive__checkout .checkout-shadow {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) !important; }
  .responsive__checkout .container {
    width: 100%;
    padding: 0; }
  .responsive__checkout .minified__view {
    margin: 0 calc(0.5 * 1rem);
    padding: calc(0.75 * 1rem) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.5 * 1rem); }
    .responsive__checkout .minified__view--header {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .responsive__checkout .minified__view.error {
      border: 1px solid #E81A1A; }
    .responsive__checkout .minified__view.disabled {
      background-color: #f7f7f7;
      color: #c2c2c2;
      pointer-events: none;
      cursor: not-allowed; }
      .responsive__checkout .minified__view.disabled p {
        color: #c2c2c2 !important; }
    .responsive__checkout .minified__view .minified__left--view {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
    .responsive__checkout .minified__view .minified__right--view {
      color: #1D66DD;
      cursor: pointer; }

.checkout__app-header {
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .checkout__app-header {
      display: none; } }
  .checkout__app-header .button-back {
    flex-grow: 1;
    align-self: center; }
    .checkout__app-header .button-back i {
      vertical-align: middle; }
  .checkout__app-header .title-page {
    flex-grow: 1.5; }
  .checkout__app-header .button-save {
    color: #1D66DD;
    cursor: pointer; }

.payment__term--checkout {
  padding: calc(0.75 * 1rem) !important; }

.checkout {
  margin-bottom: calc(2 * 1rem); }
  @media (max-width: 991.98px) {
    .checkout {
      margin-bottom: 100px; } }
  .checkout .title-page {
    font-size: 22px;
    font-weight: bold;
    color: #333333; }
  .checkout .box-checkout {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .checkout .box-premi p:first-child {
    font-weight: bold;
    font-size: 12px;
    margin: 0; }
  .checkout .box-divider {
    padding: calc(0.75 * 1rem); }
    .checkout .box-divider span.Label::before {
      margin-right: 15px !important; }
  .checkout .box-divider-2 {
    padding: 0 30px; }
  .checkout .box-discount {
    padding: 10px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    color: #1D66DD; }
    .checkout .box-discount .ion-md-close {
      color: #c2c2c2; }
  .checkout .clickable {
    cursor: pointer; }
  .checkout .not-allowed {
    cursor: not-allowed; }
  .checkout .inline-text {
    display: inline; }
  .checkout .icon-back {
    font-size: 20px;
    color: #1d66dd; }
  .checkout .text-back {
    font-size: 14px;
    font-weight: 500; }
  .checkout .text-bold {
    font-weight: bold; }
  .checkout .text-primary {
    color: #1D66DD; }
  .checkout .text-error {
    color: #ea4b4b; }
  .checkout .button-back {
    cursor: pointer;
    color: #1D66DD; }
  .checkout .pos-right {
    float: right; }
  .checkout .green {
    color: #00bd56; }
  .checkout .title-display {
    font-size: 12px;
    font-weight: bold; }
  .checkout .price-text {
    font-size: 22px;
    font-weight: bold;
    color: #1D66DD; }
  .checkout .text-capital {
    text-transform: capitalize; }
  .checkout .btn-cancel {
    background-color: white;
    border: none;
    color: #e81a1a; }
    .checkout .btn-cancel.disabled {
      cursor: no-drop; }
  .checkout hr {
    margin: 0; }
  .checkout .tab-off, .checkout .tab-on {
    padding: 10px 0;
    cursor: pointer; }
  .checkout .payment-link {
    text-align: center;
    color: #333333;
    margin-bottom: 200px; }
    .checkout .payment-link--header {
      padding: 15px 0; }
    .checkout .payment-link--payment-support {
      padding: 15px 0;
      margin-bottom: 10px; }
      .checkout .payment-link--payment-support p {
        margin-bottom: 10px; }
      .checkout .payment-link--payment-support .images-list {
        margin: 0;
        display: flex;
        max-width: 320px;
        justify-content: space-evenly;
        flex-wrap: wrap; }
        .checkout .payment-link--payment-support .images-list img {
          margin: 5px;
          max-height: 19px; }
  .checkout .tab-on {
    border-bottom: 1px solid #1D66DD; }
  @media (max-width: 768px) {
    .checkout .col-small {
      padding: 0 !important; }
    .checkout .price-text {
      font-size: 16px;
      font-weight: bold;
      color: #1D66DD; } }
  @media (min-width: 768px) {
    .checkout .tab-off, .checkout .tab-on {
      padding: 20px 0;
      cursor: pointer;
      margin-right: 25px; } }
  .checkout .CheckboxForm .Wrapper {
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-flow: wrap; }

.policy__member--checkout {
  display: grid;
  align-items: center;
  grid-template-columns: 0.4fr 0.6fr; }
  .policy__member--checkout p {
    text-align: start; }

.checkout__sub--view {
  position: relative;
  top: 0;
  max-height: 100%;
  background: #fff;
  z-index: 999;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #c2c2c2;
  width: 30vw; }
  @media (max-width: 991.98px) {
    .checkout__sub--view {
      width: 100%; } }

.cancel__confirmation--modal .modal__wrapper--header .header__left h4 {
  color: #333333;
  font-size: 20px; }

.cancel__confirmation--modal .lp-button-ghost {
  border: 1px solid #1D66DD; }

.cancel__confirmation--modal .btn-text-primary {
  color: #1D66DD; }
