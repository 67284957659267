/*
* ink colors (with variations only for black and white)
*/
.upload__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .upload__placeholder img {
    height: 160px !important;
    width: 160px !important; }
  .upload__placeholder .dummy__placeholder {
    height: 160px;
    width: 160px;
    border: 2px solid #1D66DD;
    border-radius: calc(1.25 * 1rem); }
  .upload__placeholder .upload__icon--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #1D66DD;
    text-align: center; }
  .upload__placeholder .dummy__icon {
    background-color: #1D66DD;
    color: #ffffff; }
  .upload__placeholder .mock__upload {
    display: none; }
