/*
* ink colors (with variations only for black and white)
*/
.content__card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .content__card--header {
    padding: calc(1.25 * 1rem);
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .content__card--header .header__title {
      width: 70%; }
    .content__card--header .header__button {
      width: 30%; }
  .content__card--body {
    padding: calc(0.5 * 1rem); }
    @media (max-width: 991.98px) {
      .content__card--body {
        padding: calc(0.75 * 1rem); } }
