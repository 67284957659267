/*
* ink colors (with variations only for black and white)
*/
.consult-panel-container {
  border-radius: 8px;
  background: linear-gradient(245.39deg, #4380E3 0%, #1855B8 50%, #134493 100%);
  box-shadow: 0px 2px 8px 0px rgba(0, 58, 109, 0.24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 360px; }
  .consult-panel-container .cp-top {
    padding: 12px; }
  .consult-panel-container .cp-label {
    color: white;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding-right: 138px;
    white-space: pre-wrap;
    height: 60px; }
    .consult-panel-container .cp-label img {
      position: absolute;
      top: -44px;
      right: 12px; }
  .consult-panel-container .cp-content {
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 12px;
    align-items: center;
    background: linear-gradient(245.39deg, #4380E3 0%, #1855B8 50%, #134493 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 40px 0px #00000029;
    flex-direction: column; }
    .consult-panel-container .cp-content .benefit-list {
      padding: 0px 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6px; }
      .consult-panel-container .cp-content .benefit-list .benefit-item {
        gap: 2px;
        display: flex;
        align-items: center; }
        .consult-panel-container .cp-content .benefit-list .benefit-item .benefit-item-label {
          font-size: 12px;
          color: white;
          font-weight: 400; }
        .consult-panel-container .cp-content .benefit-list .benefit-item .benefit-item-icon {
          height: 12px;
          width: 12px; }
  .consult-panel-container .cp-buttons {
    display: flex;
    width: 100%;
    gap: 12px;
    flex-direction: column; }
    .consult-panel-container .cp-buttons .callme-button {
      background: #E4EDFB;
      color: #1D66DD;
      font-weight: 700 !important;
      font-size: 16px; }
      .consult-panel-container .cp-buttons .callme-button img {
        filter: invert(27%) sepia(96%) saturate(1179%) hue-rotate(198deg) brightness(104%) contrast(103%);
        color: #1D66DD; }
    .consult-panel-container .cp-buttons .whatsapp-button {
      font-weight: 700 !important;
      font-size: 16px; }
