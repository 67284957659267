/*
* ink colors (with variations only for black and white)
*/
.stickyWhatsapp {
  text-decoration: none;
  position: fixed;
  height: 56px;
  width: 330px;
  bottom: 8px;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  z-index: 1;
  right: 16px;
  justify-content: space-between;
  flex-direction: column; }
  @media (min-width: 992px) {
    .stickyWhatsapp {
      bottom: 16px;
      right: 40px; } }
  .stickyWhatsapp .cs-logo {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: white;
    border: 3px solid #134493;
    position: relative; }
  .stickyWhatsapp .wrap_button {
    background-color: #1855b8;
    width: 310px;
    height: 40px;
    display: flex;
    padding-left: 8px;
    padding-right: 16px;
    margin-top: 10px;
    gap: 8px;
    right: 0px;
    position: absolute;
    border-radius: 8px;
    flex-direction: row;
    justify-content: right; }
    .stickyWhatsapp .wrap_button .cs-title {
      font-size: 12px;
      color: white;
      font-weight: 400;
      margin-top: 10px; }
    .stickyWhatsapp .wrap_button .cs-divider {
      color: white;
      font-weight: 700;
      margin-top: 10px;
      font-size: 12px; }
    .stickyWhatsapp .wrap_button .img_headset {
      cursor: pointer;
      display: flex;
      width: 32px;
      margin-top: 6px;
      height: 28px;
      padding-top: 2px;
      border-radius: 4px;
      justify-content: center;
      background-color: white; }
      .stickyWhatsapp .wrap_button .img_headset:hover {
        background-color: #e4edfb; }
      .stickyWhatsapp .wrap_button .img_headset:active {
        background-color: #8eb2ee; }
      .stickyWhatsapp .wrap_button .img_headset .img_icon {
        width: 18px;
        height: 16px;
        filter: invert(27%) sepia(96%) saturate(1179%) hue-rotate(198deg) brightness(104%) contrast(103%); }
    .stickyWhatsapp .wrap_button .img_wa {
      display: flex;
      cursor: pointer;
      width: 32px;
      height: 28px;
      margin-top: 6px;
      padding-top: 4px;
      border-radius: 4px;
      justify-content: center;
      background-color: #1ec494; }
      .stickyWhatsapp .wrap_button .img_wa:hover {
        background-color: #19a37b; }
      .stickyWhatsapp .wrap_button .img_wa:active {
        background-color: #148363; }
      .stickyWhatsapp .wrap_button .img_wa .img_icon {
        width: 20px;
        height: 20px; }
