/*
* ink colors (with variations only for black and white)
*/
.checkup__wrapper--leads {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background: #1D66DD;
  border-radius: calc(0.25 * 1rem);
  height: max-content;
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .checkup__wrapper--leads .leads__header {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .checkup__wrapper--leads .leads__header--title {
      font-size: 22px;
      line-height: 24px;
      font-weight: 700;
      color: #ffffff;
      margin: calc(0.25 * 1rem) 0; }
    .checkup__wrapper--leads .leads__header p:last-child {
      color: #ffffff; }
  .checkup__wrapper--leads .leads__body {
    margin-bottom: calc(1.25 * 1rem); }
    .checkup__wrapper--leads .leads__body span {
      color: #ffffff; }
  .checkup__wrapper--leads .leads__footer--button {
    width: 100%;
    background: #ef6767;
    color: #ffffff; }

.CheckupCalculationContainer .CheckupTab {
  border-right: 1px solid #dfdfdf;
  height: 100%; }
  .CheckupCalculationContainer .CheckupTab--ContainerNormal {
    display: inherit; }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupTab--ContainerNormal {
        border-bottom: 1px solid #dfdfdf; } }
  .CheckupCalculationContainer .CheckupTab--ContainerResult {
    display: inherit; }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupTab--ContainerResult {
        padding: 15px 15px 0; } }
  @media (max-width: 767.98px) {
    .CheckupCalculationContainer .CheckupTab {
      display: flex;
      flex-direction: row;
      overflow: auto;
      border: none; } }
  .CheckupCalculationContainer .CheckupTab--Menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    color: #c2c2c2;
    cursor: pointer; }
    .CheckupCalculationContainer .CheckupTab--Menu i {
      margin-right: 20px;
      font-size: 11px;
      border: 1px solid #c2c2c2;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      text-align: center;
      font-style: normal; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupTab--Menu i {
          flex-basis: 100%; } }
    .CheckupCalculationContainer .CheckupTab--Menu p {
      margin: 0;
      color: #c2c2c2; }
    .CheckupCalculationContainer .CheckupTab--Menu.Completed i {
      margin-right: 20px;
      font-size: 20px;
      border: none;
      height: auto;
      color: #333333; }
    .CheckupCalculationContainer .CheckupTab--Menu.Completed p {
      color: #333333; }
    .CheckupCalculationContainer .CheckupTab--Menu.Active {
      border-left: 4px solid #1d66dd; }
      .CheckupCalculationContainer .CheckupTab--Menu.Active i {
        border-color: #1d66dd;
        margin-left: -4px; }
      .CheckupCalculationContainer .CheckupTab--Menu.Active i, .CheckupCalculationContainer .CheckupTab--Menu.Active p {
        color: #1d66dd; }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupTab--Menu {
        padding: 20px 15px; }
        .CheckupCalculationContainer .CheckupTab--Menu i {
          margin-right: 10px; }
        .CheckupCalculationContainer .CheckupTab--Menu.Active {
          border: none;
          border-bottom: 1px solid #1d66dd; }
          .CheckupCalculationContainer .CheckupTab--Menu.Active i, .CheckupCalculationContainer .CheckupTab--Menu.Active p {
            color: #1d66dd; } }
  .CheckupCalculationContainer .CheckupTab--MenuResult.Active p {
    margin-left: -4px; }
  @media (max-width: 767.98px) {
    .CheckupCalculationContainer .CheckupTab--MenuResult {
      height: 5px;
      width: 30px;
      padding: 0px;
      border-radius: 5px;
      background-color: #e4e4e4;
      margin: 0 5px; }
      .CheckupCalculationContainer .CheckupTab--MenuResult:first-child {
        margin-left: 0; }
      .CheckupCalculationContainer .CheckupTab--MenuResult:last-child {
        margin-right: 0; }
      .CheckupCalculationContainer .CheckupTab--MenuResult.Active {
        background-color: #1d66dd; }
      .CheckupCalculationContainer .CheckupTab--MenuResult p {
        display: none; } }

.CheckupCalculationContainer .CheckupResult {
  padding: calc(0.5 * 1rem); }
  .CheckupCalculationContainer .CheckupResult .accordion .card:first-child {
    border-top: none; }
  .CheckupCalculationContainer .CheckupResult .accordion .card:last-child {
    border-bottom: none; }
  .CheckupCalculationContainer .CheckupResult .result__block {
    margin-bottom: calc(0.75 * 1rem); }
  .CheckupCalculationContainer .CheckupResult--Title {
    display: flex;
    width: 100%; }
    @media (max-width: 991.98px) {
      .CheckupCalculationContainer .CheckupResult--Title {
        flex-direction: column; } }
    .CheckupCalculationContainer .CheckupResult--Title .title__left {
      width: 60%; }
      @media (max-width: 991.98px) {
        .CheckupCalculationContainer .CheckupResult--Title .title__left {
          width: 85%; } }
    .CheckupCalculationContainer .CheckupResult--Title .title__right {
      display: flex;
      width: 40%; }
      @media (max-width: 991.98px) {
        .CheckupCalculationContainer .CheckupResult--Title .title__right {
          width: 100%;
          margin-top: calc(0.5 * 1rem); } }
      @media (min-width: 768px) {
        .CheckupCalculationContainer .CheckupResult--Title .title__right--item {
          width: 50%; } }
      @media (max-width: 991.98px) {
        .CheckupCalculationContainer .CheckupResult--Title .title__right--item:last-child {
          margin-left: 8px; } }
      .CheckupCalculationContainer .CheckupResult--Title .title__right .item__badge {
        width: 80px;
        display: flex;
        justify-content: center; }
  .CheckupCalculationContainer .CheckupResult--Header .stepTitle {
    font-size: 16px;
    color: #c2c2c2;
    font-weight: bold;
    margin: 0; }
  .CheckupCalculationContainer .CheckupResult--Header h3 {
    color: #333333;
    font-size: 14px;
    font-weight: bold; }
  .CheckupCalculationContainer .CheckupResult--Header p {
    color: #717171; }
  .CheckupCalculationContainer .CheckupResult--Header .ResultValue {
    display: flex;
    flex-direction: row; }
    .CheckupCalculationContainer .CheckupResult--Header .ResultValue .ConditionStatus--Good {
      color: #1ec494; }
    .CheckupCalculationContainer .CheckupResult--Header .ResultValue .ConditionStatus--Bad {
      color: #e81a1a; }
    .CheckupCalculationContainer .CheckupResult--Header .ResultValue p {
      flex-basis: 50%;
      font-weight: 500;
      color: #333333; }
      .CheckupCalculationContainer .CheckupResult--Header .ResultValue p span {
        font-weight: normal; }
  @media (max-width: 767.98px) {
    .CheckupCalculationContainer .CheckupResult--Header h3 {
      font-size: 16px; }
    .CheckupCalculationContainer .CheckupResult--Header p {
      color: #717171; } }
  .CheckupCalculationContainer .CheckupResult--ButtonHandle .Back button {
    background-color: white;
    border: none;
    color: #1d66dd;
    height: 40px; }
  .CheckupCalculationContainer .CheckupResult--ButtonHandle .Next button {
    background-color: #1d66dd;
    border: none;
    float: right;
    height: 40px; }
    @media (min-width: 768px) {
      .CheckupCalculationContainer .CheckupResult--ButtonHandle .Next button {
        width: 200px; } }
  .CheckupCalculationContainer .CheckupResult--Table {
    padding: 0 15px; }
    .CheckupCalculationContainer .CheckupResult--Table .HeaderTitle {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
      border-bottom: 2px solid #dfdfdf;
      padding-bottom: 15px; }
      .CheckupCalculationContainer .CheckupResult--Table .HeaderTitle div {
        padding: 0; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupResult--Table .HeaderTitle {
          display: none; } }
    .CheckupCalculationContainer .CheckupResult--Table .Item {
      font-size: 14px;
      color: #717171;
      padding: 15px 0;
      border-bottom: 1px solid #dfdfdf; }
      .CheckupCalculationContainer .CheckupResult--Table .Item div {
        padding: 0; }
        @media (max-width: 767.98px) {
          .CheckupCalculationContainer .CheckupResult--Table .Item div:first-child {
            font-weight: bold;
            color: #333333; } }
        .CheckupCalculationContainer .CheckupResult--Table .Item div.ConditionStatus--Good {
          color: #1ec494; }
        .CheckupCalculationContainer .CheckupResult--Table .Item div.ConditionStatus--Bad {
          color: #e81a1a; }
  .CheckupCalculationContainer .CheckupResult--EmailSend {
    background-color: #1d66dd;
    padding: 15px; }
    @media (min-width: 768px) {
      .CheckupCalculationContainer .CheckupResult--EmailSend {
        margin-left: -30px; } }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupResult--EmailSend {
        margin-left: -15px;
        margin-right: -15px; } }
    .CheckupCalculationContainer .CheckupResult--EmailSend div:first-child h2 {
      color: white;
      font-weight: bold;
      font-size: 22px; }
    .CheckupCalculationContainer .CheckupResult--EmailSend div:first-child p {
      color: white; }
    .CheckupCalculationContainer .CheckupResult--EmailSend div:first-child ul {
      list-style-type: none;
      padding: 0; }
      .CheckupCalculationContainer .CheckupResult--EmailSend div:first-child ul li {
        color: white; }
      .CheckupCalculationContainer .CheckupResult--EmailSend div:first-child ul i {
        margin-right: 10px; }
    @media (min-width: 992px) {
      .CheckupCalculationContainer .CheckupResult--EmailSend div:last-child {
        padding-left: 0; } }
    .CheckupCalculationContainer .CheckupResult--EmailSend div:last-child button {
      height: 40px; }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupResult--EmailSend div:last-child {
        margin-top: 10px; } }
  .CheckupCalculationContainer .CheckupResult .CtaBanner {
    background-color: #1d66dd;
    border-radius: 4px;
    padding: 15px;
    display: flex; }
    .CheckupCalculationContainer .CheckupResult .CtaBanner--Col1 {
      display: flex;
      flex-direction: row; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupResult .CtaBanner--Col1 {
          flex-direction: row-reverse;
          border-bottom: 1px solid #333333;
          margin: 0 -15px; } }
    .CheckupCalculationContainer .CheckupResult .CtaBanner img {
      transform: scaleX(-1);
      margin-bottom: -15px;
      flex-basis: 24%;
      height: 140px;
      margin-top: auto; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupResult .CtaBanner img {
          transform: scaleX(1); } }
    .CheckupCalculationContainer .CheckupResult .CtaBanner--Description {
      flex-basis: auto;
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .CheckupCalculationContainer .CheckupResult .CtaBanner--Description p {
        color: white;
        margin: 0; }
      .CheckupCalculationContainer .CheckupResult .CtaBanner--Description h3 {
        color: white;
        margin: 0;
        font-weight: bold;
        margin-bottom: 10px; }
    .CheckupCalculationContainer .CheckupResult .CtaBanner--Action {
      display: flex;
      flex-direction: column;
      flex-basis: 36%;
      cursor: pointer; }
      .CheckupCalculationContainer .CheckupResult .CtaBanner--Action .\--Target {
        text-decoration: none;
        background-color: #f95151;
        border-radius: 4px;
        margin: 5px 0;
        color: white;
        padding: 5px 15px; }
        .CheckupCalculationContainer .CheckupResult .CtaBanner--Action .\--Target i {
          margin-right: 10px; }
    @media (max-width: 767.98px) {
      .CheckupCalculationContainer .CheckupResult .CtaBanner {
        flex-wrap: wrap; }
        .CheckupCalculationContainer .CheckupResult .CtaBanner img {
          width: auto;
          height: 80px;
          margin: 0;
          margin-top: auto; }
        .CheckupCalculationContainer .CheckupResult .CtaBanner--Action {
          margin-top: 15px;
          flex-direction: row;
          flex-basis: 100%; }
          .CheckupCalculationContainer .CheckupResult .CtaBanner--Action .\--Target {
            font-size: 10px;
            font-weight: 500;
            flex-grow: 1;
            margin: 0 5px;
            padding: 5px; }
            .CheckupCalculationContainer .CheckupResult .CtaBanner--Action .\--Target:first-child {
              margin-left: 0; }
            .CheckupCalculationContainer .CheckupResult .CtaBanner--Action .\--Target:last-child {
              margin-right: 0; } }
  .CheckupCalculationContainer .CheckupResult .CheckupArticles {
    margin: 0 -15px; }
    .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-title {
      margin-bottom: 15px;
      padding: 20px 15px 0 15px; }
      .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-title h1 {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        margin: 0; }
    .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping {
      display: flex;
      flex-direction: row; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping {
          flex-direction: column; } }
      .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping .articles-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px 15px 15px;
        margin-bottom: 30px;
        cursor: pointer; }
        @media (max-width: 767.98px) {
          .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping .articles-body {
            align-items: normal;
            padding-bottom: 0; } }
        .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping .articles-body img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          margin-right: 15px; }
        .CheckupCalculationContainer .CheckupResult .CheckupArticles .articles-wrapping .articles-body p {
          font-weight: 500;
          margin: 0; }

.CheckupCalculationContainer .CheckupForm {
  padding: 20px 0; }
  .CheckupCalculationContainer .CheckupForm h3 {
    font-size: 16px;
    color: #c2c2c2; }
  .CheckupCalculationContainer .CheckupForm h2 {
    font-size: 22px;
    color: #333333;
    font-weight: bold; }
  .CheckupCalculationContainer .CheckupForm p {
    font-size: 12px;
    color: #717171; }
  @media (max-width: 767.98px) {
    .CheckupCalculationContainer .CheckupForm {
      padding: 20px 15px; } }
  .CheckupCalculationContainer .CheckupForm .FormFinancial {
    margin-bottom: 20px; }
    .CheckupCalculationContainer .CheckupForm .FormFinancial--TitleBox {
      margin-bottom: 0;
      display: flex; }
      @media (max-width: 767.98px) {
        .CheckupCalculationContainer .CheckupForm .FormFinancial--TitleBox {
          margin-bottom: 10px; } }
      .CheckupCalculationContainer .CheckupForm .FormFinancial--TitleBox .Title {
        font-size: 14px;
        margin: 0px;
        margin-bottom: 5px; }
      .CheckupCalculationContainer .CheckupForm .FormFinancial--TitleBox .SubTitle {
        margin: 5px;
        color: #c2c2c2; }
        @media (max-width: 991.98px) {
          .CheckupCalculationContainer .CheckupForm .FormFinancial--TitleBox .SubTitle {
            margin: 0; } }
  .CheckupCalculationContainer .CheckupForm .ResultFinancial--Total p {
    font-size: 14px;
    color: #717171;
    margin: 0; }
    .CheckupCalculationContainer .CheckupForm .ResultFinancial--Total p span {
      font-size: 16px;
      font-weight: bold;
      color: #333333; }
  .CheckupCalculationContainer .CheckupForm .ResultFinancial--Error p {
    font-size: 14px;
    color: #e51a1a; }
  .CheckupCalculationContainer .CheckupForm--ButtonHandle .Back button {
    background-color: white;
    border: none;
    color: #1d66dd;
    height: 40px; }
  .CheckupCalculationContainer .CheckupForm--ButtonHandle .Next button {
    background-color: #1d66dd;
    border: none;
    float: right;
    height: 40px; }
    @media (min-width: 768px) {
      .CheckupCalculationContainer .CheckupForm--ButtonHandle .Next button {
        margin-right: 30px;
        width: 200px; } }
