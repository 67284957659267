/*
* ink colors (with variations only for black and white)
*/
.compare__shimmer {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
  border-radius: calc(0.25 * 1rem);
  height: 240px;
  margin-bottom: 16px; }
  .compare__shimmer--body {
    display: flex;
    height: 100%; }
    .compare__shimmer--body .body__column {
      width: 25%;
      padding: calc(1.25 * 1rem); }
      .compare__shimmer--body .body__column:not(:last-child) {
        border-right: 1px solid #dfdfdf; }
      .compare__shimmer--body .body__column--shimmer .background {
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeHolderShimmer;
        -webkit-animation-timing-function: linear;
        background: #f6f7f9;
        background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
        background-repeat: no-repeat;
        background-size: 800px 200px;
        height: 200px;
        position: relative; }
      .compare__shimmer--body .body__column--shimmer div {
        background: #fff;
        height: 10px;
        left: 0;
        position: absolute;
        right: 0; }
        .compare__shimmer--body .body__column--shimmer div.compare__a {
          top: 10px; }
        .compare__shimmer--body .body__column--shimmer div.compare__b {
          top: 30px; }
        .compare__shimmer--body .body__column--shimmer div.compare__c {
          top: 50px; }
        .compare__shimmer--body .body__column--shimmer div.compare__d {
          top: 70px; }
        .compare__shimmer--body .body__column--shimmer div.compare__e {
          top: 90px; }
        .compare__shimmer--body .body__column--shimmer div.compare__f {
          top: 110px; }
        .compare__shimmer--body .body__column--shimmer div.compare__g {
          top: 130px; }
        .compare__shimmer--body .body__column--shimmer div.compare__h {
          top: 150px; }
        .compare__shimmer--body .body__column--shimmer div.compare__i {
          top: 170px; }
        .compare__shimmer--body .body__column--shimmer div.compare__j {
          top: 190px; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
