/*
* ink colors (with variations only for black and white)
*/
.np-login-btn {
  position: relative;
  color: #1D66DD;
  display: flex;
  align-items: center;
  text-decoration: unset;
  width: 40px;
  height: auto; }
  @media (min-width: 1200px) {
    .np-login-btn {
      width: auto; } }
  .np-login-btn a {
    font-family: "Open Sans" !important;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    transition-duration: .25s; }
    .np-login-btn a:hover {
      background-color: #1855B8;
      color: white;
      text-decoration: unset; }
      .np-login-btn a:hover svg {
        fill: #fff; }
    @media (min-width: 1200px) {
      .np-login-btn a {
        gap: 4px;
        background-color: white;
        padding: 0 12px;
        width: auto;
        border-radius: 4px;
        border: 1px solid #1d66dd;
        color: #1855B8; } }
    .np-login-btn a svg {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      fill: #1D66DD; }
      @media (min-width: 1200px) {
        .np-login-btn a svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px; } }
    .np-login-btn a span {
      display: none; }
      @media (min-width: 1200px) {
        .np-login-btn a span {
          display: block; } }
