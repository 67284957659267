/*
* ink colors (with variations only for black and white)
*/
.filter__section--item {
  background: #ffffff;
  border: 1px solid rgba(194, 194, 194, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  max-height: 40px;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 10px;
  color: #183b56;
  margin-right: 16px; }

.filter__section--item.disable__menu {
  display: none; }

.filter__item--name {
  padding: 0 16px; }

.active {
  color: #1d66dd; }

.filter__item--icon {
  margin-right: 5px; }

.filter__section--item-callme {
  margin-left: auto;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(194, 194, 194, 0.4);
  padding: 0 16px;
  background-color: white;
  color: #FEF0F0;
  text-align: left;
  display: flex;
  align-items: center; }
  .filter__section--item-callme-middle {
    margin-left: unset; }
  .filter__section--item-callme span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(239, 103, 103, 0.8);
    margin-left: 12px; }
