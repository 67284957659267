/*
* ink colors (with variations only for black and white)
*/
.bengkel__lead-form--header {
  height: 146px;
  background-color: #dfdfdf;
  border-radius: calc(0.25 * 1rem); }
  .bengkel__lead-form--header img {
    border-top-left-radius: calc(0.25 * 1rem);
    border-top-right-radius: calc(0.25 * 1rem); }

.bengkel__lead-form--body {
  padding: calc(0.5 * 1rem) calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .bengkel__lead-form--body .body__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .bengkel__lead-form--body .body__row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: calc(0.5 * 1rem); }
    .bengkel__lead-form--body .body__row--field {
      width: 50%;
      padding: 0 calc(0.5 * 1rem); }
      .bengkel__lead-form--body .body__row--field label {
        margin-bottom: calc(0.25 * 1rem);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400; }
      .bengkel__lead-form--body .body__row--field .lp__input--label {
        color: #808080 !important;
        left: 8px !important;
        font-size: 14px !important; }
  .bengkel__lead-form--body .body__submit-btn {
    margin-top: calc(0.5 * 1rem); }
  .bengkel__lead-form--body .body__cancel-btn {
    margin-top: calc(0.5 * 1rem);
    background-color: #ffffff;
    color: #1D66DD; }
