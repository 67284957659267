/*
* ink colors (with variations only for black and white)
*/
.breadCrumb {
  padding: 20px 0 0; }
  .breadCrumb ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 !important;
    margin-bottom: 0; }
    .breadCrumb ul li {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      list-style: none; }
      .breadCrumb ul li a {
        color: #1D66DD;
        text-decoration: none; }
    .breadCrumb ul li::before {
      padding: 0.5rem;
      content: ">"; }
    .breadCrumb ul li:first-child::before {
      content: ""; }
