/*
* ink colors (with variations only for black and white)
*/
.assosicated-brands-wrapper {
  background-color: white;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .assosicated-brands-wrapper {
      padding: 48px 0%; } }
  .assosicated-brands-wrapper .assosicated-brands-container {
    width: 100%; }
    @media (min-width: 576px) {
      .assosicated-brands-wrapper .assosicated-brands-container {
        width: 1120px;
        margin: 0 auto; } }
    .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-title {
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      padding: 0 16px;
      margin: 0; }
      @media (min-width: 576px) {
        .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-title {
          font-size: 28px;
          padding: 0; } }
    .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section {
      margin-top: 16px; }
      @media (min-width: 576px) {
        .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section {
          margin-top: 24px; } }
      .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px; }
        @media (min-width: 576px) {
          .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px 12px; } }
        .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list .assosicated-brands-item {
          width: 100%;
          height: 28%;
          border: 0.5px solid #D6D6D6;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 0; }
          @media (min-width: 576px) {
            .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list .assosicated-brands-item {
              width: 12.5%;
              height: 64px; } }
          .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list .assosicated-brands-item img {
            height: 54px; }
        .assosicated-brands-wrapper .assosicated-brands-container .assosicated-brands-section .assosicated-brands-list .assosicated-brands-item:hover {
          border: 2px solid #1D66DD; }
