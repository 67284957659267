/*
* ink colors (with variations only for black and white)
*/
.policy__wrapper {
  margin-right: auto;
  margin-left: auto;
  transition: all .5s ease .1s; }
  @media (min-width: 768px) {
    .policy__wrapper {
      max-width: 750px; } }
  @media (min-width: 992px) {
    .policy__wrapper {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .policy__wrapper {
      max-width: 1200px; } }
  .policy__wrapper--body {
    padding: 0 10px; }
  .policy__wrapper--item {
    margin-bottom: calc(2 * 1rem); }
  .policy__wrapper .button__wrapper {
    margin-bottom: calc(0.75 * 1rem);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    padding: calc(0.75 * 1rem); }
    .policy__wrapper .button__wrapper .button__list {
      display: flex; }
      @media (max-width: 991.98px) {
        .policy__wrapper .button__wrapper .button__list {
          justify-content: flex-end;
          flex-direction: column;
          width: 100%; } }
      .policy__wrapper .button__wrapper .button__list--item {
        margin-right: calc(0.5 * 1rem);
        width: 20%;
        border-color: #1D66DD;
        color: #1D66DD; }
        @media (max-width: 991.98px) {
          .policy__wrapper .button__wrapper .button__list--item {
            width: 100%; }
            .policy__wrapper .button__wrapper .button__list--item:not(:last-child) {
              margin-bottom: calc(0.5 * 1rem); } }
        .policy__wrapper .button__wrapper .button__list--item .item__icon {
          font-size: calc(1.25 * 1rem); }
      .policy__wrapper .button__wrapper .button__list .policy__status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: calc(0.75 * 1rem); }
        @media (min-width: 768px) {
          .policy__wrapper .button__wrapper .button__list .policy__status {
            margin-right: auto; } }
        .policy__wrapper .button__wrapper .button__list .policy__status .header__name {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700; }
          @media (min-width: 768px) {
            .policy__wrapper .button__wrapper .button__list .policy__status .header__name {
              margin-right: calc(1.25 * 1rem); } }
