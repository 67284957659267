/*
* ink colors (with variations only for black and white)
*/
.verify__otp {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; }
  .verify__otp--title {
    margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
  .verify__otp .title__info {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500; }
  .verify__otp--field {
    display: flex;
    height: 50px;
    margin-bottom: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem));
    width: 100%;
    justify-content: space-between; }
    .verify__otp--field .field__number {
      display: flex;
      justify-content: center; }
      .verify__otp--field .field__number .otp__input {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: #f2f7ff;
        padding: calc(0.5 * 1rem);
        font-size: 18px;
        border: 1px solid #ffffff;
        text-align: center;
        outline: none;
        margin-right: calc(1.25 * 1rem); }
        @media (max-width: 991.98px) {
          .verify__otp--field .field__number .otp__input {
            margin-right: calc(0.75 * 1rem); } }
        .verify__otp--field .field__number .otp__input .otp__input::-webkit-inner-spin-button,
        .verify__otp--field .field__number .otp__input .otp__input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .verify__otp--field .field__number .otp__input .otp__input::-moz-inner-spin-button,
        .verify__otp--field .field__number .otp__input .otp__input::-moz-outer-spin-button {
          -moz-appearance: none;
          margin: 0; }
  .verify__otp--countdown .resend__otp--text {
    color: #1D66DD; }
  .verify__otp--countdown .countdown__button {
    cursor: pointer; }
    .verify__otp--countdown .countdown__button:hover {
      text-decoration: underline; }
  .verify__otp--countdown .countdown__info {
    color: #c2c2c2; }
  .verify__otp--button {
    width: 100%;
    position: relative; }
  .verify__otp--success {
    text-align: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    margin-bottom: calc(1.25 * 1rem);
    color: white;
    background-color: #5cc197; }
