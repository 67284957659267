/*
* ink colors (with variations only for black and white)
*/
.bengkel__search {
  background-color: #1D66DD;
  width: 100%; }
  @media (min-width: 992px) {
    .bengkel__search {
      padding: 0 calc(calc(0.75 * 1rem) * 2) calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
  .bengkel__search--banner__container img {
    height: 150px;
    width: auto; }
  .bengkel__search--banner__container-mobile__texts {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: calc(0.75 * 1rem) calc(0.5 * 1rem) 0;
    line-height: 2;
    height: 65px; }
  .bengkel__search--banner__container-desktop__texts {
    padding: calc(2 * 1rem) calc(0.75 * 1rem); }
    .bengkel__search--banner__container-desktop__texts p {
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      height: fit-content;
      color: #ffffff;
      padding: calc(0.75 * 1rem);
      margin: 0; }
    .bengkel__search--banner__container-desktop__texts p:first-child {
      font-size: 28px;
      line-height: 26px;
      font-weight: 700; }
  @media (min-width: 992px) {
    .bengkel__search--banner__container {
      display: grid;
      grid-template-columns: 42% 50%;
      padding: calc(0.75 * 1rem) calc(1.25 * 1rem); }
      .bengkel__search--banner__container img {
        padding: calc(0.75 * 1rem) 0 0;
        height: 180px;
        width: auto; } }
  .bengkel__search--select__none {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 300px;
    padding: calc(0.5 * 1rem) 0; }
  .bengkel__search--select__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 300px; }
    .bengkel__search--select__container span {
      padding: calc(0.5 * 1rem) 0;
      margin-right: calc(0.75 * 1rem);
      font-size: 14px;
      line-height: 18px;
      font-weight: 500; }
    .bengkel__search--select__container span:first-child {
      width: 15%; }
      .bengkel__search--select__container span:first-child img {
        width: 50px;
        height: 20px;
        object-fit: contain; }
  .bengkel__search--form {
    width: 100%;
    height: 300px; }
    @media (min-width: 992px) {
      .bengkel__search--form {
        height: 140px; }
        .bengkel__search--form__container {
          display: grid;
          grid-template-columns: auto auto auto;
          padding: 0 calc(0.75 * 1rem); } }
    .bengkel__search--form .form__field {
      width: 100%;
      margin: calc(1.25 * 1rem) 0 calc(0.75 * 1rem);
      padding: 0 calc(0.75 * 1rem);
      height: 60px; }
      .bengkel__search--form .form__field label {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: calc(0.5 * 1rem); }
      .bengkel__search--form .form__field--button {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) calc(0.75 * 1rem);
        background-color: #ef6767;
        margin: calc(0.75 * 1rem) 0;
        height: 30px; }
        .bengkel__search--form .form__field--button--container {
          height: 60px;
          padding: 0 calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
        @media (min-width: 992px) {
          .bengkel__search--form .form__field--button {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500; }
            .bengkel__search--form .form__field--button--container {
              padding: 0 calc(1.25 * 1rem); } }
