/*
* ink colors (with variations only for black and white)
*/
.primary_dropdown_menu {
  list-style-type: none;
  display: none;
  min-width: 0;
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  padding: 0;
  background-color: #ffffff; }
  @media (min-width: 1200px) {
    .primary_dropdown_menu {
      min-width: 224px;
      width: auto;
      padding: 0;
      position: absolute;
      left: 0;
      top: 100%;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.16); } }
  .primary_dropdown_menu.show {
    display: block; }

.secondary_dropdown_menu {
  list-style-type: none;
  display: none;
  min-width: 0;
  width: 100%;
  position: relative;
  left: auto;
  top: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.16); }
  @media (min-width: 1200px) {
    .secondary_dropdown_menu {
      min-width: 224px;
      width: auto;
      padding: 0;
      position: absolute;
      left: 100%;
      top: 0;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.16); } }
  .secondary_dropdown_menu.show {
    display: block; }
