/*
* ink colors (with variations only for black and white)
*/
.form-row-space {
  margin-top: 12px; }

.form-child-space {
  margin-bottom: 12px; }

.remove-button, .add-button {
  font-size: 14px;
  font-weight: 700;
  color: #1D66DD;
  border: solid 1px #1D66DD;
  border-radius: 4px;
  background-color: white; }

.remove-button {
  padding: 8px 6px; }

.add-button {
  flex: 1;
  padding: 8px 0px; }

.default-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  border: solid 1px #1D66DD;
  padding: 16px 12px;
  transition: height 1s linear 0s; }
  .default-form .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin: 0 0 12px 0;
    border-bottom: 1px dashed #999999; }
    .default-form .title-row .title {
      font-size: 16px;
      font-weight: 700;
      color: #1D66DD;
      text-align: center;
      margin: 0;
      flex: 1; }
    .default-form .title-row .checkbox {
      height: 16px;
      width: 16px;
      border: solid 1px #999999;
      background-color: white; }
    .default-form .title-row .checkbox-active {
      border: none;
      background-color: #EA4B4B; }
  .default-form .title-row-inactive {
    padding: 0;
    margin: 0;
    border: none; }
