/*
* ink colors (with variations only for black and white)
*/
.insurance__expiry {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.75 * 1rem);
  padding: calc(0.75 * 1rem); }
  .insurance__expiry h4 {
    color: #333333;
    text-align: center;
    margin-bottom: calc(0.75 * 1rem); }
  .insurance__expiry .expiry__list {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(0.75 * 1rem); }
    .insurance__expiry .expiry__list .expiry__btn {
      margin-bottom: calc(0.75 * 1rem);
      border: 1px solid #1D66DD;
      color: #1D66DD; }
    .insurance__expiry .expiry__list .toggle__btn {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative; }
      .insurance__expiry .expiry__list .toggle__btn span {
        margin-left: calc(0.25 * 1rem); }
  .insurance__expiry .custom__expiry {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .insurance__expiry .custom__expiry .field {
      width: 48%; }
