/*
* ink colors (with variations only for black and white)
*/
.member__card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 0 calc(calc(1.25 * 1rem) + calc(0.5 * 1rem));
  transition: 0.5s;
  height: auto;
  margin-bottom: calc(1.25 * 1rem);
  border-radius: calc(0.25 * 1rem); }
  .member__card--header {
    cursor: pointer;
    display: flex;
    padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0; }
    .member__card--header.border__btm {
      border-bottom: 1px solid #dfdfdf; }
    .member__card--header .header__left {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991.98px) {
        .member__card--header .header__left {
          flex-direction: column; } }
      .member__card--header .header__left.invalid {
        color: #E81A1A; }
        @media (max-width: 991.98px) {
          .member__card--header .header__left.invalid {
            align-items: start; } }
      @media (max-width: 991.98px) {
        .member__card--header .header__left.valid {
          justify-content: center; } }
      .member__card--header .header__left--title {
        margin-bottom: 0;
        color: inherit;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500; }
      .member__card--header .header__left--message {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0;
        color: inherit; }
    .member__card--header .header__right {
      display: flex;
      margin-left: auto;
      font-size: 30px;
      color: #1D66DD;
      align-items: center; }
      .member__card--header .header__right.invalid {
        color: #E81A1A; }
      .member__card--header .header__right--message {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0;
        margin-right: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem));
        color: inherit; }
  .member__card--body {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out, padding 0.25s ease-out, overflow 0.25s ease-out; }
    .member__card--body.active {
      max-height: 100%;
      overflow: visible;
      padding: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)) 0;
      transition: max-height 0.25s ease-in, padding 0.25s ease-in, overflow 0.25s none; }
  .member__card--mobile {
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-x: scroll;
    padding: calc(1.25 * 1rem);
    padding-top: 50px;
    z-index: 10000000000; }
    .member__card--mobile .mobile__footer {
      position: fixed;
      bottom: 0;
      right: 0;
      padding: calc(1.25 * 1rem);
      width: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background: #ffffff;
      z-index: 100; }
      .member__card--mobile .mobile__footer--button {
        width: 100%; }
