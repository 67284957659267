/*
* ink colors (with variations only for black and white)
*/
.policy__slides {
  height: 200px !important;
  position: relative !important;
  margin: calc(0.75 * 1rem);
  cursor: pointer; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .policy__slides {
      min-width: 270px !important; } }
  .policy__slides .carousel__inner-slide {
    padding: 0 !important;
    box-shadow: unset !important; }
  .policy__slides--card {
    height: 80%;
    transition: 1s;
    background: url("/static/images/background/policy-card.png");
    border-radius: calc(1.25 * 1rem);
    background-size: cover;
    background-repeat: round; }
    .policy__slides--card.card__off {
      margin: calc(1.25 * 1rem) calc(calc(0.25 * 1rem) + calc(1.25 * 1rem));
      opacity: 0.3; }
    .policy__slides--card.card__selected {
      height: 100%;
      transition: 1s; }
    .policy__slides--card.card__disabled {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
    .policy__slides--card .card__item {
      height: 33.33%; }
      .policy__slides--card .card__item p {
        color: #ffffff; }
      .policy__slides--card .card__item--head {
        padding: calc(0.25 * 1rem) calc(0.75 * 1rem); }
        .policy__slides--card .card__item--head img {
          width: 80px; }
          @media (max-width: 991.98px) {
            .policy__slides--card .card__item--head img {
              width: 60px; } }
      .policy__slides--card .card__item--body {
        padding: calc(0.75 * 1rem); }
        .policy__slides--card .card__item--body .body__name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          text-transform: capitalize;
          line-height: calc(0.75 * 1rem);
          margin-bottom: calc(0.75 * 1rem); }
        .policy__slides--card .card__item--body .product__info {
          font-size: 10px;
          line-height: 14px;
          font-weight: 500;
          line-height: 10px; }
      .policy__slides--card .card__item--foot {
        display: flex;
        padding: calc(0.75 * 1rem); }
        .policy__slides--card .card__item--foot p {
          font-size: 10px;
          margin-bottom: 0px; }
          .policy__slides--card .card__item--foot p.title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: calc(0.75 * 1rem); }
        .policy__slides--card .card__item--foot .content {
          width: 50%; }
          .policy__slides--card .card__item--foot .content:last-child {
            align-self: flex-end; }
            .policy__slides--card .card__item--foot .content:last-child p {
              text-align: right; }
          .policy__slides--card .card__item--foot .content__value {
            font-size: 10px;
            line-height: 14px;
            font-weight: 500;
            overflow-wrap: break-word;
            line-height: 10px; }
  .policy__slides--download {
    width: 50px;
    position: absolute;
    right: calc(3.25 * 1rem);
    top: 18px; }
    .policy__slides--download .download__icon {
      width: 100%;
      border-radius: calc(0.25 * 1rem);
      background-color: #ffffff;
      color: #1D66DD;
      display: flex;
      justify-content: center;
      padding: calc(0.25 * 1rem);
      cursor: pointer; }
      .policy__slides--download .download__icon:hover {
        background: #1D66DD;
        color: #ffffff;
        border: 1px solid #ffffff;
        transition: .6s; }
