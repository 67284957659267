/*
* ink colors (with variations only for black and white)
*/
.FinancialCheckup--Card-1 {
  display: flex;
  height: 363px;
  flex-direction: row; }
  .FinancialCheckup--Card-1 .financial__checkout--image {
    width: 358px !important;
    height: 100% !important;
    content: url("/static/images/usp/usp-15.png"); }
    @media (max-width: 991.98px) {
      .FinancialCheckup--Card-1 .financial__checkout--image {
        content: url("/static/images/usp/usp-16.png");
        width: 100% !important; } }
  @media (max-width: 991.98px) {
    .FinancialCheckup--Card-1 {
      height: auto;
      flex-direction: column; } }

.FinancialCheckup .check-financial-container .financial-title {
  padding: 20px 30px;
  border-bottom: 1px solid #dfdfdf; }
  .FinancialCheckup .check-financial-container .financial-title h2 {
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    color: #333333; }
  @media (max-width: 767.98px) {
    .FinancialCheckup .check-financial-container .financial-title {
      padding: 15px; }
      .FinancialCheckup .check-financial-container .financial-title h2 {
        font-size: 22px; } }

.FinancialCheckup .description-container {
  padding: 20px 30px;
  position: relative;
  height: 500px;
  overflow: hidden; }
  .FinancialCheckup .description-container.expanded {
    height: unset;
    overflow: unset; }
  .FinancialCheckup .description-container .container-1 {
    margin-top: 20px; }
  @media (max-width: 991.98px) {
    .FinancialCheckup .description-container {
      padding: 15px; } }
  .FinancialCheckup .description-container h1 {
    color: #333333;
    font-size: 32px;
    line-height: 42px;
    font-weight: bold; }
  .FinancialCheckup .description-container h2 {
    font-weight: bold;
    font-size: 28px; }
  .FinancialCheckup .description-container h3 {
    font-weight: bold;
    font-size: 24px; }
  .FinancialCheckup .description-container p {
    color: #717171;
    line-height: 22px; }
  @media (max-width: 767.98px) {
    .FinancialCheckup .description-container h1 {
      font-size: 24px;
      line-height: 34px; }
    .FinancialCheckup .description-container h2 {
      font-size: 22px; }
    .FinancialCheckup .description-container h3 {
      font-size: 18px; } }
  .FinancialCheckup .description-container table {
    margin-bottom: calc(1.25 * 1rem); }
    .FinancialCheckup .description-container table td,
    .FinancialCheckup .description-container table th {
      border: 1px solid #dfdfdf;
      width: 50%;
      padding: calc(0.5 * 1rem); }
  .FinancialCheckup .description-container__shadow {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 200px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #ffffff 70%);
    display: flex;
    align-items: flex-end;
    justify-content: center; }
    .FinancialCheckup .description-container__shadow .text__button {
      color: #1D66DD;
      cursor: pointer;
      display: flex;
      align-items: center; }

@media (max-width: 767.98px) {
  .FinancialCheckup .sidebar-articles {
    margin-top: 15px; } }

.FinancialCheckup .sidebar-articles .articles-title {
  padding: 20px 15px 0 15px; }
  .FinancialCheckup .sidebar-articles .articles-title h1 {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    margin: 0; }

.FinancialCheckup .sidebar-articles .articles-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px 15px 15px;
  margin-bottom: 5px;
  cursor: pointer; }
  .FinancialCheckup .sidebar-articles .articles-body img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 15px; }
  .FinancialCheckup .sidebar-articles .articles-body p {
    font-weight: 500;
    margin: 0;
    color: #1d66dd;
    text-decoration: none; }

.FinancialCheckup .social__icon--list {
  margin-top: 16px; }
  .FinancialCheckup .social__icon--list .social__icon--wrapper {
    transition: 0.3s ease-in-out; }
    .FinancialCheckup .social__icon--list .social__icon--wrapper:hover {
      transform: scale(1.3);
      transition: 0.3s ease-in-out; }
    .FinancialCheckup .social__icon--list .social__icon--wrapper .social__icon {
      height: 32px;
      width: 32px; }
