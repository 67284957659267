/*
* ink colors (with variations only for black and white)
*/
.agentsInvite {
  background: rgba(30, 196, 148, 0.04);
  margin-bottom: calc(2 * 1rem); }
  .agentsInvite__hero {
    background: url("/static/images/agents/invite/hero_mobile.png");
    background-position: center;
    height: 360px; }
    @media (min-width: 768px) {
      .agentsInvite__hero {
        background: url("/static/images/agents/invite/hero_desktop.png");
        background-size: cover;
        height: 600px; } }
    .agentsInvite__hero__body {
      position: relative; }
      .agentsInvite__hero__body--content {
        position: absolute;
        top: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        @media (min-width: 768px) {
          .agentsInvite__hero__body--content {
            width: 50%;
            display: unset; } }
      .agentsInvite__hero__body--title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 2;
        margin-bottom: calc(0.75 * 1rem); }
        @media (min-width: 768px) {
          .agentsInvite__hero__body--title {
            text-align: left;
            font-size: 28px; } }
      .agentsInvite__hero__body--cta {
        background: #ef6767;
        border-radius: calc(0.75 * 1rem);
        border: none;
        color: #ffffff;
        width: 50%;
        font-weight: 600 !important; }
  .agentsInvite__copywriting {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-size: 16px;
    background: url("/static/images/agents/invite/copywriting_bg_mobile.png");
    height: 260px;
    color: #ffffff;
    margin: calc(0.75 * 1rem) 0;
    text-align: center;
    line-height: 2;
    border-radius: calc(0.75 * 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(0.75 * 1rem); }
    .agentsInvite__copywriting button {
      background: #ef6767;
      border-radius: calc(0.5 * 1rem);
      border: none;
      color: #ffffff;
      margin: calc(2 * 1rem) 0;
      font-weight: 600;
      width: 100%; }
    @media (min-width: 768px) {
      .agentsInvite__copywriting {
        background: url("/static/images/agents/invite/copywriting_bg.png");
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        font-weight: 600;
        height: 150px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: calc(2 * 1rem) 0 calc(0.75 * 1rem); }
        .agentsInvite__copywriting div {
          padding: calc(0.75 * 1rem) 0; }
        .agentsInvite__copywriting button {
          width: 30%;
          margin: calc(1.25 * 1rem) 0;
          padding: calc(0.5 * 1rem); } }
  .agentsInvite__card__float {
    position: relative;
    margin: calc(5.25 * 1rem) 0; }
    .agentsInvite__card__float--body {
      background: #ffffff;
      padding: calc(0.75 * 1rem);
      box-shadow: -16px 16px 50px -30px rgba(0, 0, 0, 0.24);
      border-radius: 16px; }
      .agentsInvite__card__float--body div {
        margin: calc(0.5 * 1rem) 0; }
    .agentsInvite__card__float--id {
      color: #1D66DD;
      background: #e4eefd;
      text-align: center;
      width: 30%;
      border-radius: calc(0.5 * 1rem);
      line-height: 2; }
    .agentsInvite__card__float--title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      line-height: 2; }
      @media (min-width: 768px) {
        .agentsInvite__card__float--title {
          font-size: 20px;
          line-height: 1.5;
          width: 90%; } }
    .agentsInvite__card__float--subtitle {
      font-size: 16px;
      line-height: 2; }
      @media (min-width: 768px) {
        .agentsInvite__card__float--subtitle {
          width: 75%; } }
  .agentsInvite__card__reverse {
    position: relative;
    box-shadow: -16px 16px 50px -30px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    margin: calc(1.25 * 1rem) 0; }
    .agentsInvite__card__reverse--img {
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .agentsInvite__card__reverse--body {
      background: #ffffff;
      padding: calc(0.75 * 1rem);
      border-radius: 16px; }
      .agentsInvite__card__reverse--body--wrap {
        padding: calc(0.75 * 1rem); }
      .agentsInvite__card__reverse--body div {
        margin: calc(0.5 * 1rem) 0; }
    .agentsInvite__card__reverse--id {
      color: #1D66DD;
      background: #e4eefd;
      text-align: center;
      width: 30%;
      border-radius: calc(0.5 * 1rem);
      line-height: 2; }
    .agentsInvite__card__reverse--title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      line-height: 2; }
      @media (min-width: 768px) {
        .agentsInvite__card__reverse--title {
          font-size: 20px; } }
    .agentsInvite__card__reverse--subtitle {
      font-size: 16px;
      line-height: 2; }
      @media (min-width: 768px) {
        .agentsInvite__card__reverse--subtitle {
          width: 75%; } }
  @media (min-width: 768px) {
    .agentsInvite__card__float--body {
      box-shadow: -16px 16px 50px -30px rgba(0, 0, 0, 0.24);
      background: #ffffff;
      position: absolute;
      top: -10px;
      right: 0;
      width: 50%;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      padding: calc(1.25 * 1rem) !important; }
    .agentsInvite__card__reverse {
      display: flex;
      flex-direction: row-reverse; }
      .agentsInvite__card__reverse--body {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center; } }
  @media (min-width: 768px) {
    .agentsInvite__testimony {
      display: flex;
      flex-direction: row; } }
  @media (min-width: 768px) {
    .agentsInvite__testimony__body {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center; } }
  .agentsInvite__testimony__body--title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #1D66DD;
    text-align: center;
    padding: calc(0.5 * 1rem) 0; }
    @media (min-width: 768px) {
      .agentsInvite__testimony__body--title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        text-align: left;
        padding: calc(0.75 * 1rem); } }
  .agentsInvite__testimony__body--subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    padding: calc(0.5 * 1rem) 0; }
    @media (min-width: 768px) {
      .agentsInvite__testimony__body--subtitle {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        padding: calc(0.75 * 1rem); } }
  .agentsInvite__testimony__body--box {
    position: relative; }
    @media (max-width: 991.98px) {
      .agentsInvite__testimony__body--box img {
        height: 350px; } }
    .agentsInvite__testimony__body--box--text {
      padding: calc(1.25 * 1rem) calc(2 * 1rem);
      position: absolute;
      top: 10%;
      line-height: 2.5;
      font-size: 14px !important; }
      @media (min-width: 768px) {
        .agentsInvite__testimony__body--box--text {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          padding: calc(2 * 1rem);
          line-height: 2.4; } }
  .agentsInvite__carousel {
    position: relative; }
    .agentsInvite__carousel--play {
      position: absolute;
      top: 42.5%;
      left: 42.5%;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      @media (min-width: 768px) {
        .agentsInvite__carousel--play {
          top: 45%;
          left: 47.5%; } }
    .agentsInvite__carousel--title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      color: #1D66DD; }
    .agentsInvite__carousel h4 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      text-align: center !important; }
    .agentsInvite__carousel ul {
      list-style: none;
      margin: 0; }
    .agentsInvite__carousel .lp__carousel .carousel__inner-slide,
    .agentsInvite__carousel .lp__carousel .carousel__slider.center-mode,
    .agentsInvite__carousel .lp__carousel .carousel__slider {
      padding: 0 !important;
      box-shadow: unset !important; }
    .agentsInvite__carousel .lp__carousel .carousel__slide:first-child {
      margin-left: calc(0.5 * 1rem);
      margin-right: 0; }
    .agentsInvite__carousel .lp__carousel .carousel__slide:last-child {
      margin-left: 0;
      margin-right: calc(0.5 * 1rem); }
    .agentsInvite__carousel .lp__carousel .carousel .button__back,
    .agentsInvite__carousel .lp__carousel .carousel .button__next {
      padding: calc(0.75 * 1rem) calc(1.25 * 1rem);
      background: #ffffff;
      color: #1D66DD; }
      @media (min-width: 768px) {
        .agentsInvite__carousel .lp__carousel .carousel .button__back,
        .agentsInvite__carousel .lp__carousel .carousel .button__next {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400; } }
    .agentsInvite__carousel__slide li {
      width: unset !important; }
    .agentsInvite__carousel__slide a {
      cursor: pointer; }
      @media (min-width: 768px) {
        .agentsInvite__carousel__slide a {
          height: 400px; } }
      .agentsInvite__carousel__slide a img {
        padding: calc(0.5 * 1rem);
        width: 99% !important;
        height: 95% !important;
        margin: auto;
        border-radius: 16px; }
