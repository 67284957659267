/*
* ink colors (with variations only for black and white)
*/
.voucher__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
  border-radius: calc(0.25 * 1rem);
  text-align: center;
  color: #1D66DD; }
  .voucher__wrapper--submit:not(:disabled) {
    cursor: pointer; }
  .voucher__wrapper--info {
    display: flex;
    align-items: center; }
    .voucher__wrapper--info .info__code {
      width: 100%; }
    .voucher__wrapper--info .info__close {
      margin-left: auto;
      cursor: pointer; }
