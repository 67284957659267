/*
* ink colors (with variations only for black and white)
*/
.padding__offset {
  padding: calc(0.75 * 1rem) 0; }

.delivery__info--wrapper {
  height: 100%;
  padding: calc(1.25 * 1rem);
  background-color: #ffffff !important; }

.mobil__tax {
  background-color: #1D66DD;
  margin-top: -30px;
  padding: calc(1.25 * 1rem); }
  .mobil__tax .desktop__view--header {
    display: flex;
    color: #ffffff;
    margin: 20px auto;
    width: 25vw;
    position: relative;
    top: 10vh; }
    .mobil__tax .desktop__view--header .icon__wrapper {
      margin-right: calc(0.75 * 1rem);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .mobil__tax .desktop__view--header .icon__wrapper i {
        color: #333333; }
    .mobil__tax .desktop__view--header span {
      text-transform: capitalize;
      margin-top: calc(0.75 * 1rem); }
  .mobil__tax--container {
    display: grid;
    width: 25vw;
    margin: 20px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(1.25 * 1rem);
    background-color: #ffffff;
    position: relative;
    top: 10vh;
    padding: calc(0.75 * 1rem); }
    @media (max-width: 991.98px) {
      .mobil__tax--container {
        width: 100vw;
        margin: 0;
        top: 0;
        border-radius: 0 !important; }
        .mobil__tax--container .border-radius-xs {
          border-radius: 0 !important; } }
    .mobil__tax--container .mobileNav p {
      text-transform: capitalize; }
  .mobil__tax--body .mobil__tax--form .form__title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #707070; }
  .mobil__tax--body .mobil__tax--form .tax__form--data {
    position: relative;
    min-height: calc(100vh - (100px + calc(1.25 * 1rem))); }
    .mobil__tax--body .mobil__tax--form .tax__form--data .input__group {
      margin: calc(0.75 * 1rem) 0; }
      .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .PhoneInput {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-size: 14px !important; }
      .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .lp-radio__wrapper {
        padding: calc(0.5 * 1rem); }
        .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .lp-radio__wrapper label {
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 0; }
      .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .DatePicker, .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .custom__date--picker {
        width: 100%; }
      .mobil__tax--body .mobil__tax--form .tax__form--data .input__group .custom__date--picker {
        height: 40px; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .radio__group .color-lp-red {
      margin-left: auto; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .input__group--title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #333333; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .disable {
      opacity: 0.5;
      pointer-events: none; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .input__group--subtitle {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #707070; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .lp__input--group {
      margin: calc(1.25 * 1rem) 0; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .extra__info {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #707070; }
    .mobil__tax--body .mobil__tax--form .tax__form--data ol {
      padding-left: 15px; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .image__placeholder {
      width: 20%;
      min-height: 70px;
      background-color: #eaeaea;
      margin: calc(0.75 * 1rem) auto;
      border-radius: calc(0.75 * 1rem);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .mobil__tax--body .mobil__tax--form .tax__form--data .image__placeholder .upload__icon--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #1D66DD;
        text-align: center; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .delivery__information .lp-radio__wrapper label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #333333; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .custom__floating--btn {
      position: absolute;
      bottom: 0;
      width: 100%; }
    .mobil__tax--body .mobil__tax--form .tax__form--data .input__section, .mobil__tax--body .mobil__tax--form .tax__form--data .media__section, .mobil__tax--body .mobil__tax--form .tax__form--data .info__section, .mobil__tax--body .mobil__tax--form .tax__form--data .action__button {
      background-color: #ffffff;
      padding: calc(0.5 * 1rem) calc(1.25 * 1rem); }
    .mobil__tax--body .mobil__tax--form .tax__form--data .media__section, .mobil__tax--body .mobil__tax--form .tax__form--data .info__section {
      margin: calc(0.5 * 1rem) 0; }
