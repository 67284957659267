/*
* ink colors (with variations only for black and white)
*/
.kyc__background {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: calc(3.25 * 1rem) 0;
  z-index: 99999; }

.kyc__box {
  position: absolute;
  top: 30px;
  margin-top: 34px;
  width: 340px;
  padding: calc(2 * 1rem);
  border-radius: calc(0.5 * 1rem);
  box-shadow: 0 1px 4px 0 rgba(51, 51, 51, 0.16);
  background: #ffffff; }
  .kyc__box p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    text-align: center; }
  .kyc__box input {
    margin-bottom: calc(1.25 * 1rem); }
  .kyc__box input[name="annualIncome"] {
    padding: 0 40px !important; }
