/*
* ink colors (with variations only for black and white)
*/
.benefit__details--table {
  margin-bottom: calc(0.75 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (max-width: 768px) {
    .benefit__details--table {
      margin-bottom: calc(1.25 * 1rem); } }
  .benefit__details--table .table__head {
    padding: 10px calc(1.25 * 1rem);
    border-bottom: 1px solid #dfdfdf; }
    @media (max-width: 768px) {
      .benefit__details--table .table__head {
        padding: calc(1.25 * 1rem);
        display: flex;
        justify-content: center; } }
  .benefit__details--table .table__body {
    display: flex;
    border-bottom: 1px solid #dfdfdf; }
    .benefit__details--table .table__body--left {
      width: 35%;
      padding: calc(1.25 * 1rem);
      border-right: 1px solid #dfdfdf;
      display: flex; }
      @media (max-width: 768px) {
        .benefit__details--table .table__body--left {
          width: 50%; } }
    .benefit__details--table .table__body--right {
      display: flex;
      padding: calc(1.25 * 1rem);
      width: 50%; }
      .benefit__details--table .table__body--right p {
        color: #717171; }
      @media (min-width: 768px) {
        .benefit__details--table .table__body--right {
          width: 65%;
          justify-content: center; } }
      .benefit__details--table .table__body--right.providers p {
        color: #1d66dd;
        cursor: pointer; }
