.auto-width {
  width: 200px !important; }
  @media (max-width: 767.98px) {
    .auto-width {
      width: 100% !important; } }

@media (max-width: 767.98px) {
  .SpecificationForm__Auto {
    width: 100% !important;
    padding: 15px 24px 24px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: #FFFFFF; } }

.SpecificationForm__Auto .css-1li0unn-control,
.SpecificationForm__Auto .css-alq018-control {
  background: #1D66DD1A;
  border: none; }

.filter-asurance-submit {
  width: 100%;
  background-color: #EA4B4B;
  font-weight: 700;
  border-color: #EA4B4B; }

@media (max-width: 767.98px) {
  .reset-m {
    background-color: #FFFFFF;
    border: 1px solid #EA4B4B;
    color: #EA4B4B;
    font-weight: 700; } }

@media (max-width: 767.98px) {
  .reset {
    visibility: hidden; } }

@media (max-width: 767.98px) {
  .filter__header {
    visibility: hidden; } }
