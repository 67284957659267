/*
* ink colors (with variations only for black and white)
*/
.location-view__wrapper--header > p {
  color: #707070; }

.location-view__wrapper--header .search__box img {
  left: calc(0.5 * 1rem); }

.location-view__wrapper--body {
  height: 250px;
  overflow-y: scroll; }
  .location-view__wrapper--body .body__location {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: calc(0.5 * 1rem);
    cursor: pointer; }
    .location-view__wrapper--body .body__location:hover {
      border-bottom: 1px solid #c2c2c2; }
  .location-view__wrapper--body .empty__state--wrapper {
    box-shadow: none !important;
    padding: 0 !important; }

.location-view__wrapper .loader__field {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
