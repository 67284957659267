/*
* ink colors (with variations only for black and white)
*/
.primary_nav_item {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 0;
  border-bottom: 1px solid #FAFAFA;
  position: relative;
  transition-duration: 0.25s;
  transition-property: all; }
  @media (min-width: 1200px) {
    .primary_nav_item {
      border-bottom: none;
      padding: 0 8px; } }
  .primary_nav_item:hover {
    background-image: linear-gradient(rgba(29, 102, 221, 0), rgba(29, 102, 221, 0.1)); }
  .primary_nav_item:last-child {
    border-bottom: none; }
  .primary_nav_item.push_left {
    margin-left: 0; }
  @media (min-width: 1200px) {
    .primary_nav_item.push_left {
      margin-left: auto; } }
  .primary_nav_item.has_dropdown {
    position: relative; }
  .primary_nav_item > .primary_dropdown_menu.show {
    display: block; }
    @media (min-width: 1200px) {
      .primary_nav_item > .primary_dropdown_menu.show {
        left: 0;
        top: 100%; } }
  .primary_nav_item svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px; }
    @media (min-width: 1200px) {
      .primary_nav_item svg {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        fill: #222222; } }

.secondary_nav_item {
  color: #222;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #F5F5F5; }
  @media (min-width: 1200px) {
    .secondary_nav_item {
      background-color: #fff; } }
  .secondary_nav_item:last-child {
    border-bottom: none; }
  .secondary_nav_item.has_dropdown {
    position: relative; }
  @media (min-width: 1200px) {
    .secondary_nav_item > .secondary_dropdown_menu {
      left: 100%;
      top: 0;
      z-index: 1; } }
  .secondary_nav_item > .secondary_dropdown_menu.show {
    display: block; }

.tertiary_nav_item {
  color: #222;
  display: block;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #F5F5F5; }
  .tertiary_nav_item:last-child {
    border-bottom: none; }
  .tertiary_nav_item.has_dropdown {
    position: relative; }
  @media (min-width: 1200px) {
    .tertiary_nav_item > .tertiary_dropdown_menu {
      left: 100%;
      top: 0; } }
  .tertiary_nav_item > .tertiary_dropdown_menu.show {
    display: block; }
