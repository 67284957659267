/*
* ink colors (with variations only for black and white)
*/
.agentForm__wrapper {
  width: 100%;
  padding-right: calc(0.75 * 1rem);
  padding-left: calc(0.75 * 1rem);
  margin-right: auto;
  margin-left: auto; }
  .agentForm__wrapper .agentForm__wrapper--body .form__wrapper--full, .agentForm__wrapper--body .agentForm__wrapper .form__wrapper--full, .agentForm__wrapper .agentForm__wrapper--body .form__wrapper--half, .agentForm__wrapper--body .agentForm__wrapper .form__wrapper--half, .agentForm__wrapper .agentForm__wrapper--body .form__wrapper--quarter, .agentForm__wrapper--body .agentForm__wrapper .form__wrapper--quarter {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .agentForm__wrapper--body .form__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .agentForm__wrapper--body .form__wrapper--full {
      flex: 0 0 100%;
      max-width: 100%; }
    .agentForm__wrapper--body .form__wrapper--half {
      flex: 0 0 50%;
      max-width: 50%; }
    .agentForm__wrapper--body .form__wrapper--quarter {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .agentForm__wrapper--body .form__wrapper--group {
      margin-bottom: calc(1.25 * 1rem); }
  .agentForm__wrapper--body a {
    color: #1D66DD;
    cursor: pointer; }
  .agentForm__wrapper--body .upload__box {
    width: calc(5.25 * 1rem);
    height: calc(5.25 * 1rem);
    border-radius: 4px;
    border: dashed 1px #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center; }
    .agentForm__wrapper--body .upload__box--image {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      display: flex;
      justify-content: center;
      align-items: center; }
    .agentForm__wrapper--body .upload__box--button {
      width: 20px;
      background: #333333;
      opacity: 0.5;
      border-radius: 4px;
      display: flex;
      justify-content: center; }
    .agentForm__wrapper--body .upload__box i {
      color: #ffffff;
      font-size: 16px; }
    .agentForm__wrapper--body .upload__box span {
      font-size: 8px;
      color: #dfdfdf; }
    .agentForm__wrapper--body .upload__box .upload {
      cursor: pointer; }
      .agentForm__wrapper--body .upload__box .upload i {
        font-size: large;
        color: #dfdfdf; }
