.usp-container {
  border-radius: 8px;
  background-image: linear-gradient(to left, #4380E3, #1855B8, #134493);
  display: flex;
  flex-direction: column;
  min-width: 360px;
  gap: 16px; }
  .usp-container .usp-label {
    color: #FFFFFF;
    font-size: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
    font-weight: 700;
    line-height: 20px;
    /* 150% */ }
  .usp-container .usp-content {
    border: 0.5px solid #D6D6D6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 360px;
    border-radius: 8px;
    background: #FFFFFF;
    align-self: stretch;
    padding: 16px; }
    .usp-container .usp-content .usp-item {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch; }
      .usp-container .usp-content .usp-item img {
        top: 50%;
        width: 32px;
        height: 32px;
        filter: invert(30%) sepia(67%) saturate(2146%) hue-rotate(205deg) brightness(89%) contrast(98%); }
      .usp-container .usp-content .usp-item .usp-title {
        color: #333333;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 200% */ }
