@font-face {
  font-family: "lifepal-icon";
  src: url("/static/fonts/lifepal/icomoon.eot?hefc7w");
  src: url("/static/fonts/lifepal/icomoon.eot?hefc7w#iefix") format("embedded-opentype"), url("/static/fonts/lifepal/icomoon.ttf?hefc7w") format("truetype"), url("/static/fonts/lifepal/icomoon.woff?hefc7w") format("woff"), url("/static/fonts/lifepal/icomoon.svg?hefc7w#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="lifepal-"],
[class*=" lifepal-"] {
  font-family: "lifepal-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lifepal-location:before {
  content: "\E97F"; }

.lifepal-map:before {
  content: "\E980"; }

.lifepal-cup:before {
  content: "\E952"; }

.lifepal-burger:before {
  content: "\E97B"; }

.lifepal-achievement:before {
  content: "\E900"; }

.lifepal-add-01:before {
  content: "\E901"; }

.lifepal-add:before {
  content: "\E902"; }

.lifepal-arrow-down:before {
  content: "\E903"; }

.lifepal-arrow-left:before {
  content: "\E904"; }

.lifepal-arrow-right:before {
  content: "\E905"; }

.lifepal-arrow-up:before {
  content: "\E906"; }

.lifepal-ask .path1:before {
  content: "\E907";
  color: #333333; }

.lifepal-ask .path2:before {
  content: "\E908";
  margin-left: -1em;
  color: black; }

.lifepal-ask .path3:before {
  content: "\E909";
  margin-left: -1em;
  color: black; }

.lifepal-book:before {
  content: "\E90A"; }

.lifepal-bpjs-kesehatan:before {
  content: "\E90B"; }

.lifepal-bpjs-tk:before {
  content: "\E90C"; }

.lifepal-busy:before {
  content: "\E90D"; }

.lifepal-calendar:before {
  content: "\E90E"; }

.lifepal-call_later:before {
  content: "\E90F"; }

.lifepal-call:before {
  content: "\E910"; }

.lifepal-card:before {
  content: "\E911"; }

.lifepal-category:before {
  content: "\E912"; }

.lifepal-checbox:before {
  content: "\E913"; }

.lifepal-check-round:before {
  content: "\E914"; }

.lifepal-check:before {
  content: "\E915"; }

.lifepal-checkbox-active:before {
  content: "\E916"; }

.lifepal-circle-arrow-right:before {
  content: "\E917"; }

.lifepal-circle-check:before {
  content: "\E918"; }

.lifepal-clinic:before {
  content: "\E919"; }

.lifepal-close-circle:before {
  content: "\E91A"; }

.lifepal-close:before {
  content: "\E91B"; }

.lifepal-coin:before {
  content: "\E91C"; }

.lifepal-compare:before {
  content: "\E91D"; }

.lifepal-consultant:before {
  content: "\E91E"; }

.lifepal-contract:before {
  content: "\E91F"; }

.lifepal-contract2:before {
  content: "\E920"; }

.lifepal-copy:before {
  content: "\E921"; }

.lifepal-dental:before {
  content: "\E922"; }

.lifepal-discount:before {
  content: "\E923"; }

.lifepal-document:before {
  content: "\E924"; }

.lifepal-dollar-01:before {
  content: "\E925"; }

.lifepal-download:before {
  content: "\E926"; }

.lifepal-dropdown-down:before {
  content: "\E927"; }

.lifepal-dropdown-left:before {
  content: "\E928"; }

.lifepal-dropdown-right:before {
  content: "\E929"; }

.lifepal-dropdown-up:before {
  content: "\E92A"; }

.lifepal-edit:before {
  content: "\E92B"; }

.lifepal-eye:before {
  content: "\E92C"; }

.lifepal-facebook .path1:before {
  content: "\E92D";
  color: #333333; }

.lifepal-facebook .path2:before {
  content: "\E92E";
  margin-left: -1em;
  color: #333033; }

.lifepal-favourite:before {
  content: "\E92F"; }

.lifepal-female:before {
  content: "\E930";
  color: #5d5d5d; }

.lifepal-filter:before {
  content: "\E931"; }

.lifepal-fire:before {
  content: "\E932"; }

.lifepal-flag:before {
  content: "\E933"; }

.lifepal-gender:before {
  content: "\E934";
  color: #5d5d5d; }

.lifepal-headphone:before {
  content: "\E935"; }

.lifepal-health:before {
  content: "\E936"; }

.lifepal-history:before {
  content: "\E937"; }

.lifepal-home:before {
  content: "\E938"; }

.lifepal-hospital:before {
  content: "\E939"; }

.lifepal-icons-all:before {
  content: "\E93A"; }

.lifepal-info-solid:before {
  content: "\E93B"; }

.lifepal-info:before {
  content: "\E93C"; }

.lifepal-instagram:before {
  content: "\E93D"; }

.lifepal-link .path1:before {
  content: "\E93E";
  color: #333333; }

.lifepal-link .path2:before {
  content: "\E93F";
  margin-left: -1em;
  color: #333033; }

.lifepal-link .path3:before {
  content: "\E940";
  margin-left: -1em;
  color: #333033; }

.lifepal-linkedin:before {
  content: "\E941"; }

.lifepal-list-approve:before {
  content: "\E942"; }

.lifepal-list:before {
  content: "\E943"; }

.lifepal-login:before {
  content: "\E944"; }

.lifepal-logout:before {
  content: "\E945"; }

.lifepal-long-arrow-down:before {
  content: "\E946"; }

.lifepal-long-arrow-left:before {
  content: "\E947"; }

.lifepal-long-arrow-right:before {
  content: "\E948"; }

.lifepal-long-arrow-up:before {
  content: "\E949"; }

.lifepal-mail:before {
  content: "\E94B"; }

.lifepal-male:before {
  content: "\E94C";
  color: #5d5d5d; }

.lifepal-maternity:before {
  content: "\E94D"; }

.lifepal-minus:before {
  content: "\E94E"; }

.lifepal-missed_call:before {
  content: "\E94F"; }

.lifepal-new:before {
  content: "\E950"; }

.lifepal-notissued:before {
  content: "\E951"; }

.lifepal-paper-edit:before {
  content: "\E953"; }

.lifepal-paper-rip:before {
  content: "\E954"; }

.lifepal-phone:before {
  content: "\E955"; }

.lifepal-pin:before {
  content: "\E956"; }

.lifepal-plus:before {
  content: "\E957"; }

.lifepal-price:before {
  content: "\E958"; }

.lifepal-process:before {
  content: "\E959"; }

.lifepal-profile:before {
  content: "\E95A"; }

.lifepal-quotation:before {
  content: "\E95B"; }

.lifepal-radio-active:before {
  content: "\E95C"; }

.lifepal-radio:before {
  content: "\E95D"; }

.lifepal-refund:before {
  content: "\E95E"; }

.lifepal-reject:before {
  content: "\E95F"; }

.lifepal-setting:before {
  content: "\E960"; }

.lifepal-reward:before {
  content: "\E961"; }

.lifepal-sad:before {
  content: "\E962"; }

.lifepal-sadpal:before {
  content: "\E963"; }

.lifepal-search:before {
  content: "\E964"; }

.lifepal-service:before {
  content: "\E965"; }

.lifepal-shield:before {
  content: "\E966"; }

.lifepal-snow:before {
  content: "\E967"; }

.lifepal-sort:before {
  content: "\E968"; }

.lifepal-status:before {
  content: "\E969"; }

.lifepal-suspend:before {
  content: "\E96A"; }

.lifepal-thumb:before {
  content: "\E96B"; }

.lifepal-time:before {
  content: "\E96C"; }

.lifepal-twitter .path1:before {
  content: "\E96D";
  color: #333333; }

.lifepal-twitter .path2:before {
  content: "\E96E";
  margin-left: -1em;
  color: #333033; }

.lifepal-upload:before {
  content: "\E96F"; }

.lifepal-user:before {
  content: "\E970"; }

.lifepal-vehicle:before {
  content: "\E971"; }

.lifepal-voucher-01:before {
  content: "\E972"; }

.lifepal-warning-solid:before {
  content: "\E973"; }

.lifepal-warning:before {
  content: "\E974"; }

.lifepal-wave:before {
  content: "\E975"; }

.lifepal-whatsapp:before {
  content: "\E976"; }

.lifepal-wind:before {
  content: "\E977"; }

.lifepal-wrench-01:before {
  content: "\E978"; }

.lifepal-youtube .path1:before {
  content: "\E979";
  color: #333333; }

.lifepal-youtube .path2:before {
  content: "\E97A";
  margin-left: -1em;
  color: #333033; }

.lifepal-help-black:before {
  content: "\E985"; }

.lifepal-profile-black:before {
  content: "\E986"; }

.lifepal-polis-black:before {
  content: "\E988"; }

.lifepal-pengajuan-black:before {
  content: "\E989"; }

.lifepal-home-black:before {
  content: "\E98A"; }
