/*
* ink colors (with variations only for black and white)
*/
.auto-debit__form--button {
  width: 100% !important; }

.auto-debit__form--row {
  padding-right: 80px; }
  .auto-debit__form--row .row__item p {
    margin-bottom: 0; }
  .auto-debit__form--row .row__item--expiry {
    display: flex;
    margin-bottom: -15px; }
    .auto-debit__form--row .row__item--expiry .field.FieldForm {
      width: 100%; }
    .auto-debit__form--row .row__item--expiry span {
      margin-left: calc(calc(0.5 * 1rem) + calc(0.25 * 1rem));
      margin-top: calc(0.75 * 1rem);
      color: #dfdfdf; }
    .auto-debit__form--row .row__item--expiry .field:nth-last-child(1) {
      margin-left: calc(calc(0.5 * 1rem) + calc(0.25 * 1rem)); }
