/*
* ink colors (with variations only for black and white)
*/
.account__summary {
  padding: calc(0.5 * 1rem);
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .account__summary .balance__info {
    width: 100%; }
    @media (min-width: 576px) {
      .account__summary .balance__info {
        width: 50%; } }

.withdraw__input--block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: calc(1.25 * 1rem); }
  @media (min-width: 576px) {
    .withdraw__input--block {
      width: 50%; } }
  .withdraw__input--block .input__title--block {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .withdraw__input--block .input__title--block span:last-child {
      cursor: pointer;
      color: #1D66DD; }
      .withdraw__input--block .input__title--block span:last-child:hover {
        text-decoration: underline; }
    .withdraw__input--block .input__title--block .disable {
      opacity: 0.6;
      pointer-events: none; }
  .withdraw__input--block .input__desc--end {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.withdraw__input--content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  height: 94px;
  width: 100%; }
  @media (min-width: 576px) {
    .withdraw__input--content {
      width: 50%; } }

.setup__bankaccount {
  padding: calc(0.75 * 1rem) calc(1.25 * 1rem); }
  @media (min-width: 576px) {
    .setup__bankaccount {
      padding-right: 0;
      width: 50%; } }
  .setup__bankaccount.clickable {
    cursor: pointer; }
  .setup__bankaccount .bank__info {
    justify-content: flex-start !important; }
    .setup__bankaccount .bank__info .bank__logo {
      height: 15px;
      width: 47px;
      margin-right: calc(1.25 * 1rem);
      transform: scale(2);
      margin-left: 16px; }
      .setup__bankaccount .bank__info .bank__logo img {
        width: 100% !important;
        height: 100% !important;
        vertical-align: top; }
    .setup__bankaccount .bank__info .bank__details--placeholder {
      display: flex; }
      .setup__bankaccount .bank__info .bank__details--placeholder .shine {
        display: inline-block;
        position: relative;
        background: #f6f7f8;
        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 800px 104px;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear; }
      .setup__bankaccount .bank__info .bank__details--placeholder .avatar {
        align-self: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-left: 0; }
      .setup__bankaccount .bank__info .bank__details--placeholder div {
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        margin-left: calc(1.25 * 1rem); }
      .setup__bankaccount .bank__info .bank__details--placeholder lines {
        height: 10px;
        margin-top: 10px;
        width: 200px; }

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
    .setup__bankaccount .bank__info .bank__details {
      display: inline-flex;
      flex-direction: column;
      margin-left: calc(0.75 * 1rem); }
      .setup__bankaccount .bank__info .bank__details span:nth-child(odd) {
        color: #5e5e5e; }
      .setup__bankaccount .bank__info .bank__details span {
        color: #333333; }
    .setup__bankaccount .bank__info .navigation__block {
      margin-left: auto; }
      .setup__bankaccount .bank__info .navigation__block i {
        font-size: 20px; }
  .setup__bankaccount .add__account {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(0.75 * 1rem);
    height: 94px;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    color: #1D66DD; }
    .setup__bankaccount .add__account span {
      margin-right: calc(0.75 * 1rem); }

.mobile .action__container {
  width: 50%;
  padding: calc(1.25 * 1rem) 0 calc(1.25 * 1rem) calc(1.25 * 1rem); }
  @media (max-width: 767.98px) {
    .mobile .action__container {
      width: 100%;
      padding: calc(1.25 * 1rem); } }
  .mobile .action__container .alert__error {
    margin-bottom: calc(0.5 * 1rem);
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    padding: calc(0.25 * 1rem);
    border-radius: calc(0.25 * 1rem);
    text-align: center; }
  .mobile .action__container button {
    width: 100%;
    padding: calc(0.5 * 1rem); }

.add__bank--wrapper {
  margin-top: calc(1.25 * 1rem); }

.withdrawal__container {
  padding: calc(1.25 * 1rem);
  margin-top: calc(1.25 * 1rem); }
  .withdrawal__container .withdrawal__filter--wrapper {
    display: flex; }
    @media (max-width: 991.98px) {
      .withdrawal__container .withdrawal__filter--wrapper {
        justify-content: space-between; } }
    .withdrawal__container .withdrawal__filter--wrapper .filter__item {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%; }
      @media (min-width: 768px) {
        .withdrawal__container .withdrawal__filter--wrapper .filter__item:first-child {
          margin-right: calc(1.25 * 1rem); }
        .withdrawal__container .withdrawal__filter--wrapper .filter__item .DatePicker {
          width: 100%; }
          .withdrawal__container .withdrawal__filter--wrapper .filter__item .DatePicker input {
            width: 50%; } }
      .withdrawal__container .withdrawal__filter--wrapper .filter__item .DatePicker {
        width: 100%; }
        .withdrawal__container .withdrawal__filter--wrapper .filter__item .DatePicker input {
          width: 100%; }

.withdrawal__history--wrapper {
  margin-top: calc(1.25 * 1rem); }

.loader {
  margin-top: calc(5.25 * 1rem);
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .loader .lds-ring {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px; }
  .loader .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25.5px;
    height: 25.5px;
    margin: 3px;
    border: 3px solid #1D66DD;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1D66DD transparent transparent transparent; }
  .loader .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
  .loader .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
  .loader .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
