/*
* ink colors (with variations only for black and white)
*/
.withdrawal__info--card {
  padding: 0; }
  .withdrawal__info--card .card__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 calc(1.25 * 1rem);
    height: 50px; }
    .withdrawal__info--card .card__header .withdrawal__id {
      color: #717171;
      width: 70%;
      margin-bottom: 0;
      padding-top: 15px;
      height: 100%;
      border-right: 1px solid #dfdfdf; }
      @media (max-width: 991.98px) {
        .withdrawal__info--card .card__header .withdrawal__id {
          width: 50%; } }
    .withdrawal__info--card .card__header .withdrawal__time {
      height: 100%;
      padding-top: 15px;
      margin-left: auto;
      margin-bottom: 0;
      color: #c2c2c2; }
  .withdrawal__info--card .card__body {
    padding: calc(1.25 * 1rem); }
    .withdrawal__info--card .card__body .withdrawal__details {
      display: inline-flex;
      width: 100%; }
    .withdrawal__info--card .card__body .withdrawal__amount {
      color: #1D66DD; }
    .withdrawal__info--card .card__body .withdrawal__bank {
      color: #c2c2c2;
      margin-left: auto;
      margin-right: calc(0.5 * 1rem); }
    @media (max-width: 991.98px) {
      .withdrawal__info--card .card__body .withdrawal__status {
        margin-left: auto; } }
    .withdrawal__info--card .card__body .withdrawal__status .status__button {
      padding: 0 calc(calc(0.25 * 1rem) + calc(0.75 * 1rem));
      border-radius: calc(0.25 * 1rem);
      outline: none; }
