/*
* ink colors (with variations only for black and white)
*/
.email__subscription--title {
  margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }

.email__subscription--form .form__body {
  margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }

.email__subscription--form .form__button {
  width: 100%; }
