/*
* ink colors (with variations only for black and white)
*/
.insurance__providers--dropdown {
  position: relative;
  margin-bottom: calc(1.25 * 1rem); }
  .insurance__providers--dropdown .InputForm.control:before {
    font-family: "Ionicons", serif;
    content: "\F4A5";
    margin-right: calc(0.5 * 1rem);
    margin-left: calc(0.5 * 1rem);
    color: #1D66DD;
    font-size: large;
    position: absolute;
    left: 0;
    top: calc(0.5 * 1rem); }
  .insurance__providers--dropdown .InputForm .Wrapper {
    padding-left: calc(2 * 1rem); }

.insurance__providers--filter {
  display: flex;
  align-items: center; }
  .insurance__providers--filter span {
    display: block;
    width: 50%; }
  .insurance__providers--filter .filter__dropdown {
    width: 50%; }

.insurance__providers--card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  margin-bottom: calc(0.75 * 1rem); }
  .insurance__providers--card .providers-card__header {
    padding: calc(0.75 * 1rem);
    border-bottom: 1px solid #dfdfdf; }
  .insurance__providers--card .providers-card__body {
    padding: calc(0.75 * 1rem); }
