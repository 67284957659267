/*
* ink colors (with variations only for black and white)
*/
.blacklisted-ktp__view--header {
  display: flex; }
  .blacklisted-ktp__view--header > span {
    margin-left: calc(1.25 * 1rem); }

.blacklisted-ktp__view--body {
  display: flex; }
  .blacklisted-ktp__view--body .body__left {
    width: 40%;
    padding: calc(1.25 * 1rem); }
  .blacklisted-ktp__view--body .body__right {
    width: 60%;
    padding: calc(1.25 * 1rem); }

.blacklisted-ktp__view--footer {
  display: flex; }
  .blacklisted-ktp__view--footer .footer__item {
    width: 50%;
    padding: calc(0.25 * 1rem); }
    .blacklisted-ktp__view--footer .footer__item button {
      width: 100%; }
