/*
* ink colors (with variations only for black and white)
*/
.form__wrapper--full, .form__wrapper--half, .form__wrapper--buttonHalf {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.lp__login .auth__form--wrapper {
  background-color: #1D66DD;
  height: 70vh;
  border-radius: calc(0.5 * 1rem); }
  @media (min-width: 768px) {
    .lp__login .auth__form--wrapper {
      padding: calc(1.25 * 1rem);
      margin-top: -10px; } }
  @media (max-width: 991.98px) {
    .lp__login .auth__form--wrapper {
      height: 50vh;
      padding: calc(0.75 * 1rem);
      margin-top: 0px;
      border-radius: calc(0.5 * 1rem); } }

.lp__login .title {
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  position: absolute;
  top: -50px;
  right: 30%; }

.lp__login .icon__offset {
  float: left;
  left: 0 !important; }

.lp__login .icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border-radius: 50%; }
  .lp__login .icon__wrapper i {
    color: #1D66DD; }

.lp__login .loginRegister__wrapper {
  position: relative; }
  .lp__login .loginRegister__wrapper--col {
    display: grid;
    margin: -15px auto 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.5 * 1rem);
    background-color: #ffffff;
    top: 10vh; }
    @media (max-width: 991.98px) {
      .lp__login .loginRegister__wrapper--col {
        width: auto !important;
        margin: 0;
        margin-top: -41px;
        padding: 0; }
        .lp__login .loginRegister__wrapper--col .title {
          top: -44px; } }

.lp__login .loginRegister__body {
  width: 100%;
  overflow: hidden; }

@media (min-width: 768px) {
  .login__wrapper .footer__wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 200px;
    padding-top: 0; } }

@media (max-width: 991.98px) {
  .login__wrapper .footer__wrapper {
    bottom: -30px; } }

.form__wrapper {
  display: flex;
  flex-wrap: wrap; }
  .form__wrapper .phone__field .field {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .form__wrapper .phone__field .field span {
      background: #1D66DD;
      padding: calc(0.5 * 1rem);
      border-radius: calc(0.25 * 1rem) 0 0 calc(0.25 * 1rem);
      color: #ffffff;
      font-size: 15px; }
    .form__wrapper .phone__field .field input {
      padding: calc(0.5 * 1rem);
      border: 1px solid #c2c2c2;
      border-radius: 0 calc(0.25 * 1rem) calc(0.25 * 1rem) 0;
      width: 100%; }
    .form__wrapper .phone__field .field input[type='tel']:focus {
      outline: none;
      border: 1px solid #1D66DD; }
  .form__wrapper .phone__field .error {
    color: #E81A1A; }
  .form__wrapper--full {
    flex: 0 0 100%;
    max-width: 100%; }
    .form__wrapper--full:not(:last-child) {
      margin-bottom: calc(1.25 * 1rem); }
      @media (max-width: 991.98px) {
        .form__wrapper--full:not(:last-child) {
          margin-bottom: calc(0.5 * 1rem); } }
    .form__wrapper--full:first-child {
      margin-top: calc(0.5 * 1rem); }
    @media (max-width: 991.98px) {
      .form__wrapper--full button {
        padding: 0; } }
    .form__wrapper--full button span {
      font-size: 11px; }
      @media (max-width: 991.98px) {
        .form__wrapper--full button span {
          font-size: 9px; } }
  .form__wrapper--half {
    flex: 0 0 50%;
    max-width: 50%; }
  .form__wrapper--buttonHalf {
    flex: 0 0 50%;
    max-width: 50%; }
    @media (max-width: 991.98px) {
      .form__wrapper--buttonHalf {
        flex: 0 0 100%;
        max-width: 100%; } }
  .form__wrapper--date {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(0.25 * 1rem); }
  .form__wrapper .field__label {
    font-size: 12px;
    margin-bottom: calc(0.5 * 1rem); }
  .form__wrapper .color-lp-red {
    margin: 0; }
  .form__wrapper fieldset {
    border: 1px solid transparent;
    border-top-color: #5C5C5C;
    box-sizing: border-box;
    padding: 0 calc(1.25 * 1rem);
    margin-top: calc(0.75 * 1rem); }
    .form__wrapper fieldset legend {
      margin: auto;
      padding: 0 4px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-align: center;
      width: 50%; }
  .form__wrapper .icon__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: calc(1.25 * 1rem) 0; }
    @media (max-width: 991.98px) {
      .form__wrapper .icon__button {
        margin: calc(0.75 * 1rem) 0; } }
    .form__wrapper .icon__button .image__wrapper {
      width: 20%;
      margin-right: calc(1.25 * 1rem); }
      .form__wrapper .icon__button .image__wrapper i {
        font-size: 36px;
        vertical-align: middle;
        color: #1D66DD; }
      .form__wrapper .icon__button .image__wrapper img {
        width: 16px !important;
        height: 16px !important; }

@media (max-width: 991.98px) {
  .desktop__buttons {
    display: none; } }

.mobile__buttons--outline {
  display: flex;
  justify-content: center;
  margin-top: calc(0.75 * 1rem);
  color: #1D66DD;
  cursor: pointer; }

@media (min-width: 768px) {
  .mobile__buttons {
    display: none; } }
