/*
* ink colors (with variations only for black and white)
*/
.mainPlanProductList {
  margin-bottom: 20px;
  padding-top: 22px;
  position: relative; }
  @media (max-width: 991.98px) {
    .mainPlanProductList {
      padding-top: 20px; } }
  .mainPlanProductList .product__wrapper {
    position: relative;
    margin-top: 0px;
    min-height: 500px; }
    @media (max-width: 991.98px) {
      .mainPlanProductList .product__wrapper {
        top: 30px;
        margin-top: 0; } }

.sort-modal-wrapper {
  background-color: white;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding: 24px; }
  .sort-modal-wrapper .sort-modal-radio {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: row; }
    .sort-modal-wrapper .sort-modal-radio input[type='radio'] {
      accent-color: #EA4B4B; }

.sort-modal {
  font-weight: 500;
  font-size: 14px;
  line-height: 8px;
  letter-spacing: inherit;
  color: #183b56;
  background-color: #FFFFFF;
  cursor: pointer; }

.activeSort {
  color: #EA4B4B;
  font-weight: 600; }

.plan {
  background-color: #ffffff;
  /* Plan Card */ }
  .plan-header {
    z-index: 1020;
    border-top: 1px solid #dfdfdf;
    padding: 20px 0 0;
    position: fixed;
    background: white;
    width: 100%;
    top: 57.75px; }
    .plan-header .toggle-filter {
      position: absolute;
      right: 15px;
      top: 0; }
      @media (min-width: 992px) {
        .plan-header .toggle-filter {
          position: static; } }
  .plan-top {
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 1em; }
  .plan-compare {
    background: #f8f9fa;
    padding: 20px; }
  .plan-filter {
    display: none;
    overflow-y: auto; }
    .plan-filter.is-open {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: white;
      margin: 0 auto; }
      @media (min-width: 992px) {
        .plan-filter.is-open {
          overflow-y: visible;
          display: block;
          position: absolute;
          width: 100%;
          left: auto;
          right: auto;
          margin-left: -15px;
          margin-right: -15px;
          top: 1em;
          background: transparent; } }
    .plan-filter__header {
      position: relative;
      padding: 20px 0;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 1.5em;
      border-bottom: 2px solid #dfdfdf; }
      .plan-filter__header .btn[type="button"] {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%); }
      .plan-filter__header .btn[type="submit"] {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%); }
      @media (min-width: 992px) {
        .plan-filter__header {
          margin-left: auto;
          margin-right: auto;
          padding: 20px; } }
    @media (min-width: 992px) {
      .plan-filter {
        display: flex; }
        .plan-filter__modal {
          z-index: 1030;
          max-height: 85vh;
          overflow-y: auto;
          overflow-x: hidden;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 40%;
          background: white;
          border: 1px solid #dfdfdf;
          border-radius: 4px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13); } }
  .plan-card {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    columns: 1;
    column-gap: 10px; }
    .plan-card .header {
      padding: 10px; }
      .plan-card .header .logo {
        width: 40%;
        margin-bottom: 20px; }
        .plan-card .header .logo img {
          width: auto;
          max-width: 130px;
          max-height: 30px; }
        @media (min-width: 992px) {
          .plan-card .header .logo {
            width: auto; } }
      .plan-card .header .info > h3 {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600; }
    .plan-card .action {
      display: flex;
      flex-wrap: wrap;
      padding: 10px; }
      .plan-card .action .btn:nth-child(1) {
        flex: 1;
        order: 2;
        margin-right: 1em; }
      .plan-card .action .btn:last-child {
        margin-right: 0; }
      .plan-card .action .btn:nth-child(2) {
        flex: 1;
        order: 3; }
      .plan-card .action .btn:nth-child(3) {
        order: 1;
        width: 100%;
        margin-bottom: 1em; }
      .plan-card .action .btn:nth-child(1), .plan-card .action .btn:nth-child(2) {
        border-color: #dfdfdf;
        border-width: 1px; }
      .plan-card .action .btn.selected:nth-child(1) {
        border-width: 2px;
        font-size: 500;
        border-color: #1d66dd; }
      @media (min-width: 992px) {
        .plan-card .action .btn:nth-child(1) {
          order: 1; }
        .plan-card .action .btn:nth-child(2) {
          order: 2;
          margin-right: 1em; }
        .plan-card .action .btn:nth-child(3) {
          flex: 1;
          margin-bottom: 0;
          order: 3; } }
    .plan-card .footer {
      border-top: 1px solid #dfdfdf;
      padding: 10px 20px; }
      .plan-card .footer .detail-toggle a {
        color: #333;
        font-weight: 500;
        position: relative;
        display: block; }
        .plan-card .footer .detail-toggle a::after {
          position: absolute;
          right: 0;
          top: -5px;
          content: "\F280";
          font-family: "Ionicons", sans-serif;
          font-size: 21px;
          color: #1d66dd; }
        .plan-card .footer .detail-toggle a.opened::after {
          content: "\F286"; }
      .plan-card .footer .detail {
        margin-top: 10px; }
        .plan-card .footer .detail ul {
          margin: 0 auto;
          padding: 0 1.25em;
          list-style-type: disc; }
    @media (min-width: 992px) {
      .plan-card {
        columns: 2;
        column-gap: 20px;
        margin-bottom: 2em; }
        .plan-card .header {
          padding: 20px; }
        .plan-card .action {
          padding: 0 20px 20px; }
        .plan-card footer {
          padding: 20px; } }
    .plan-card > li {
      width: 100%;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      margin-bottom: 20px;
      break-inside: avoid; }
      @media (max-width: 1199.98px) {
        .plan-card > li:last-child {
          margin-bottom: 0; } }
  .plan .card {
    border-color: #dfdfdf;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
    border-radius: 4px; }
    .plan .card-compare {
      display: flex; }
      .plan .card-compare .item {
        flex: 1;
        padding: 1em 2em; }
        .plan .card-compare .item:nth-child(2) {
          border-left: 2px solid #dfdfdf;
          border-right: 2px solid #dfdfdf; }
        .plan .card-compare .item:last-child {
          border-right: none; }
        .plan .card-compare .item .header {
          display: flex;
          align-items: center;
          height: 100px;
          position: relative; }
          .plan .card-compare .item .header::after {
            content: "";
            display: block;
            clear: both; }
          .plan .card-compare .item .header img {
            max-height: 100%;
            max-width: 50%; }
          .plan .card-compare .item .header button {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            border: none;
            color: #b6b6bb; }
        .plan .card-compare .item dl {
          margin-top: 1em; }
          .plan .card-compare .item dl dt {
            clear: both;
            float: left;
            font-weight: normal; }
          .plan .card-compare .item dl dd {
            width: 60%;
            float: right; }
    .plan .card-footer {
      background: white; }
      .plan .card-footer .text {
        color: #b6b6bb; }
  .plan-form-family nav {
    height: 60px;
    border-bottom: 1px solid #dfdfdf;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem; }
    .plan-form-family nav button {
      padding: 0;
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%); }
  .plan-form-family-desktop .beneficiary {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 1em; }

.micro-header {
  border-top: 1px solid #dfdfdf;
  padding: 10px 10px 0 10px;
  position: fixed;
  top: 57.75px;
  background: white; }

.plan__right--panel .product__list {
  width: 100%;
  height: 100%; }
  .plan__right--panel .product__list .plan__listing--product {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 2px rgba(163, 177, 198, 0.5); }

.filter-base {
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  margin-top: 20px;
  background-color: #ffffff; }
  .filter-base-box .footer-button {
    display: flex;
    gap: 8px;
    flex-direction: row;
    margin: 12px 0;
    justify-content: end; }
    .filter-base-box .footer-button .reset {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 0;
      text-align: center;
      letter-spacing: 0.2px;
      color: #1D66DD;
      border: 1px solid #1D66DD;
      background: none;
      min-width: 0px !important;
      width: 72px; }
      .filter-base-box .footer-button .reset:hover {
        box-shadow: none !important; }
      .filter-base-box .footer-button .reset:focus {
        box-shadow: none !important; }
  .filter-base-box .filter__view {
    font-weight: bold;
    font-size: 14px;
    color: #1d66dd;
    display: flex;
    justify-content: center; }
    .filter-base-box .filter__view span {
      padding: 0 10px; }
    .filter-base-box .filter__view button {
      border: none;
      background: none; }
  .filter-base-box .filter__separator {
    border-top: 1px dashed #e2e8f0;
    width: 100%;
    margin: calc(0.75 * 1rem) 0; }
  @media (max-width: 768px) {
    .filter-base-modal-box {
      background-color: white;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      padding: 15px 24px 24px; } }
  .filter-base-modal-box h1 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px; }
  .filter-base__InsuranceB {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    width: 248px;
    max-height: 500px;
    overflow: scroll; }
    .filter-base__InsuranceB::-webkit-scrollbar {
      width: 4px;
      display: block !important; }
    .filter-base__InsuranceB::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #EA4B4B; }
    @media (max-width: 768px) {
      .filter-base__InsuranceB {
        white-space: nowrap;
        margin-right: -10px !important;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        width: 100%; } }
    .filter-base__InsuranceB ~ .filter__separator {
      margin-top: 20px;
      margin-bottom: 20px; }
    .filter-base__InsuranceB .filter__item input,
    .filter-base__InsuranceB .filter__item label {
      cursor: pointer;
      color: #1D4476;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      letter-spacing: 0.28px; }
    .filter-base__InsuranceB .picker-custom {
      display: inline-block;
      border-radius: 17.5px;
      border: solid 1px #dfdfdf;
      padding: 6px 10px;
      margin-right: 8px;
      cursor: pointer; }
      .filter-base__InsuranceB .picker-custom.active {
        background: #e4eefd;
        color: #1d66dd;
        border: solid 1px #1d66dd; }
      @media (min-width: 768px) {
        .filter-base__InsuranceB .picker-custom {
          display: none; } }
    .filter-base__InsuranceB .form-check {
      padding-left: 20px;
      height: 16px; }
      .filter-base__InsuranceB .form-check input[type='checkbox'] {
        accent-color: #EA4B4B; }
  @media (max-width: 768px) {
    .filter-base__Rating {
      width: inherit;
      overflow-y: scroll;
      white-space: nowrap;
      margin-right: -10px !important;
      padding-bottom: 8px; } }
  .filter-base__Rating .radio-custom {
    border-radius: 17.5px;
    border: solid 1px #dfdfdf;
    padding: 6px 10px;
    margin-right: 8px;
    cursor: pointer; }
    .filter-base__Rating .radio-custom.active {
      background: #e4eefd;
      color: #1d66dd;
      border: solid 1px #1d66dd; }
    @media (max-width: 768px) {
      .filter-base__Rating .radio-custom {
        display: inline-block; } }
    @media (min-width: 768px) {
      .filter-base__Rating .radio-custom {
        margin-bottom: 10px; } }
    .filter-base__Rating .radio-custom i.ion-ios-star {
      color: #ffc500; }
    .filter-base__Rating .radio-custom i.ion-md-radio-button-off {
      color: #dfdfdf; }
  .filter-base__life {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    box-shadow: 0 2px 14px -2px rgba(0, 0, 0, 0.22);
    margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    background-color: #ffffff; }
  @media (max-width: 768px) {
    .filter-base__Website {
      display: none; } }
  .filter-base h1 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px; }
  .filter-base a {
    font-size: 14px;
    font-weight: normal;
    color: #1d66dd;
    cursor: pointer; }

@media (max-width: 991.98px) {
  .autoPlanProductList--productList {
    display: flex;
    flex-direction: column; } }

.plan__right-panel {
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .plan__right-panel {
      display: none; } }
  .plan__right-panel .right__panel-item {
    position: relative;
    width: 260px;
    background: rgba(29, 102, 221, 0.02);
    border: 1px solid rgba(29, 102, 221, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px 20px 20px 20px;
    margin-bottom: 20px; }
    .plan__right-panel .right__panel-item .img__fill {
      position: absolute;
      top: 0;
      right: 0; }
  .plan__right-panel .title,
  .plan__right-panel .sub__title {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #183b56;
    padding-top: 12px; }
  .plan__right-panel .title {
    font-weight: 600;
    width: 95%; }
  .plan__right-panel .sub__title {
    font-weight: 500; }

.floating__button--wrapper {
  display: flex;
  width: 100%; }
  .floating__button--wrapper:not(:last-child) {
    border-right: 1px solid #D6D6D6; }

.floating__button--item {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  margin: 0 auto;
  max-height: 28px;
  max-width: 115px;
  padding: 0 12px !important; }
  .floating__button--item img {
    filter: brightness(0) saturate(100%);
    height: 15px;
    width: auto; }
  .floating__button--item .floating__button--label {
    font-size: 12px !important; }

.floating__button--item.active {
  background-color: #E4EDFB; }
  .floating__button--item.active img {
    filter: brightness(0) saturate(100%) invert(31%) sepia(92%) saturate(1420%) hue-rotate(202deg) brightness(87%) contrast(99%); }
  .floating__button--item.active .floating__button--label {
    color: #1d66dd;
    font-weight: 700; }

.floating__button--item.disable__menu {
  display: none; }

.confirmation__modal .modal {
  top: 30% !important; }

.confirmation__modal .modal-header h5 {
  font-size: 16px; }
  @media (max-width: 991.98px) {
    .confirmation__modal .modal-header h5 {
      font-size: 14px; } }

.filter__section {
  align-items: center;
  background-color: white;
  border-bottom: 0.5px solid #D6D6D6;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 16px 0;
  position: sticky;
  top: 68px;
  width: 100%;
  z-index: 5; }
  @media (max-width: 991.98px) {
    .filter__section {
      display: none; } }
  .filter__section--group {
    display: flex;
    height: auto;
    width: 100%;
    transition: opacity ease 0.5s;
    padding: 0;
    align-items: center; }
  .filter__section-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: white; }
  .filter__section-subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: white; }
  .filter__section .filter-base-box {
    width: 248px; }
    .filter__section .filter-base-box .filter__header {
      padding-bottom: 16px; }
      .filter__section .filter-base-box .filter__header .filter-header-title {
        font-weight: 700;
        size: 16px;
        line-height: 20px;
        color: #222222; }
    .filter__section .filter-base-box .filter__separator {
      margin-top: 20px !important;
      margin-bottom: 16px !important; }
    .filter__section .filter-base-box .auto-width {
      width: auto !important; }
    .filter__section .filter-base-box .footer-button {
      margin: 0px 0 !important; }
      .filter__section .filter-base-box .footer-button .filter-asurance-submit {
        width: 168px;
        background-color: #EA4B4B;
        background-color: #EA4B4B;
        border-color: #EA4B4B;
        margin-left: 8px; }
      .filter__section .filter-base-box .footer-button .reset {
        padding: 0 !important;
        width: 72px;
        color: #EA4B4B;
        border-color: #EA4B4B; }
