.ReviewContainer .review-width {
  margin: 0 auto; }
  @media (min-width: 768px) {
    .ReviewContainer .review-width {
      max-width: 66.6%; } }

.Refund-Detail .header {
  padding: 20px 30px;
  border-bottom: 1px solid #d3d3d3; }
  .Refund-Detail .header img {
    width: 100px; }
  .Refund-Detail .header p {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin: 0px; }

.Refund-Detail .cancelReason {
  padding: 20px 30px;
  border-bottom: 1px solid #d3d3d3; }

.Refund-Detail .detail {
  padding: 20px 30px;
  border-bottom: 1px solid #d3d3d3; }
  .Refund-Detail .detail .body {
    display: flex; }
    .Refund-Detail .detail .body p {
      margin-bottom: 0.5rem; }
    .Refund-Detail .detail .body .title {
      font-weight: 500;
      font-size: 14px;
      color: #333333; }
    .Refund-Detail .detail .body .subTitle {
      font-size: 14px;
      color: #5e5e5e;
      margin-left: auto; }

.clickable {
  cursor: pointer; }

.icon-close {
  float: right;
  cursor: pointer;
  font-size: x-large; }

.float-btn {
  position: fixed;
  width: 160px;
  height: 48px;
  bottom: 40px;
  right: 40px;
  background-color: white;
  color: #1d66dd;
  border-radius: 60px;
  border: solid 1px #1d66dd;
  text-align: center;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer; }
  .float-btn p {
    margin-top: 12px;
    font-weight: bold; }
