/*
* ink colors (with variations only for black and white)
*/
.privacy--container {
  font-family: "Open Sans" !important;
  background-color: #E4EDFB;
  padding: 16px; }
  @media (min-width: 576px) {
    .privacy--container {
      width: 100%;
      margin: 0 auto; } }
  .privacy--container .privacy__content--container {
    width: 100%; }
    @media (min-width: 576px) {
      .privacy--container .privacy__content--container {
        width: 1120px;
        margin: 0 auto; } }
  .privacy--container h3 {
    margin: 0 0 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #333; }
    @media (min-width: 576px) {
      .privacy--container h3 {
        font-size: 28px;
        line-height: 36px; } }
