/*
* ink colors (with variations only for black and white)
*/
.budget__list--psf {
  padding-left: 0; }
  .budget__list--psf .disable {
    opacity: 0.5;
    cursor: not-allowed; }
  .budget__list--psf .budget__item {
    padding: calc(0.75 * 1rem);
    display: flex;
    align-items: center;
    flex-direction: row; }
    .budget__list--psf .budget__item:not(:last-child) {
      margin-bottom: calc(0.75 * 1rem); }
    .budget__list--psf .budget__item:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
      .budget__list--psf .budget__item:hover .budget__value {
        color: #1D66DD; }
    .budget__list--psf .budget__item input {
      align-self: center; }
    .budget__list--psf .budget__item .budget__value {
      margin-left: calc(0.75 * 1rem);
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #717171;
      margin-bottom: 0;
      cursor: pointer;
      width: 100%; }

#bottomSheet {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(2 * 1rem) calc(2 * 1rem) 0px 0px;
  transition: all 0.4s ease;
  width: 100%;
  left: 0;
  height: 300px;
  padding: calc(1.25 * 1rem); }
  @media (min-width: 768px) {
    #bottomSheet {
      left: 35%;
      width: 30%;
      height: 200px; } }
  #bottomSheet .InputForm .Phone::before, #bottomSheet .InputForm .Currency::before {
    background: #d6d6d6; }
  #bottomSheet input.Wrapper.PhoneInput.input:focus {
    outline: none; }

.bottomSheet__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }
  .bottomSheet__header h2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #717171; }
  .bottomSheet__header .plain__button {
    color: #717171;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 0;
    text-decoration: none; }
    .bottomSheet__header .plain__button:hover {
      text-decoration: underline; }

.bottomSheet__footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(3.25 * 1rem); }
  .bottomSheet__footer button {
    position: absolute; }

.bottomSheet--onScreen {
  position: fixed;
  bottom: 0; }

.bottomSheet--offScreen {
  position: fixed;
  bottom: -300px; }
