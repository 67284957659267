/*
* ink colors (with variations only for black and white)
*/
.declaration__date {
  display: flex; }
  .declaration__date--column {
    width: 20%; }
    .declaration__date--column:not(:first-child) {
      margin-left: calc(0.5 * 1rem); }

.declaration__file--button .button__action {
  cursor: pointer;
  color: #1D66DD; }
  .declaration__file--button .button__action:hover {
    text-decoration: underline; }
