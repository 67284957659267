/*
* ink colors (with variations only for black and white)
*/
.login__head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  justify-content: start;
  align-items: end;
  background: linear-gradient(180deg, #4380E3 0%, #1D66DD 48.5%, #1855B8 100%);
  height: 96px;
  border-radius: 8px 8px 0px 0px; }
  .login__head .title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: #ffffff; }
  .login__head .title__modal_text {
    margin: 0 0 calc(0.75 * 1rem);
    padding: unset;
    color: white;
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700; }
  .login__head .back {
    display: flex;
    border-radius: 50px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    cursor: pointer; }
  .login__head .back--modal {
    display: flex;
    border-radius: 50px;
    background-color: #ffffff;
    border: 1px solid #1D66DD;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer; }
    .login__head .back--modal i {
      left: -6px; }
  .login__head .close-button {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 12px;
    left: 12px;
    border: none;
    background: none;
    cursor: pointer;
    right: unset; }
