/*
* ink colors (with variations only for black and white)
*/
.changeplan__card .field__label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: calc(0.5 * 1rem); }
  @media (max-width: 991.98px) {
    .changeplan__card .field__label {
      margin-top: calc(1.25 * 1rem); } }

.changeplan__card .header__left--title {
  padding-top: calc(0.75 * 1rem);
  padding-bottom: calc(0.75 * 1rem); }

.changeplan__card .ion-ios-checkmark-circle-outline {
  display: none !important; }
