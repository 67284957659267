/*
* ink colors (with variations only for black and white)
*/
.popular__cities {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0;
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border-radius: calc(0.5 * 1rem); }
  @media (max-width: 991.98px) {
    .popular__cities {
      margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
  .popular__cities--title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .popular__cities p {
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .popular__cities--body {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .popular__cities--body .body__city {
      width: 50%; }
      .popular__cities--body .body__city a {
        display: block;
        text-transform: capitalize;
        margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
    .popular__cities--body button {
      border: 1px solid #1D66DD;
      color: #1D66DD; }
