/*
* ink colors (with variations only for black and white)
*/
.bengkel__searchmap-form--body {
  padding: calc(0.75 * 1rem); }
  .bengkel__searchmap-form--body .body__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: calc(0.5 * 1rem);
    line-height: 2; }
  .bengkel__searchmap-form--body .body__row {
    display: flex;
    flex-direction: column; }
    .bengkel__searchmap-form--body .body__row--field {
      width: 100%;
      margin-bottom: calc(0.75 * 1rem); }
      .bengkel__searchmap-form--body .body__row--field label {
        margin-bottom: calc(0.25 * 1rem);
        font-size: 11px; }
  .bengkel__searchmap-form--body .body__submit-btn {
    margin-top: calc(0.5 * 1rem);
    padding: calc(0.5 * 1rem) 0;
    border: none;
    border-radius: calc(0.25 * 1rem);
    color: #ffffff;
    background: #faaf40;
    width: 100%; }
  .bengkel__searchmap-form--body .body__cancel-btn {
    margin-top: calc(0.5 * 1rem);
    background-color: #ffffff;
    color: #1D66DD;
    border: none; }
