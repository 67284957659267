/*
* ink colors (with variations only for black and white)
*/
.lifeSpecification__wrapper {
  padding: 30px 0 0;
  padding-top: 0; }
  .lifeSpecification__wrapper--body {
    padding: 30px 15px 0;
    margin: 0 auto;
    max-width: 800px; }
    @media (max-width: 991.98px) {
      .lifeSpecification__wrapper--body {
        padding: 30px 0; } }
    .lifeSpecification__wrapper--body .item__row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px; }
    .lifeSpecification__wrapper--body .item__col {
      flex: 0 0 33%;
      max-width: 33%;
      padding-right: 15px;
      padding-left: 15px; }
    .lifeSpecification__wrapper--body .savings__selector {
      display: flex; }
      .lifeSpecification__wrapper--body .savings__selector .saving__item {
        margin-right: 8px;
        display: inline-flex; }
        .lifeSpecification__wrapper--body .savings__selector .saving__item input {
          align-self: center; }
          .lifeSpecification__wrapper--body .savings__selector .saving__item input::-webkit-inner-spin-button, .lifeSpecification__wrapper--body .savings__selector .saving__item input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0; }
        .lifeSpecification__wrapper--body .savings__selector .saving__item label {
          margin-left: 8px;
          margin-bottom: 0;
          color: #707070;
          cursor: pointer; }
          .lifeSpecification__wrapper--body .savings__selector .saving__item label:hover {
            color: #1d66dd; }
    .lifeSpecification__wrapper--body .saving__input--element {
      display: inline-flex;
      width: 100%; }
      .lifeSpecification__wrapper--body .saving__input--element .InputForm {
        width: 100%; }
      .lifeSpecification__wrapper--body .saving__input--element .Currency {
        display: flex; }
      .lifeSpecification__wrapper--body .saving__input--element input {
        width: 100%;
        height: 40px;
        border: 1px solid #c2c2c2;
        padding-left: calc(0.75 * 1rem); }
        .lifeSpecification__wrapper--body .saving__input--element input:focus {
          box-shadow: none;
          outline: none; }
        .lifeSpecification__wrapper--body .saving__input--element input::-webkit-inner-spin-button, .lifeSpecification__wrapper--body .saving__input--element input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0; }
  .lifeSpecification__wrapper .errorMessage {
    color: #e81a1a;
    font-size: 12px; }
  .lifeSpecification__wrapper--btnReset {
    border: 1px solid #dfdfdf !important; }
    .lifeSpecification__wrapper--btnReset:focus {
      color: #1d66dd;
      box-shadow: none !important; }
