/*
* ink colors (with variations only for black and white)
*/
.policyInfo__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .policyInfo__wrapper--head {
    display: flex;
    padding: calc(1.25 * 1rem) calc(calc(1.25 * 1rem) + calc(0.25 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
    .policyInfo__wrapper--head .box__left {
      width: 20%;
      display: flex;
      align-items: center; }
      @media (max-width: 991.98px) {
        .policyInfo__wrapper--head .box__left {
          width: 100%; } }
  .policyInfo__wrapper--body {
    padding: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem)); }
    .policyInfo__wrapper--body div {
      display: flex; }
      .policyInfo__wrapper--body div:not(:last-child) {
        margin-bottom: calc(1.25 * 1rem); }
      .policyInfo__wrapper--body div span {
        width: 15%; }
        @media (max-width: 991.98px) {
          .policyInfo__wrapper--body div span {
            width: 45%; } }
      .policyInfo__wrapper--body div span:last-child {
        width: auto; }
        @media (max-width: 991.98px) {
          .policyInfo__wrapper--body div span:last-child {
            width: 50%; } }
  .policyInfo__wrapper .button__outline {
    border: 1px solid #717171;
    background: #ffffff;
    color: #717171;
    width: 100%; }
    .policyInfo__wrapper .button__outline:disabled {
      color: #5C5C5C; }

.notification__modal p, .notification__modal li {
  color: #707070; }

.notification__modal--button {
  width: 100%; }
