/*
* ink colors (with variations only for black and white)
*/
.success__screen {
  padding: calc(2 * 1rem) 0; }
  .success__screen h3 {
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    text-align: center; }
  .success__screen .img__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(2 * 1rem) 0; }
  .success__screen .subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-align: center; }
  .success__screen .cta {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    background: #1D66DD;
    color: #ffffff;
    padding: calc(0.75 * 1rem);
    margin: calc(2 * 1rem) 0;
    display: flex;
    justify-content: center; }
