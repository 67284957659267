/*
* ink colors (with variations only for black and white)
*/
.feature-filter-root {
  display: flex;
  width: 248px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px; }
  .feature-filter-root .filter__header {
    padding-bottom: 0px; }
    .feature-filter-root .filter__header .filter-header-title {
      font-weight: 700;
      size: 16px;
      line-height: 20px;
      color: #222222; }
  .feature-filter-root .feature-input-group {
    color: #1D4476;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: 0.28px;
    display: flex;
    gap: 12px; }
    .feature-filter-root .feature-input-group input[type='checkbox'] {
      accent-color: #EA4B4B; }
  .feature-filter-root .feature-action-separator {
    border-top: 1px dashed #e2e8f0;
    height: 1px;
    width: 100%; }
  .feature-filter-root .feature-action-group {
    display: flex;
    width: 100%;
    gap: 16px; }
    .feature-filter-root .feature-action-group .feature-action-reset {
      flex-grow: 1;
      border-radius: 4px;
      border: 1px solid #EA4B4B;
      color: #EA4B4B;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      /* 142.857% */
      padding: 10px 12px;
      background-color: white; }
    .feature-filter-root .feature-action-group .feature-action-submit {
      border: 1px solid #EA4B4B;
      width: 168px;
      border-radius: 4px;
      color: white;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      /* 142.857% */
      padding: 10px 12px;
      background-color: #EA4B4B; }
