/*
* ink colors (with variations only for black and white)
*/
.application__card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.25 * 1rem);
  margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .application__card--body {
    padding: calc(calc(0.75 * 1rem) + calc(0.75 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 140px;
    width: 100%; }
    .application__card--body .body__row {
      width: 70%;
      display: flex;
      flex-wrap: wrap; }
      .application__card--body .body__row .info__product {
        width: 100%;
        order: 1;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500; }
        @media (min-width: 768px) {
          .application__card--body .body__row .info__product {
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            width: 55%; } }
      .application__card--body .body__row .info__date {
        order: 2;
        width: 50%;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400; }
        @media (min-width: 768px) {
          .application__card--body .body__row .info__date {
            order: 3; } }
      .application__card--body .body__row .info__value {
        order: 3;
        width: 50%;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500; }
        @media (min-width: 768px) {
          .application__card--body .body__row .info__value {
            width: 45%;
            order: 2;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400; } }
      .application__card--body .body__row.left {
        width: 30%;
        padding-right: calc(0.5 * 1rem); }
        .application__card--body .body__row.left .brand__logo {
          width: auto !important;
          height: 25px !important; }
        @media (min-width: 576px) {
          .application__card--body .body__row.left {
            width: 27.5%; }
            .application__card--body .body__row.left .brand__logo {
              height: 23px !important; } }
      @media (min-width: 576px) {
        .application__card--body .body__row {
          width: 72.5%; } }
  .application__card--footer .footer__item {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(1.25 * 1rem) + calc(0.25 * 1rem));
    width: 100%;
    display: flex;
    justify-content: center;
    color: #1D66DD;
    cursor: pointer;
    background-color: #f2f7ff; }
    .application__card--footer .footer__item:hover {
      background-color: #1D66DD;
      color: #ffffff;
      transition: 0.3s;
      border-radius: 0 0 calc(0.25 * 1rem) calc(0.25 * 1rem); }
    .application__card--footer .footer__item--button {
      margin-bottom: 0;
      color: inherit; }
    .application__card--footer .footer__item p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500; }
