/*
* ink colors (with variations only for black and white)
*/
.account__application {
  margin-right: auto;
  margin-left: auto;
  transition: all .5s ease .1s;
  padding-bottom: calc(calc(2 * 1rem) + calc(2 * 1rem)); }
  @media (max-width: 768px) {
    .account__application {
      padding: 10px; } }
  @media (min-width: 768px) {
    .account__application {
      max-width: 750px; } }
  @media (min-width: 992px) {
    .account__application {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .account__application {
      max-width: 1170px; } }
  .account__application--item {
    margin-bottom: calc(2 * 1rem); }
  .account__application--search {
    display: flex;
    flex-direction: row-reverse; }
    @media (max-width: 991.98px) {
      .account__application--search {
        margin-bottom: calc(1.25 * 1rem); } }
    @media (max-width: 991.98px) {
      .account__application--search .search__box {
        width: 100%; } }
  .account__application--loader {
    height: calc(100vh - 75px);
    display: flex;
    justify-content: center; }
  .account__application--title {
    margin-bottom: calc(0.75 * 1rem);
    display: flex;
    align-items: center; }
    .account__application--title p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700; }
    .account__application--title .collapse__button {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 9.5px 7.5px;
      border-radius: 100px;
      border: solid 1.5px #ffffff;
      background-color: #e2e8f0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
      transition: all 0.5s ease; }
      .account__application--title .collapse__button:hover {
        opacity: 1; }
      .account__application--title .collapse__button .icon {
        transition: all 0.5s ease;
        color: #1D66DD; }
      @media (min-width: 576px) {
        .account__application--title .collapse__button {
          margin-left: calc(0.75 * 1rem); } }
    @media (max-width: 575.98px) {
      .account__application--title .item {
        flex-grow: 1; }
        .account__application--title .item--grow {
          flex-grow: 100;
          margin: 0 calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
        .account__application--title .item .hr {
          width: 100%;
          height: 0.5px;
          border: 0;
          border-top: 1.5px solid #e2e8f0; } }
  .account__application--body {
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    grid-gap: calc(1.25 * 1rem);
    transition: all .5s ease .1s;
    margin-bottom: calc(calc(0.25 * 1rem) + calc(0.25 * 1rem)); }
    @media (max-width: 991.98px) {
      .account__application--body {
        grid-template-columns: 1fr;
        grid-gap: 0; } }
    .account__application--body.\--empty {
      display: flex;
      align-items: center;
      justify-content: center; }
  .account__application--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .account__application--empty {
        text-align: center; } }
    .account__application--empty .empty__text {
      color: #717171;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400; }
      .account__application--empty .empty__text.heading {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700; }
    .account__application--empty .empty__button {
      width: 261px;
      height: 40px; }
  @media (max-width: 575.98px) {
    .account__application__head {
      display: none; } }
  @media (min-width: 576px) {
    .account__application__head {
      max-height: 88px;
      width: 100%;
      background-color: #1D66DD;
      padding: calc(calc(0.75 * 1rem) + calc(0.75 * 1rem));
      margin-bottom: calc(calc(1.25 * 1rem) + calc(0.75 * 1rem)); }
      .account__application__head--item {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        transition: all .5s ease .1s; }
        .account__application__head--item p {
          margin: 0;
          color: #ffffff;
          font-family: 'Open Sans';
          font-size: 18px;
          line-height: 22px;
          font-weight: 500; } }
    @media (min-width: 576px) and (min-width: 768px) {
      .account__application__head--item {
        max-width: 750px; } }
    @media (min-width: 576px) and (min-width: 992px) {
      .account__application__head--item {
        max-width: 970px; } }
    @media (min-width: 576px) and (min-width: 1200px) {
      .account__application__head--item {
        max-width: 1170px; } }
