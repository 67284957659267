/*
* ink colors (with variations only for black and white)
*/
.ReviewCardWrapper {
  margin-bottom: calc(1.25 * 1rem); }
  .ReviewCardWrapper + .Wrapper {
    margin-top: 30px; }
  .ReviewCardWrapper .field__label {
    font-weight: bold;
    margin-bottom: calc(0.25 * 1rem); }

.clickable {
  cursor: pointer; }

.ReviewContainer {
  padding-bottom: calc(3.25 * 1rem);
  border-radius: calc(0.25 * 1rem); }
  .ReviewContainerWithName {
    padding-top: calc(1.25 * 1rem);
    margin-bottom: calc(3.25 * 1rem);
    border-radius: calc(0.25 * 1rem);
    box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22); }
  .ReviewContainerPlain {
    box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
    margin-bottom: calc(3.25 * 1rem); }

.ReviewHeader {
  position: relative;
  padding: 15px calc(1.25 * 1rem) !important;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  border-right: none !important;
  box-shadow: none !important; }
  .ReviewHeaderOpen {
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 30px; }

.Collapse {
  background: #ffffff;
  border: none;
  font-size: calc(1.25 * 1rem);
  color: #1D66DD;
  position: absolute;
  top: 15px;
  right: 1rem;
  cursor: pointer; }

.Collapsed {
  margin: 0 calc(-1rem - 20px) calc(-1rem - 20px);
  height: 0;
  padding: 0;
  overflow: hidden; }
  .CollapsedOpen {
    padding: 0 20px 20px 20px;
    display: block;
    height: auto;
    margin: 30px 0;
    overflow: unset; }
  .Collapsed strong {
    font-weight: bold;
    color: #333333; }

.AddButton {
  background: #ffffff;
  border: none;
  font-size: 16px;
  color: #1d66dd;
  cursor: pointer; }

.IconBtn {
  cursor: pointer;
  color: #b6b6b6; }

.SaveBtn {
  font-size: 12px;
  color: #1D66DD; }

.FieldMargin {
  margin-bottom: 25px; }

.SpanMargin {
  margin-right: calc(0.75 * 1rem);
  margin-bottom: calc(0.75 * 1rem); }
  .SpanMargin--error {
    color: #E81A1A; }

.SpanRight {
  margin-right: calc(0.75 * 1rem); }
  .SpanRight.disabled {
    pointer-events: none;
    color: #717171; }

.Box {
  width: 100px;
  height: 100px;
  border: 1px dashed #dfdfdf;
  border-radius: calc(0.25 * 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfdfdf; }

.ImageBox {
  width: 100px;
  height: 100px;
  background-size: cover !important;
  background-repeat: round !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ImageBox .btn-close {
    width: calc(1.25 * 1rem);
    background: #333333;
    opacity: 0.5;
    border-radius: calc(1.25 * 1rem);
    display: flex;
    justify-content: center; }
    .ImageBox .btn-close i {
      color: #ffffff; }

.ErrorMessage {
  color: #e81a1a; }
