/*
* ink colors (with variations only for black and white)
*/
.team__view--wrapper {
  padding: 0 10vw;
  display: grid;
  grid-template-columns: 0.45fr 1fr; }
  @media (max-width: 991.98px) {
    .team__view--wrapper {
      grid-template-columns: 1fr;
      padding: 0 3vw; } }
  .team__view--wrapper .left__panel--team .layout__wrapper {
    position: sticky;
    top: 80px; }
  .team__view--wrapper .left__panel--team .info__container {
    display: flex;
    flex-direction: column; }
    .team__view--wrapper .left__panel--team .info__container .text__view {
      display: flex;
      align-items: center;
      padding: calc(1.25 * 1rem) calc(0.75 * 1rem);
      border-radius: calc(0.25 * 1rem); }
      .team__view--wrapper .left__panel--team .info__container .text__view:first-child {
        margin: calc(0.75 * 1rem) 0; }
      .team__view--wrapper .left__panel--team .info__container .text__view span {
        width: 50%; }
    .team__view--wrapper .left__panel--team .info__container .mitra__details {
      background: #164da6; }
      .team__view--wrapper .left__panel--team .info__container .mitra__details span {
        color: #ffffff; }
    .team__view--wrapper .left__panel--team .info__container .commission__details {
      background: #f2f7ff; }
      .team__view--wrapper .left__panel--team .info__container .commission__details span {
        color: #77a3eb; }
  .team__view--wrapper .right__panel--team {
    margin-left: 30px; }
    @media (max-width: 991.98px) {
      .team__view--wrapper .right__panel--team {
        margin-left: 0; } }
    .team__view--wrapper .right__panel--team .search__group {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: calc(1.25 * 1rem) 0; }
      @media (max-width: 991.98px) {
        .team__view--wrapper .right__panel--team .search__group .search__box {
          flex-grow: 1; } }
      .team__view--wrapper .right__panel--team .search__group .search {
        height: 40px;
        padding: 0; }
      .team__view--wrapper .right__panel--team .search__group button {
        height: 40px;
        margin-left: calc(0.75 * 1rem); }
        @media (max-width: 991.98px) {
          .team__view--wrapper .right__panel--team .search__group button {
            white-space: nowrap; } }
      .team__view--wrapper .right__panel--team .search__group .copy__view {
        position: absolute;
        bottom: -125px;
        padding: calc(1.25 * 1rem);
        z-index: 2;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        background: #ffffff;
        border: 1px solid #c2c2c2;
        border-radius: 4px;
        width: 340px;
        height: 120px; }
        @media (max-width: 991.98px) {
          .team__view--wrapper .right__panel--team .search__group .copy__view {
            width: 90vw; } }
        .team__view--wrapper .right__panel--team .search__group .copy__view .heading-sm-b {
          margin-bottom: calc(0.75 * 1rem); }
        .team__view--wrapper .right__panel--team .search__group .copy__view .input__group {
          display: flex;
          justify-content: space-evenly;
          border: 1px solid #c2c2c2;
          border-radius: 4px;
          height: 40px; }
          .team__view--wrapper .right__panel--team .search__group .copy__view .input__group div:first-child {
            padding: calc(0.5 * 1rem);
            height: 100%;
            max-width: 250px;
            min-width: 240px;
            overflow: hidden;
            color: #c2c2c2;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid #c2c2c2; }
          .team__view--wrapper .right__panel--team .search__group .copy__view .input__group .icon__wrapper {
            height: 40px;
            padding: calc(0.5 * 1rem);
            cursor: pointer; }
    .team__view--wrapper .right__panel--team .profile__card {
      display: flex;
      align-items: center;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      padding: 16px;
      border-radius: calc(0.5 * 1rem);
      z-index: 1;
      position: relative;
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .team__view--wrapper .right__panel--team .profile__card img {
          align-self: flex-start; } }
      .team__view--wrapper .right__panel--team .profile__card:not(:last-child) {
        margin-bottom: calc(1.25 * 1rem); }
      .team__view--wrapper .right__panel--team .profile__card .profile__name {
        margin-left: calc(2 * 1rem); }
        @media (max-width: 991.98px) {
          .team__view--wrapper .right__panel--team .profile__card .profile__name {
            margin-bottom: calc(1.25 * 1rem); }
            .team__view--wrapper .right__panel--team .profile__card .profile__name h4 {
              margin-top: 0; } }
        .team__view--wrapper .right__panel--team .profile__card .profile__name h4 {
          text-transform: capitalize; }
      .team__view--wrapper .right__panel--team .profile__card .profile__activity {
        margin-left: auto;
        align-self: flex-start;
        color: #c2c2c2; }
        @media (max-width: 991.98px) {
          .team__view--wrapper .right__panel--team .profile__card .profile__activity {
            position: absolute;
            bottom: 0;
            margin-left: calc(48px + calc(2 * 1rem)); } }
