/*
* ink colors (with variations only for black and white)
*/
.agentLevel__card {
  height: 100%; }
  .agentLevel__card--header {
    display: flex;
    position: relative; }
  .agentLevel__card .right__col {
    position: relative;
    width: 100%;
    padding-right: calc(calc(0.25 * 1rem) + calc(0.75 * 1rem));
    padding-left: calc(calc(0.25 * 1rem) + calc(0.75 * 1rem)); }
  .agentLevel__card .left__col--image {
    height: 30px !important;
    width: 30px !important; }
  .agentLevel__card .right__col {
    padding-left: 0;
    margin-left: 30px;
    display: flex; }
    .agentLevel__card .right__col--title {
      display: flex;
      align-items: flex-end; }
      .agentLevel__card .right__col--title .title {
        height: max-content; }
    .agentLevel__card .right__col i.text-icon-left {
      cursor: pointer; }
    .agentLevel__card .right__col i.text-icon-right {
      position: absolute;
      font-size: calc(1.25 * 1rem);
      top: 0;
      right: 0; }
      @media (min-width: 768px) {
        .agentLevel__card .right__col i.text-icon-right {
          display: none; } }
