/*
* ink colors (with variations only for black and white)
*/
.agentDashboard__wrapper {
  background-color: #f2f7ff;
  position: relative;
  padding: 0;
  min-height: 100vh; }
  @media (max-width: 991.98px) {
    .agentDashboard__wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0; } }
  .agentDashboard__wrapper--item {
    padding: calc(1.25 * 1rem); }

.modal__mobile {
  position: absolute;
  background-color: #ffffff; }
