/*
* ink colors (with variations only for black and white)
*/
.transactionCard__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.5 * 1rem); }
  .transactionCard__wrapper--header {
    padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
  .transactionCard__wrapper--body {
    padding: 0 calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    max-height: 300px;
    overflow: scroll; }
    .transactionCard__wrapper--body .item__tx {
      display: flex;
      padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0; }
      .transactionCard__wrapper--body .item__tx:not(:last-child) {
        border-bottom: 1px solid #dfdfdf; }
      .transactionCard__wrapper--body .item__tx--left {
        display: flex; }
      .transactionCard__wrapper--body .item__tx--right {
        margin-left: auto; }
