/*
* ink colors (with variations only for black and white)
*/
.floating__wrapper--button {
  position: fixed;
  top: 50%;
  right: 0; }
  @media (max-width: 991.98px) {
    .floating__wrapper--button {
      width: 50px; } }
  .floating__wrapper--button button {
    width: 230px;
    height: 60px;
    background-color: #1ec494;
    border-color: #1ec494;
    border-top-left-radius: calc(1.25 * 1rem);
    border-bottom-left-radius: calc(1.25 * 1rem); }
    .floating__wrapper--button button:hover {
      background-color: #1ec494;
      border-color: #1ec494;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
    .floating__wrapper--button button:focus {
      background-color: #1ec494;
      border-color: #1ec494;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
    @media (max-width: 991.98px) {
      .floating__wrapper--button button {
        max-width: 50px !important;
        min-width: auto !important; } }
  .floating__wrapper--button .icon__wrapper--call {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 50px;
    border-right: 1px solid #fff;
    margin-right: calc(0.75 * 1rem); }
    .floating__wrapper--button .icon__wrapper--call i {
      font-size: 28px; }
    .floating__wrapper--button .icon__wrapper--call:after {
      height: 100%;
      width: 2px; }
  @media (max-width: 991.98px) {
    .floating__wrapper--button span {
      display: none; }
    .floating__wrapper--button button {
      width: 50px;
      height: 40px;
      padding-right: 0;
      border-top-left-radius: calc(0.75 * 1rem);
      border-bottom-left-radius: calc(0.75 * 1rem); }
    .floating__wrapper--button .icon__wrapper--call {
      border-right: none;
      width: 100%;
      margin-right: 0; }
      .floating__wrapper--button .icon__wrapper--call i {
        font-size: 20px; } }
