.form-width {
  width: 600px !important; }
  @media (max-width: 767.98px) {
    .form-width {
      width: 100% !important; } }

.SpecificationForm {
  padding: 30px 0 0;
  padding-top: 0; }
  .SpecificationForm--body {
    padding: 30px 15px 0;
    margin: 0 auto;
    max-width: 800px; }
    .SpecificationForm--body .DatePicker {
      z-index: 0 !important;
      width: 100%; }
      .SpecificationForm--body .DatePicker input {
        width: 100%; }
  .SpecificationForm .dropdown-custom-auto .css-1li0unn-control::before, .SpecificationForm .dropdown-custom-auto .css-1jgn1w8-control::before {
    font-family: 'Ionicons';
    content: '\F4A5';
    margin-right: 10px;
    margin-left: 10px;
    color: #1d66dd;
    font-size: large; }
  .SpecificationForm .box-person, .SpecificationForm .box-person-add {
    border-radius: 4px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.3);
    padding: 3px 20px;
    cursor: pointer; }
  .SpecificationForm .box-person-add {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: #1d66dd; }
  @media (min-width: 576px) {
    .SpecificationForm .tabs-wrapper {
      margin-top: 20px; } }
  .SpecificationForm .tabs span {
    font-size: 16px; }
  .SpecificationForm .tabs hr {
    margin: 5px 0 0 0;
    background: #1d66dd; }
  .SpecificationForm .tabs div {
    cursor: pointer; }
  .SpecificationForm .dropdown-custom-person .DropdownForm {
    min-width: 110px; }
  .SpecificationForm .dropdown-custom-person .ReactSelect {
    width: 100%; }
  .SpecificationForm .dropdown-custom-person .css-1jgn1w8-control {
    border-style: none; }
  .SpecificationForm .dropdown-custom-person .css-1li0unn-control {
    border-style: none; }
  .SpecificationForm .dropdown-custom-person .css-26l3qy-menu {
    min-width: 110px; }
  .SpecificationForm .border-active {
    border-bottom: 1px solid #1d66dd; }
  .SpecificationForm .margin-b20 {
    margin-bottom: 20px; }
  .SpecificationForm .button-bottom button {
    width: 270px; }
    @media (max-width: 991.98px) {
      .SpecificationForm .button-bottom button {
        width: 100%; } }
  .SpecificationForm .errorMessage {
    color: #e81a1a;
    font-size: 12px; }
