/*
* ink colors (with variations only for black and white)
*/
.payment__term--checkout {
  position: fixed;
  top: 0; }
  .payment__term--checkout ul {
    margin-top: calc(0.75 * 1rem) !important; }
    .payment__term--checkout ul li {
      width: 100%;
      margin-right: 0 !important;
      padding: calc(0.5 * 1rem); }
      .payment__term--checkout ul li:not(:only-child) {
        border-bottom: 1px solid #c2c2c2; }
      .payment__term--checkout ul li label {
        margin-bottom: 0; }
  .payment__term--checkout .InputWrapper {
    border: none !important;
    background: none !important;
    padding-left: 0; }
