/*
* ink colors (with variations only for black and white)
*/
.auto__moto--type {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: calc(0.75 * 1rem);
  margin-top: calc(1.25 * 1rem); }

.minified__view--auto {
  padding: calc(0.5 * 1rem); }
