/*
* ink colors (with variations only for black and white)
*/
.voucher__view--group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: calc(0.25 * 1rem); }
  .voucher__view--group .group__input {
    padding: calc(0.5 * 1rem);
    height: 40px;
    border-radius: 6px 0 0 6px;
    border: 1px solid #ced4da;
    width: 75%; }
  .voucher__view--group .group__button {
    width: 25%; }
    .voucher__view--group .group__button button {
      min-width: inherit;
      height: 40px;
      border-radius: 0 6px 6px 0; }
