/*
* ink colors (with variations only for black and white)
*/
.user__form--psf {
  padding: calc(0.75 * 1rem);
  padding-top: calc(0.25 * 1rem); }
  @media (max-width: 991.98px) {
    .user__form--psf {
      margin-top: 150px; } }
  .user__form--psf .userInformation__wrapper--group {
    margin-bottom: 0 !important; }
  .user__form--psf .userInformation__wrapper--header {
    display: flex;
    margin-top: calc(0.5 * 1rem); }
    .user__form--psf .userInformation__wrapper--header .credit__card--question {
      margin-top: 0; }
    .user__form--psf .userInformation__wrapper--header .header__left {
      width: 100%; }
    .user__form--psf .userInformation__wrapper--header .header__right {
      width: 70%; }
      .user__form--psf .userInformation__wrapper--header .header__right label {
        color: #717171; }
  .user__form--psf .userInformation__wrapper--col:not(:first-child):not(:last-child) {
    margin-top: calc(1.25 * 1rem); }
  .user__form--psf .userInformation__wrapper--col .psf__form--label {
    color: #717171;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; }
  .user__form--psf .userInformation__wrapper--col button {
    width: 100%; }
  .user__form--psf .credit__card--question {
    margin-top: calc(1.25 * 1rem); }
    .user__form--psf .credit__card--question span {
      color: #717171; }
    .user__form--psf .credit__card--question .credit__card--answer {
      display: flex;
      align-items: center;
      margin-top: calc(0.75 * 1rem); }
      .user__form--psf .credit__card--question .credit__card--answer span:first-child {
        margin-right: calc(0.75 * 1rem); }
      .user__form--psf .credit__card--question .credit__card--answer span {
        cursor: pointer;
        padding: calc(0.25 * 1rem) calc(0.5 * 1rem);
        border-radius: 4px; }
        .user__form--psf .credit__card--question .credit__card--answer span:hover {
          background: #f2f7ff; }
        .user__form--psf .credit__card--question .credit__card--answer span:focus {
          background: #f2f7ff;
          outline: none; }
        .user__form--psf .credit__card--question .credit__card--answer span label {
          margin-left: calc(0.5 * 1rem);
          margin-bottom: 0; }

.google__review {
  padding: calc(0.75 * 1rem) 24px;
  margin-top: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
  color: #717171; }
  .google__review--wrapper {
    display: flex;
    align-items: center; }
    .google__review--wrapper .wrapper__info {
      margin-left: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
      color: #717171; }
      .google__review--wrapper .wrapper__info p {
        color: #717171;
        margin-bottom: 2px; }
      .google__review--wrapper .wrapper__info--rating i {
        color: #faaf40;
        margin-right: calc(0.25 * 1rem);
        font-size: 16px; }
