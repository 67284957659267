/*
* ink colors (with variations only for black and white)
*/
.contact__us {
  background: linear-gradient(180deg, #1D66DD 50%, #ffffff 50%);
  padding: calc(0.75 * 1rem);
  position: relative; }
  .contact__us .success__box {
    width: 350px;
    margin: calc(2 * 1rem) auto;
    background: #ffffff;
    border-radius: calc(0.5 * 1rem);
    padding: calc(1.25 * 1rem);
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .contact__us .success__box .success__icon--wrapper {
      height: 22px;
      width: 22px; }
      .contact__us .success__box .success__icon--wrapper i {
        font-size: 22px;
        line-height: 24px;
        font-weight: 700;
        background: #1EC494;
        border-radius: 50%;
        padding: calc(0.5 * 1rem);
        color: #ffffff;
        margin: calc(0.75 * 1rem) 0; }
    .contact__us .success__box div {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      margin: calc(0.75 * 1rem) 0; }
    .contact__us .success__box button {
      background: #1D66DD;
      color: #ffffff;
      font-weight: bold; }
  .contact__us .callback__box {
    width: 350px;
    margin: calc(2 * 1rem) auto;
    background: #ffffff;
    border-radius: calc(0.5 * 1rem);
    padding: calc(0.5 * 1rem);
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.24); }
    .contact__us .callback__box--question {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      line-height: 1.5;
      margin: calc(0.75 * 1rem) 0; }
    .contact__us .callback__box .date__time--selector {
      border-radius: calc(1.25 * 1rem);
      background: #ffffff;
      border: 1px solid #c2c2c2;
      padding: calc(0.5 * 1rem);
      cursor: pointer; }
      .contact__us .callback__box .date__time--selector:hover {
        background-color: #e4eefd;
        box-shadow: 0 2px 4px 0 "null"; }
    .contact__us .callback__box .btnActive {
      background: #e4eefd;
      color: #1D66DD;
      border: 1px solid #1D66DD; }
    .contact__us .callback__box .btnDisabled {
      background: #c2c2c2; }
    .contact__us .callback__box .dates__wrapper {
      display: grid;
      grid-gap: calc(0.5 * 1rem);
      grid-template-columns: 1fr 1fr 1fr;
      padding: calc(0.75 * 1rem) 0; }
    .contact__us .callback__box .hours__wrapper {
      display: grid;
      grid-gap: calc(0.5 * 1rem);
      grid-template-columns: 1fr 1fr;
      padding: calc(0.75 * 1rem) 0;
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2; }
    .contact__us .callback__box .cta {
      border-radius: calc(0.25 * 1rem);
      background: #1D66DD;
      color: #ffffff; }
