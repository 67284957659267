/*
* ink colors (with variations only for black and white)
*/
.filter-group-root {
  display: flex;
  gap: 16px; }

.order-filter-root {
  display: flex;
  gap: 16px;
  flex-direction: column;
  text-wrap: nowrap;
  width: 258px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px; }
  .order-filter-root .filter__header {
    padding-bottom: 0px; }
    .order-filter-root .filter__header .filter-header-title {
      font-weight: 700;
      size: 16px;
      line-height: 20px;
      color: #222222; }
  .order-filter-root .sort-modal-wrapper {
    padding: 0px; }
    .order-filter-root .sort-modal-wrapper .sort-modal-radio {
      gap: 10px;
      display: flex;
      flex-direction: row; }
      .order-filter-root .sort-modal-wrapper .sort-modal-radio input[type='radio'] {
        accent-color: #EA4B4B; }
      .order-filter-root .sort-modal-wrapper .sort-modal-radio > div {
        cursor: pointer; }
        .order-filter-root .sort-modal-wrapper .sort-modal-radio > div.active {
          color: #EA4B4B;
          font-weight: 600; }

.feature-action-group {
  display: flex;
  width: 100%;
  background-color: white;
  gap: 16px; }
  @media (max-width: 767.98px) {
    .feature-action-group {
      padding: 15px 24px 24px; } }
  .feature-action-group .feature-action-reset {
    flex-grow: 1;
    border-radius: 4px;
    border: 1px solid #EA4B4B;
    color: #EA4B4B;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    padding: 10px 12px;
    background-color: white; }
  .feature-action-group .feature-action-submit {
    border: 1px solid #EA4B4B;
    width: 168px;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    padding: 10px 12px;
    background-color: #EA4B4B; }
