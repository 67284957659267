/*
* ink colors (with variations only for black and white)
*/
.payment-link__wrapper {
  margin: calc(1.25 * 1rem) auto;
  transition: all .5s ease .1s;
  max-width: 30vw; }
  @media (max-width: 991.98px) {
    .payment-link__wrapper {
      max-width: 100vw;
      margin: 0; } }
  .payment-link__wrapper .title-page {
    font-size: 22px;
    font-weight: bold;
    color: #333333; }
  .payment-link__wrapper--container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0; }
    .payment-link__wrapper--container .card {
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15); }
      @media (max-width: 991.98px) {
        .payment-link__wrapper--container .card {
          border: none;
          border-bottom: 1px solid #c2c2c2;
          border-radius: 0; } }
    .payment-link__wrapper--container .payment__link--member {
      padding: 16px;
      padding-bottom: 0; }
    @media (max-width: 991.98px) {
      .payment-link__wrapper--container .mt-2 {
        margin-top: 0 !important; } }
    .payment-link__wrapper--container .container__left--header {
      padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
      display: flex;
      flex-direction: row;
      align-content: center; }
    .payment-link__wrapper--container .container__left--body .body__box {
      padding: calc(0.5 * 1rem);
      position: relative; }
      .payment-link__wrapper--container .container__left--body .body__box--item {
        height: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        border-radius: calc(0.25 * 1rem);
        padding: calc(0.5 * 1rem); }
        .payment-link__wrapper--container .container__left--body .body__box--item .item__right {
          position: absolute;
          right: calc(1.25 * 1rem);
          cursor: pointer; }
        .payment-link__wrapper--container .container__left--body .body__box--item .item__value {
          text-transform: capitalize; }
        .payment-link__wrapper--container .container__left--body .body__box--item.error {
          border: 1px solid #E81A1A; }
      .payment-link__wrapper--container .container__left--body .body__box--disable {
        background-color: #f7f7f7; }
        .payment-link__wrapper--container .container__left--body .body__box--disable p {
          color: #c2c2c2; }
    .payment-link__wrapper--container.with__border {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
