/*
* ink colors (with variations only for black and white)
*/
.hero-wrapper {
  display: flex;
  justify-content: center;
  padding: 16px 4%; }
  @media (min-width: 576px) {
    .hero-wrapper {
      padding: 32px 0px; } }
  .hero-wrapper .hero-container {
    width: 100%;
    border-radius: 8px;
    background-image: linear-gradient(to left, #4380E3, #1855B8, #134493); }
    @media (min-width: 576px) {
      .hero-wrapper .hero-container {
        width: 1120px;
        border-radius: 16px;
        margin: 0 auto; } }
    .hero-wrapper .hero-container .hero-first-section {
      position: relative;
      padding: 8px 0px 0px 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      @media (min-width: 576px) {
        .hero-wrapper .hero-container .hero-first-section {
          padding: 16px 72px 0px 72px; } }
      .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%; }
        @media (min-width: 576px) {
          .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper {
            width: 621px; } }
        .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper .hero-title {
          font-size: 18px;
          font-weight: 700;
          color: white;
          line-height: 24px;
          margin: 0; }
          @media (min-width: 576px) {
            .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper .hero-title {
              font-size: 48px;
              line-height: 56px; } }
        .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper .hero-subtitle {
          font-size: 12px;
          color: white;
          margin: 4px 0 0 0; }
          @media (min-width: 576px) {
            .hero-wrapper .hero-container .hero-first-section .hero-title-wrapper .hero-subtitle {
              font-size: 20px; } }
      .hero-wrapper .hero-container .hero-first-section .hero-mascot {
        height: 140px; }
        @media (min-width: 576px) {
          .hero-wrapper .hero-container .hero-first-section .hero-mascot {
            height: 224px; } }
    .hero-wrapper .hero-container .hero-second-section {
      position: relative;
      border-radius: 8px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (min-width: 576px) {
        .hero-wrapper .hero-container .hero-second-section {
          border-radius: 16px; } }
      .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0px 12px 0px 12px; }
        @media (min-width: 576px) {
          .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper {
            padding: 0; } }
        .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper .benefit-list {
          padding: 4px 8px;
          background-color: #E4EDFB;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 4px;
          border-radius: 0px 0px 8px 8px; }
          .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper .benefit-list .benefit-item {
            gap: 2px;
            display: flex;
            align-items: center; }
            .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper .benefit-list .benefit-item .benefit-item-label {
              font-size: 12px; }
            .hero-wrapper .hero-container .hero-second-section .benefit-list-wrapper .benefit-list .benefit-item .benefit-item-icon {
              height: 12px;
              width: 12px; }
      .hero-wrapper .hero-container .hero-second-section .insurance-types {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px 16px 16px 16px;
        gap: 18px; }
        @media (min-width: 576px) {
          .hero-wrapper .hero-container .hero-second-section .insurance-types {
            padding: 16px 24px 24px 24px;
            gap: 0; } }
        .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px; }
          @media (min-width: 576px) {
            .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item {
              flex-direction: row;
              justify-content: center;
              padding: 8px 4px;
              width: 24%;
              gap: 8px;
              border-radius: 8px;
              border: 0.5px solid #D6D6D6;
              transition-duration: 300ms;
              cursor: pointer; } }
          .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item .insurance-item-icon {
            height: 64px;
            width: 64px;
            transition-duration: 300ms;
            border-radius: 8px;
            border: 0.5px solid #D6D6D6;
            padding: 8px; }
            @media (min-width: 576px) {
              .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item .insurance-item-icon {
                height: 56px;
                width: 56px;
                border-radius: 0;
                border: none;
                padding: 0; } }
          .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item .insurance-item-label {
            font-size: 12px;
            color: black;
            text-align: center;
            width: 64px; }
            @media (min-width: 576px) {
              .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item .insurance-item-label {
                font-size: 16px;
                text-align: left;
                width: fit-content; } }
        @media (min-width: 576px) {
          .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item:hover {
            background-color: #F6F9FE;
            box-shadow: 0 2px 20px #00000029;
            padding: 0; } }
        .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item:hover .insurance-item-icon {
          padding: 0;
          background-color: #F6F9FE; }
          @media (min-width: 576px) {
            .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item:hover .insurance-item-icon {
              height: 68px;
              width: 68px;
              background-color: none; } }
        .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item:hover .insurance-item-label {
          font-weight: bold; }
          @media (min-width: 576px) {
            .hero-wrapper .hero-container .hero-second-section .insurance-types .insurance-item:hover .insurance-item-label {
              font-weight: none; } }
