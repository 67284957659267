/*
* ink colors (with variations only for black and white)
*/
.next-button {
  border-radius: 8px;
  border: none;
  background-color: #1D66DD;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  outline: none; }
  .next-button.next-button-disabled {
    opacity: 0.5; }
  .next-button .label {
    color: white;
    font-size: 14px;
    font-weight: 600; }
  .next-button .icon {
    height: 14px;
    width: 14px;
    margin-left: 4px; }
