/*
* ink colors (with variations only for black and white)
*/
@media (min-width: 768px) {
  .kyc__verification {
    width: 400px;
    margin: auto;
    padding-bottom: calc(2 * 1rem); } }

.kyc__verification .error {
  padding-top: calc(0.5 * 1rem);
  color: #E81A1A; }
