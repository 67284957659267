/*
* ink colors (with variations only for black and white)
*/
@media (max-width: 991.98px) {
  .auto-specifications__form {
    padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0; } }

.auto-specifications__form--field {
  margin-bottom: 16px; }
  .auto-specifications__form--field .field__label {
    display: block;
    margin-bottom: calc(0.5 * 1rem);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700; }
