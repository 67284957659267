/*
* ink colors (with variations only for black and white)
*/
.orders__wrapper {
  margin-top: calc(2 * 1rem);
  margin-right: auto;
  margin-left: auto;
  transition: all .5s ease .1s; }
  @media (max-width: 768px) {
    .orders__wrapper {
      margin-top: calc(0.75 * 1rem);
      padding: calc(0.75 * 1rem); } }
  @media (min-width: 768px) {
    .orders__wrapper {
      max-width: 750px; } }
  @media (min-width: 992px) {
    .orders__wrapper {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .orders__wrapper {
      max-width: 1200px; } }
  .orders__wrapper h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: calc(1.25 * 1rem); }
    @media (max-width: 991.98px) {
      .orders__wrapper h4 {
        display: none; } }
  .orders__wrapper--search {
    width: 30%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 15px; }
    @media (max-width: 991.98px) {
      .orders__wrapper--search {
        width: 95vw; } }
    .orders__wrapper--search img {
      height: 18px;
      width: 18px;
      align-self: center;
      position: absolute;
      left: 15px; }
    .orders__wrapper--search input {
      width: 100%;
      height: 40px;
      border-radius: calc(0.25 * 1rem);
      border: solid 1px #d6d6d6;
      padding: calc(1.25 * 1rem);
      padding-left: calc(calc(2 * 1rem) + calc(0.5 * 1rem)); }
  .orders__wrapper .order__list--wrapper .order__card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    height: 80px;
    transition: height 0.5s ease-in-out; }
    .orders__wrapper .order__list--wrapper .order__card:not(:last-child) {
      margin-bottom: calc(1.25 * 1rem); }
    .orders__wrapper .order__list--wrapper .order__card .order__info {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .orders__wrapper .order__list--wrapper .order__card .order__info .order__info--group {
        width: 50%;
        margin-bottom: calc(0.75 * 1rem); }
        .orders__wrapper .order__list--wrapper .order__card .order__info .order__info--group p {
          margin-bottom: calc(0.5 * 1rem);
          text-transform: capitalize; }
        .orders__wrapper .order__list--wrapper .order__card .order__info .order__info--group .order__title {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6); }
        .orders__wrapper .order__list--wrapper .order__card .order__info .order__info--group .order__value {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: #333333;
          white-space: pre-line; }
        .orders__wrapper .order__list--wrapper .order__card .order__info .order__info--group .brand__color--modifier {
          color: #1D66DD; }
    .orders__wrapper .order__list--wrapper .order__card--header, .orders__wrapper .order__list--wrapper .order__card--body, .orders__wrapper .order__list--wrapper .order__card--footer {
      padding: calc(0.75 * 1rem); }
    .orders__wrapper .order__list--wrapper .order__card--body, .orders__wrapper .order__list--wrapper .order__card--footer {
      visibility: hidden; }
    .orders__wrapper .order__list--wrapper .order__card--footer {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 991.98px) {
        .orders__wrapper .order__list--wrapper .order__card--footer button {
          min-width: 150px !important; } }
  .orders__wrapper .order__list--wrapper .active {
    height: auto;
    transition: height 0.5s ease-in-out; }
    .orders__wrapper .order__list--wrapper .active .order__card--header {
      background-color: rgba(30, 196, 148, 0.06); }
    .orders__wrapper .order__list--wrapper .active .order__card--body, .orders__wrapper .order__list--wrapper .active .order__card--footer {
      visibility: visible; }
