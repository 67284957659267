/*
* ink colors (with variations only for black and white)
*/
.content__list {
  display: grid;
  grid-gap: calc(0.75 * 1rem);
  grid-template-columns: repeat(6, 1fr);
  padding-left: 0;
  margin-bottom: calc(1.25 * 1rem); }
  @media (max-width: 991.98px) {
    .content__list {
      grid-template-columns: repeat(3, 1fr); } }
  .content__list--item {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: calc(0.5 * 1rem);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.75 * 1rem);
    cursor: pointer;
    background-color: #ffffff;
    margin-bottom: calc(0.5 * 1rem); }
    .content__list--item:nth-child(odd) {
      margin-right: calc(0.75 * 1rem); }
    .content__list--item:nth-child(even) {
      margin-right: calc(0.75 * 1rem); }
    .content__list--item:hover ~ .item__title {
      text-decoration: underline;
      color: #1D66DD; }
    .content__list--item .item__title {
      text-align: center;
      color: #717171;
      margin-bottom: 0;
      line-height: 12px;
      cursor: pointer; }
      .content__list--item .item__title:hover {
        text-decoration: underline;
        color: #1D66DD; }
    .content__list--item.disabled {
      pointer-events: none;
      opacity: 0.5; }
