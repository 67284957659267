/*
* ink colors (with variations only for black and white)
*/
.google__map--wrapper {
  position: relative;
  height: calc(100vh - 65px);
  width: 100%; }
  .google__map--wrapper .map__header {
    position: absolute;
    top: 20px;
    left: 8px;
    width: 95%; }
    @media (min-width: 768px) {
      .google__map--wrapper .map__header {
        width: 50%;
        left: 27%; } }
    .google__map--wrapper .map__header .icon__wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 22px;
      background-color: #ffffff;
      border-radius: 50%; }
    .google__map--wrapper .map__header .search__container {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      padding: calc(0.75 * 1rem);
      margin-top: calc(0.75 * 1rem);
      min-height: 100px;
      background-color: #ffffff;
      width: 100%; }
      .google__map--wrapper .map__header .search__container .search-input {
        padding-left: calc(0.5 * 1rem) !important; }
      .google__map--wrapper .map__header .search__container .search__info {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: calc(0.75 * 1rem); }
        .google__map--wrapper .map__header .search__container .search__info span {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400; }
        .google__map--wrapper .map__header .search__container .search__info i {
          color: #1D66DD; }
      .google__map--wrapper .map__header .search__container .address__container {
        margin-top: calc(0.75 * 1rem); }
        .google__map--wrapper .map__header .search__container .address__container .address__list {
          list-style: none;
          padding-left: 0; }
          .google__map--wrapper .map__header .search__container .address__container .address__list--item {
            margin-bottom: calc(0.75 * 1rem);
            padding: calc(0.5 * 1rem) 0 calc(0.5 * 1rem) calc(0.5 * 1rem);
            white-space: pre-line;
            display: flex;
            justify-content: space-between; }
            .google__map--wrapper .map__header .search__container .address__container .address__list--item:not(:last-child) {
              border-bottom: 0.5px solid #ebebeb; }
            .google__map--wrapper .map__header .search__container .address__container .address__list--item .full__address {
              margin-left: calc(0.75 * 1rem);
              font-size: 12px;
              line-height: 16px;
              font-weight: 400; }
              .google__map--wrapper .map__header .search__container .address__container .address__list--item .full__address h5 {
                margin: 0;
                margin-bottom: calc(0.5 * 1rem);
                font-size: 12px;
                line-height: 16px;
                font-weight: 700; }
            .google__map--wrapper .map__header .search__container .address__container .address__list--item i {
              font-size: 18px;
              align-self: flex-start; }
  .google__map--wrapper .selected-location__container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 75px; }
    .google__map--wrapper .selected-location__container .icon__wrapper {
      margin: calc(0.75 * 1rem); }
    .google__map--wrapper .selected-location__container p {
      margin: 0;
      color: #717171;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400; }
  .google__map--wrapper .selected-location-submit__container {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 50px);
    padding: calc(0.75 * 1rem); }
  .google__map--wrapper .gm-fullscreen-control {
    display: none; }
