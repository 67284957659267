.iconSpritesBantuan {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }
  .iconSpritesBantuan::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url("/static/images/icon-sprites-bantuan.webp");
    background-repeat: no-repeat;
    content: "";
    width: 35px;
    height: 35px;
    background-position: center 0px;
    background-size: 100%; }
  .iconSpritesBantuan__carSurvey::after {
    background-position: center 0px; }
  .iconSpritesBantuan__claimStatus::after {
    background-position: center -37px; }
  .iconSpritesBantuan__endorsementRequest::after {
    background-position: center -70px; }
  .iconSpritesBantuan__publicInformation::after {
    background-position: center -105px; }
  .iconSpritesBantuan__paymentIssue::after {
    background-position: center -140px; }
  .iconSpritesBantuan__paymentStatus::after {
    background-position: center -175px; }
  .iconSpritesBantuan__policyStatus::after {
    background-position: center -210px; }
  .iconSpritesBantuan__refund::after {
    background-position: center -245px; }
  .iconSpritesBantuan__refundStatus::after {
    background-position: center -280px; }
  .iconSpritesBantuan__others::after {
    background-position: center -315px; }
  .iconSpritesBantuan__email::after {
    width: 40px;
    height: 40px;
    background-position: center -404px; }
  .iconSpritesBantuan__claim::after {
    background-position: center -525px; }
  .iconSpritesBantuan__dataChange::after {
    background-position: center -560px; }
  .iconSpritesBantuan__customer::after {
    background-position: center -455px; }
  .iconSpritesBantuan__customer__active::after {
    background-position: center -385px; }
  .iconSpritesBantuan__partner::after {
    background-position: center -420px; }
  .iconSpritesBantuan__partner__active::after {
    background-position: center -490px; }

.pusatBantuan__faq__title span.useSvg {
  margin-left: 17px !important; }
