/*
* ink colors (with variations only for black and white)
*/
/*
* ink colors (with variations only for black and white)
*/
.btn {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px; }
  .btn-outline-white {
    color: white;
    border-color: white; }
    .btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active {
      background: white !important;
      border-color: transparent !important;
      color: #329ef7 !important; }
    .btn-outline-white:focus, .btn-outline-white:active {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important; }
  .btn-outline-default {
    color: #1d66dd;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #dfdfdf; }
    .btn-outline-default.selected, .btn-outline-default:hover, .btn-outline-default:focus {
      border-color: #1d66dd;
      color: #1d66dd;
      transition: all ease .5s; }
  .btn-white {
    background: white;
    color: #1d66dd;
    font-weight: 500; }
    .btn-white:hover {
      color: #1d66dd; }
  .btn-subscribe {
    color: #6c757d;
    border-width: 1px;
    border-color: #6c757d;
    box-shadow: none !important; }
    .btn-subscribe:hover, .btn-subscribe:active, .btn-subscribe:focus {
      background-color: #6c757d !important;
      color: white !important;
      border-color: transparent !important; }
  .btn-lg {
    font-size: 1em; }

.button-other-premium {
  width: 25%; }
  @media (max-width: 991.98px) {
    .button-other-premium {
      width: 40%; } }
  @media (max-width: 767.98px) {
    .button-other-premium {
      width: 50%; } }

.btn-file {
  position: relative;
  overflow: hidden;
  border: dashed 1px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background: aliceblue; }

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

.button-primary, .button-default, .button-secondary, .button-faded-orange {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 calc(0.75 * 1rem);
  min-width: fit-content;
  width: auto;
  border-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  border-style: solid; }

.button-primary {
  color: #ffffff;
  background-color: #1D66DD; }
  .button-primary:focus {
    color: #ffffff;
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-primary:hover {
    color: #ffffff;
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-default {
  color: rgba(51, 51, 51, 0.64);
  background-color: #ffffff; }
  .button-default:focus {
    color: rgba(51, 51, 51, 0.64);
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-default:hover {
    color: rgba(51, 51, 51, 0.64);
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-default:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-secondary {
  color: rgba(51, 51, 51, 0.64);
  background-color: #f95151; }
  .button-secondary:focus {
    color: rgba(51, 51, 51, 0.64);
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-secondary:hover {
    color: rgba(51, 51, 51, 0.64);
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-faded-orange {
  color: #ffffff;
  background-color: #ef6767; }
  .button-faded-orange:focus {
    color: #ffffff;
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-faded-orange:hover {
    color: #ffffff;
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-faded-orange:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-sm {
  height: calc(1.5 * 1rem); }

.button-md {
  height: calc(2 * 1rem); }

.button-lg {
  height: calc(2.5 * 1rem); }

.agent__detail--view {
  padding-top: calc(1.25 * 1rem);
  background-color: #f2f7ff; }
  .agent__detail--view .agent__detail--wrapper {
    padding-right: 0;
    padding-left: 0; }
  .agent__detail--view .agent__info {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.5 * 1rem);
    padding: 0 calc(0.75 * 1rem);
    margin-bottom: calc(1.25 * 1rem);
    background-color: #ffffff; }
    .agent__detail--view .agent__info .agent__calling--detail {
      display: flex;
      align-items: center; }
      @media (max-width: 991.98px) {
        .agent__detail--view .agent__info .agent__calling--detail {
          flex-direction: column;
          align-items: flex-start; } }
      .agent__detail--view .agent__info .agent__calling--detail .agent__name {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: capitalize;
        width: 30%; }
      .agent__detail--view .agent__info .agent__calling--detail .details__info {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .agent__detail--view .agent__info .agent__calling--detail .badge {
        padding: calc(0.25 * 1rem) calc(0.5 * 1rem); }
      .agent__detail--view .agent__info .agent__calling--detail .online__status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: calc(0.5 * 1rem); }
        @media (max-width: 991.98px) {
          .agent__detail--view .agent__info .agent__calling--detail .online__status {
            margin: calc(0.25 * 1rem);
            margin-left: 0; } }
        .agent__detail--view .agent__info .agent__calling--detail .online__status span {
          font-size: 10px;
          line-height: 14px;
          font-weight: 500; }
        .agent__detail--view .agent__info .agent__calling--detail .online__status span:first-child {
          flex-grow: 0.2;
          width: 50px; }
        .agent__detail--view .agent__info .agent__calling--detail .online__status .badge {
          margin-left: calc(0.75 * 1rem); }
      .agent__detail--view .agent__info .agent__calling--detail .calling__status {
        position: relative; }
        .agent__detail--view .agent__info .agent__calling--detail .calling__status button {
          min-width: 30px;
          margin-left: 20px; }
  .agent__detail--view .tool__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(0.75 * 1rem);
    color: #1D66DD;
    padding-right: 0; }
    .agent__detail--view .tool__bar button {
      color: #1D66DD;
      border-color: #1D66DD; }
      .agent__detail--view .tool__bar button i {
        margin-right: calc(0.75 * 1rem); }
  .agent__detail--view .tso__agent-detail {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly; }
  .agent__detail--view .tso__list--table {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(1.25 * 1rem);
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 !important; }
    .agent__detail--view .tso__list--table .TableData {
      margin-top: 0 !important; }
    .agent__detail--view .tso__list--table .detail__view--status {
      height: 100%;
      width: 100%;
      min-height: 180px;
      border-bottom: 1px solid #1D66DD; }
      @media (max-width: 767.98px) {
        .agent__detail--view .tso__list--table .detail__view--status {
          min-height: 340px; } }
    .agent__detail--view .tso__list--table .search_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(1.25 * 1rem);
      border-top: 1px solid #a5c2f1; }
      @media (max-width: 767.98px) {
        .agent__detail--view .tso__list--table .search_container {
          flex-direction: column; } }
      .agent__detail--view .tso__list--table .search_container .search__wrapper {
        width: 40%; }
        @media (max-width: 767.98px) {
          .agent__detail--view .tso__list--table .search_container .search__wrapper {
            width: 100%; } }
        .agent__detail--view .tso__list--table .search_container .search__wrapper .search__box input {
          border-radius: 26px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
      .agent__detail--view .tso__list--table .search_container .filter__wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%; }
        @media (max-width: 767.98px) {
          .agent__detail--view .tso__list--table .search_container .filter__wrapper {
            justify-content: flex-start;
            flex-direction: column; } }
        .agent__detail--view .tso__list--table .search_container .filter__wrapper .lead__dropdown {
          width: 100%; }
          .agent__detail--view .tso__list--table .search_container .filter__wrapper .lead__dropdown:last-child {
            margin-left: calc(0.75 * 1rem); }
          @media (max-width: 767.98px) {
            .agent__detail--view .tso__list--table .search_container .filter__wrapper .lead__dropdown {
              width: 100%; }
              .agent__detail--view .tso__list--table .search_container .filter__wrapper .lead__dropdown:last-child {
                margin-left: 0;
                margin-top: calc(0.75 * 1rem); } }
          .agent__detail--view .tso__list--table .search_container .filter__wrapper .lead__dropdown .wrapper {
            max-width: 100% !important;
            flex: 0 0 100%; }
    .agent__detail--view .tso__list--table .rt-thead {
      border-top: 1px solid #a5c2f1;
      border-bottom: 1px solid #a5c2f1;
      background-color: #ffffff; }
      .agent__detail--view .tso__list--table .rt-thead .agent__detail--view {
        padding: calc(0.5 * 1rem) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) !important;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #1D66DD;
        border-right: none !important;
        box-shadow: none !important;
        text-align: left;
        background-color: transparent; }
    .agent__detail--view .tso__list--table .rt-body .rt-td {
      padding: 12px !important;
      padding-left: 30px !important; }
    .agent__detail--view .tso__list--table .notes__history {
      min-width: 32px; }
  .agent__detail--view .tab__container {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: calc(0.75 * 1rem);
    overflow: hidden;
    background-color: #ffffff; }
    .agent__detail--view .tab__container input {
      display: none; }
      .agent__detail--view .tab__container input:checked + label {
        color: #ffffff;
        background-color: #1D66DD; }
      .agent__detail--view .tab__container input#tab1:checked ~ .content__container #c1 {
        opacity: 1; }
      .agent__detail--view .tab__container input#tab2:checked ~ .content__container #c2 {
        opacity: 1; }
      .agent__detail--view .tab__container input#tab3:checked ~ .content__container #c3 {
        opacity: 1; }
      .agent__detail--view .tab__container input#tab4:checked ~ .content__container #c4 {
        opacity: 1; }
    .agent__detail--view .tab__container label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 36px;
      width: 25%;
      text-align: center;
      color: #1D66DD;
      position: relative;
      margin-bottom: 0;
      transition: 0.25s background ease;
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .agent__detail--view .tab__container label {
          height: 40px;
          width: 50%; } }
      .agent__detail--view .tab__container label::after {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        display: block;
        background: #ccc;
        bottom: 0;
        opacity: 0;
        left: 0;
        transition: 0.25s ease; }
    .agent__detail--view .tab__container .tab__divider {
      position: absolute;
      height: 1px;
      background: #a5c2f1;
      width: 100%;
      top: 35px;
      left: 0;
      transition: 0.25s ease; }
      @media (max-width: 991.98px) {
        .agent__detail--view .tab__container .tab__divider {
          top: 39px; } }
    .agent__detail--view .tab__container .content__container {
      background: #eee;
      position: relative;
      font-size: 16px; }
      .agent__detail--view .tab__container .content__container .content {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: calc(1.25 * 1rem);
        top: 0;
        opacity: 0;
        transition: 0.25s ease;
        color: #333; }
      .agent__detail--view .tab__container .content__container .metric__card {
        background-color: #ffffff;
        padding: calc(0.75 * 1rem);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: calc(1.25 * 1rem);
        border-bottom: 1px solid #a5c2f1;
        height: auto; }
        @media (max-width: 767.98px) {
          .agent__detail--view .tab__container .content__container .metric__card {
            grid-template-columns: 1fr !important; } }
        @media (min-width: 768px) {
          .agent__detail--view .tab__container .content__container .metric__card .left__panel--metrics {
            border-right: 1px solid #a5c2f1; } }
        @media (max-width: 767.98px) {
          .agent__detail--view .tab__container .content__container .metric__card .left__panel--metrics {
            border-bottom: 1px solid #a5c2f1; } }
        .agent__detail--view .tab__container .content__container .metric__card .metrics {
          display: flex;
          align-items: center;
          margin-bottom: calc(0.5 * 1rem); }
          .agent__detail--view .tab__container .content__container .metric__card .metrics span {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            width: 50%; }
          .agent__detail--view .tab__container .content__container .metric__card .metrics .metric__label {
            color: #77a3eb;
            width: 85%; }
          @media (max-width: 991.98px) {
            .agent__detail--view .tab__container .content__container .metric__card .metrics .metric__value {
              text-align: center; } }
