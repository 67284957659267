/*
* ink colors (with variations only for black and white)
*/
.faq-wrapper {
  background-color: white;
  padding: 32px 16px;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .faq-wrapper {
      padding: 48px 0;
      flex-direction: row;
      justify-content: center; } }
  .faq-wrapper .faq-container {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 576px) {
      .faq-wrapper .faq-container {
        flex-direction: row;
        justify-content: center;
        width: 1120px;
        margin: 0 auto; } }
    .faq-wrapper .faq-container .faq-title {
      display: flex;
      font-size: 20px;
      font-weight: bold;
      width: 100%; }
      @media (min-width: 576px) {
        .faq-wrapper .faq-container .faq-title {
          font-size: 26px;
          width: 341px; } }
    .faq-wrapper .faq-container .faq-list {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (min-width: 576px) {
        .faq-wrapper .faq-container .faq-list {
          margin-top: 0;
          margin-left: 24px;
          width: 755px;
          padding: 0 16px; } }
      .faq-wrapper .faq-container .faq-list .faq-item {
        display: flex;
        flex-direction: column;
        margin: 16px 0 0 0; }
        .faq-wrapper .faq-container .faq-list .faq-item .faq-item-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .faq-wrapper .faq-container .faq-list .faq-item .faq-item-header .faq-item-label {
            font-size: 14px;
            font-weight: bold; }
          .faq-wrapper .faq-container .faq-list .faq-item .faq-item-header .faq-item-icon {
            height: 20px;
            width: 20px; }
        .faq-wrapper .faq-container .faq-list .faq-item .faq-item-content {
          transition: all 300ms linear;
          width: fit-content;
          margin: 8px 0 0 0; }
          .faq-wrapper .faq-container .faq-list .faq-item .faq-item-content ul li {
            margin-top: 8px; }
          .faq-wrapper .faq-container .faq-list .faq-item .faq-item-content > ul {
            margin-bottom: 0 !important; }
