/*
* ink colors (with variations only for black and white)
*/
@media (max-width: 991.98px) {
  .declaration__form {
    padding-top: calc(0.75 * 1rem);
    padding-bottom: calc(5.25 * 1rem); } }

.declaration__form--wrapper {
  display: flex;
  flex-wrap: wrap; }
  .declaration__form--wrapper .form__field {
    width: 100%;
    margin-bottom: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)); }
    .declaration__form--wrapper .form__field--label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      margin-bottom: calc(0.5 * 1rem); }
    .declaration__form--wrapper .form__field--date {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .declaration__form--wrapper .form__field--date .date__column {
        width: 32%; }
        @media (max-width: 768px) {
          .declaration__form--wrapper .form__field--date .date__column {
            width: 100%; }
            .declaration__form--wrapper .form__field--date .date__column:not(:last-child) {
              margin-bottom: calc(0.75 * 1rem); } }
    .declaration__form--wrapper .form__field--file {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .declaration__form--wrapper .form__field--file .file__column {
        width: 48%; }
        @media (max-width: 768px) {
          .declaration__form--wrapper .form__field--file .file__column {
            width: 100%; }
            .declaration__form--wrapper .form__field--file .file__column:not(:last-child) {
              margin-bottom: calc(0.75 * 1rem); } }
        .declaration__form--wrapper .form__field--file .file__column .input-group-text {
          background: #1D66DD;
          color: #ffffff;
          cursor: pointer; }
        .declaration__form--wrapper .form__field--file .file__column .form-control[readonly] {
          background-color: #ffffff; }
  .declaration__form--wrapper .form__image {
    width: calc((100% / 3) - calc(0.75 * 1rem));
    margin-right: calc(0.75 * 1rem);
    margin-bottom: calc(0.5 * 1rem); }
    .declaration__form--wrapper .form__image--title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700; }
      .declaration__form--wrapper .form__image--title sup {
        color: #E81A1A; }
    .declaration__form--wrapper .form__image--box {
      width: 100px;
      height: 100px;
      border: 1px dashed #dfdfdf;
      border-radius: calc(0.75 * 1rem);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: calc(0.5 * 1rem); }
      @media (max-width: 768px) {
        .declaration__form--wrapper .form__image--box {
          width: 90px;
          height: 90px; } }
      .declaration__form--wrapper .form__image--box.uploaded {
        border: 1px solid #dfdfdf !important; }
      .declaration__form--wrapper .form__image--box .box__image {
        border-radius: calc(0.25 * 1rem);
        width: inherit;
        height: inherit;
        background-size: cover !important;
        background-repeat: round !important;
        display: flex;
        justify-content: center;
        align-items: center; }
        .declaration__form--wrapper .form__image--box .box__image--close {
          background: #333333;
          opacity: 0.5;
          border-radius: calc(1.25 * 1rem);
          width: calc(1.25 * 1rem);
          display: flex;
          justify-content: center;
          cursor: pointer; }
          .declaration__form--wrapper .form__image--box .box__image--close i {
            color: #ffffff; }
      .declaration__form--wrapper .form__image--box .box__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        background-color: #ffffff;
        border-radius: 50%; }
        .declaration__form--wrapper .form__image--box .box__content i {
          font-size: 14px; }
        .declaration__form--wrapper .form__image--box .box__content--upload {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #1D66DD;
          margin-bottom: 0;
          cursor: pointer; }
          @media (max-width: 768px) {
            .declaration__form--wrapper .form__image--box .box__content--upload {
              font-size: 11px;
              line-height: calc(0.75 * 1rem); } }

.declaration__form .DatePicker {
  display: flex;
  align-items: center;
  justify-content: center; }
  .declaration__form .DatePicker .custom__date--picker {
    color: #333333;
    line-height: 1.29;
    letter-spacing: normal;
    border-radius: calc(0.25 * 1rem);
    width: 100%;
    height: 40px;
    text-align: start;
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700; }
  .declaration__form .DatePicker .selected__day {
    background-color: #f2f7ff;
    color: #1D66DD; }
