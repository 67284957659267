/*
* ink colors (with variations only for black and white)
*/
.additionalBenefits__card--header {
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border-bottom: 1px solid #dfdfdf; }

.additionalBenefits__card--body {
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border-bottom: 1px solid #dfdfdf; }
  .additionalBenefits__card--body .body__item {
    margin-bottom: calc(1.25 * 1rem); }

.additionalBenefits__card--loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(2 * 1rem); }

.additionalBenefits__card--footer {
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .additionalBenefits__card--footer .footer__button {
    width: 100%;
    height: 40px; }
