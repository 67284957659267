/*
* ink colors (with variations only for black and white)
*/
.dana__callback {
  display: flex;
  justify-content: center;
  margin-top: 100px; }
  .dana__callback--box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(2 * 1rem); }
    .dana__callback--box .lds-ring {
      width: 80px;
      height: 80px; }
      .dana__callback--box .lds-ring.primary div {
        width: 80px;
        height: 80px; }
