/*
* ink colors (with variations only for black and white)
*/
.empty__state--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(3.25 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (max-width: 991.98px) {
    .empty__state--wrapper {
      flex-direction: column !important;
      height: max-content !important; } }
  .empty__state--wrapper .icon__wrapper {
    margin-right: calc(2 * 1rem); }
