/*
* ink colors (with variations only for black and white)
*/
.bengkel__finder {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0;
  border-radius: calc(0.5 * 1rem);
  height: max-content; }
  .bengkel__finder--banner {
    margin: 0 -16px;
    padding: 0;
    width: calc(100% + 32px);
    background-color: transparent;
    border: none; }
    .bengkel__finder--banner img {
      width: 100%;
      height: auto; }
  .bengkel__finder--showmore {
    background-color: #fff;
    border: 1px solid #1D66DD;
    color: #1D66DD;
    font-weight: bold;
    padding: 0 calc(0.75 * 1rem);
    min-width: 164px;
    min-height: 24px;
    height: calc(2.5 * 1rem);
    border-radius: 4px;
    text-transform: capitalize; }
  .bengkel__finder--header {
    padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0; }
    .bengkel__finder--header .header__title {
      font-size: 32px;
      margin-bottom: calc(0.5 * 1rem); }
    .bengkel__finder--header .header__content {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      line-height: 1.57;
      letter-spacing: 0.3px;
      text-align: left;
      color: #333333;
      margin: 0; }
      .bengkel__finder--header .header__content-primarytxt {
        color: #1D66DD; }
    .bengkel__finder--header .customized__brand--title {
      max-height: 60px;
      overflow: hidden; }
  .bengkel__finder--footer {
    padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
    .bengkel__finder--footer .footer__title {
      font-size: 20px; }
    .bengkel__finder--footer .footer__item {
      padding: calc(0.5 * 1rem) 0;
      display: flex;
      flex-direction: column; }
      .bengkel__finder--footer .footer__item:not(:last-child) {
        border-bottom: 1px solid #dfdfdf; }
      .bengkel__finder--footer .footer__item--info {
        width: 83%; }
        .bengkel__finder--footer .footer__item--info span {
          display: block; }
        .bengkel__finder--footer .footer__item--info .info__name {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700; }
        .bengkel__finder--footer .footer__item--info .info__location {
          margin-bottom: 0;
          text-transform: capitalize; }
      .bengkel__finder--footer .footer__item--link--googlemap {
        border: 1px solid #faaf40;
        padding: calc(0.25 * 1rem) calc(0.75 * 1rem);
        border-radius: calc(0.5 * 1rem);
        margin: calc(0.5 * 1rem) 0;
        color: #ffffff;
        width: unset;
        background-color: #faaf40; }
      @media (min-width: 992px) {
        .bengkel__finder--footer .footer__item {
          flex-direction: row; }
          .bengkel__finder--footer .footer__item--info {
            width: 85%; } }
    .bengkel__finder--footer .footer__cities {
      display: flex;
      flex-wrap: wrap; }
      .bengkel__finder--footer .footer__cities--item {
        padding: calc(0.5 * 1rem) 0;
        width: 25%; }
        @media (max-width: 991.98px) {
          .bengkel__finder--footer .footer__cities--item {
            width: 50%; } }
        .bengkel__finder--footer .footer__cities--item a {
          margin-bottom: calc(0.5 * 1rem);
          display: block; }
  .bengkel__finder--message {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400; }

.bengkel__popupForm__modal {
  width: 340px;
  margin: 0 auto; }
  .bengkel__popupForm__modal .modal-content {
    margin-top: 25%;
    padding: 0; }
