/*
* ink colors (with variations only for black and white)
*/
.financial-lead__form--header {
  padding: calc(0.75 * 1rem);
  background: #1D66DD;
  margin-bottom: calc(2 * 1rem); }
  .financial-lead__form--header .header__title {
    margin: 0;
    margin-bottom: calc(2 * 1rem);
    color: #ffffff; }
  .financial-lead__form--header .header__planner {
    height: 54px;
    background: #D2E0F8;
    margin-bottom: -40px;
    border-radius: calc(0.25 * 1rem);
    display: flex;
    justify-content: center;
    align-items: center; }
    .financial-lead__form--header .header__planner--left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 50%; }
    .financial-lead__form--header .header__planner--right {
      width: 100%;
      color: #164DA6;
      padding: 0 calc(0.5 * 1rem); }
      .financial-lead__form--header .header__planner--right p {
        color: #164DA6; }

.financial-lead__form--body {
  padding: calc(0.75 * 1rem);
  padding-bottom: 0; }
  .financial-lead__form--body .field__inline {
    display: flex; }
    .financial-lead__form--body .field__inline--left {
      width: 100%; }
    .financial-lead__form--body .field__inline--right {
      width: 70%;
      margin-left: calc(0.75 * 1rem); }
      .financial-lead__form--body .field__inline--right label {
        color: #717171; }
  .financial-lead__form--body .field {
    margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
    .financial-lead__form--body .field__submit {
      margin-bottom: calc(0.5 * 1rem); }
    .financial-lead__form--body .field__button {
      background: white;
      border: none;
      color: #1D66DD; }
      .financial-lead__form--body .field__button.lp-button-default:hover {
        opacity: unset !important;
        box-shadow: unset !important; }
  .financial-lead__form--body .form__title--label {
    color: #717171;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 0; }
    .financial-lead__form--body .form__title--label.hidden {
      visibility: hidden; }
  .financial-lead__form--body .form__gender--answer {
    display: flex;
    align-items: center;
    margin-top: calc(0.25 * 1rem); }
    .financial-lead__form--body .form__gender--answer span:first-child {
      margin-right: calc(0.75 * 1rem); }
    .financial-lead__form--body .form__gender--answer span {
      cursor: pointer;
      padding: calc(0.25 * 1rem) calc(0.5 * 1rem);
      border-radius: calc(0.25 * 1rem); }
      .financial-lead__form--body .form__gender--answer span:hover {
        background: #f2f7ff; }
      .financial-lead__form--body .form__gender--answer span:focus {
        background: #f2f7ff;
        outline: none; }
      .financial-lead__form--body .form__gender--answer span label {
        margin-left: calc(0.5 * 1rem);
        margin-bottom: 0; }

.financial-lead__form--footer {
  padding: calc(0.75 * 1rem); }
  .financial-lead__form--footer span {
    display: block; }
