/*
* ink colors (with variations only for black and white)
*/
.popular__vehicle-brands {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0;
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border-radius: calc(0.5 * 1rem); }
  @media (max-width: 991.98px) {
    .popular__vehicle-brands {
      margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
  .popular__vehicle-brands--title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .popular__vehicle-brands p {
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .popular__vehicle-brands--body {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0; }
    .popular__vehicle-brands--body .body__brand {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.33%;
      padding: calc(0.5 * 1rem);
      text-align: center; }
      .popular__vehicle-brands--body .body__brand:not(:nth-child(3n)) {
        border-right: 1px solid #dfdfdf; }
      .popular__vehicle-brands--body .body__brand:not(:nth-last-child(-n + 3)) {
        border-bottom: 1px solid #dfdfdf;
        justify-content: space-evenly; }
      .popular__vehicle-brands--body .body__brand a {
        display: block;
        line-height: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
      .popular__vehicle-brands--body .body__brand--image {
        margin-bottom: calc(0.5 * 1rem);
        max-width: 69px;
        height: auto; }
