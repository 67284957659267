/*
* ink colors (with variations only for black and white)
*/
.tutorial__wrapper {
  position: relative;
  text-align: center;
  padding: 50px 50px 0 50px; }
  @media (max-width: 991.98px) {
    .tutorial__wrapper {
      height: calc(100vh - 60px); } }
  .tutorial__wrapper .greeting__logo {
    height: 180px;
    width: 100%;
    margin-bottom: calc(2 * 1rem);
    text-align: center; }
    .tutorial__wrapper .greeting__logo img {
      height: 100% !important;
      width: 100% !important;
      object-fit: contain; }
  @media (min-width: 992px) {
    .tutorial__wrapper .action__block {
      margin-top: calc(2 * 1rem); } }
  .tutorial__wrapper .action__block button {
    height: 40px;
    width: 140px; }
  .tutorial__wrapper .action__block button:not(:last-child) {
    margin-right: calc(0.5 * 1rem); }
  @media (max-width: 991.98px) {
    .tutorial__wrapper .action__block {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 calc(1.25 * 1rem); }
      .tutorial__wrapper .action__block button {
        width: 45%; }
      .tutorial__wrapper .action__block button:only-child {
        width: 100%; } }
  @media (max-width: 991.98px) {
    .tutorial__wrapper .content {
      min-height: calc(100vh - 160px); } }

.agent__registration .agent__register {
  margin: 0 auto;
  max-width: 25%; }
  @media (max-width: 991.98px) {
    .agent__registration .agent__register {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; } }

.agent__register--footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  text-align: center;
  z-index: 100;
  background: #ffffff; }
  @media (max-width: 991.98px) {
    .agent__register--footer {
      display: none; } }
  .agent__register--footer p {
    margin: auto 120px;
    padding: 0 calc(2 * 1rem);
    white-space: pre-line; }
