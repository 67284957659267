/*
* ink colors (with variations only for black and white)
*/
.box__discount {
  padding: calc(0.75 * 1rem) calc(1.25 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.25 * 1rem);
  margin-bottom: calc(0.75 * 1rem);
  color: #1D66DD;
  cursor: pointer; }
  .box__discount.disable {
    background: #ebebeb;
    color: #c2c2c2;
    cursor: not-allowed;
    pointer-events: none; }
  .box__discount.rebate {
    border: solid 1px #1D66DD;
    color: #333333;
    cursor: text; }
