/*
* ink colors (with variations only for black and white)
*/
.other__payment--terms ul {
  padding-left: 15px; }
  .other__payment--terms ul li {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
    .other__payment--terms ul li:not(:last-child) {
      margin-bottom: calc(0.5 * 1rem); }

.other__payment--terms .divider {
  border-bottom: 1px solid #ffffff; }

.other__payment--terms .tc__wrapper .tc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: calc(0.5 * 1rem) 0; }

.other__payment--terms .tc__wrapper .estimasi {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%; }
