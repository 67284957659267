/*
* ink colors (with variations only for black and white)
*/
.primary_nav_link {
  font-family: 'Open Sans' !important;
  font-size: 14px;
  font-weight: 400;
  color: #222;
  line-height: 20px;
  text-decoration: none !important;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 16px;
  position: relative;
  border: none;
  cursor: pointer;
  transition-duration: 0.25s !important;
  transition-property: all;
  width: auto;
  padding: 16px;
  z-index: 2; }
  @media (min-width: 1200px) {
    .primary_nav_link {
      width: auto;
      padding: 24px 0 24px 0;
      position: relative;
      z-index: 2; } }
  .primary_nav_link.style_button_account {
    justify-content: center;
    gap: 6px;
    background-color: white;
    color: #1855B8;
    border: 1px solid #1855B8;
    padding: 10px;
    margin: 16px;
    width: auto;
    border-radius: 4px;
    fill: #1D66DD; }
    @media (min-width: 1200px) {
      .primary_nav_link.style_button_account {
        padding: 6px;
        margin: 14px 0; } }
    .primary_nav_link.style_button_account:hover {
      background-color: #1855B8;
      color: #ffffff;
      fill: #ffffff; }
      .primary_nav_link.style_button_account:hover > svg.style_button_account {
        fill: #ffffff; }
    .primary_nav_link.style_button_account > svg {
      fill: #ffffff; }
    .primary_nav_link.style_button_account > svg.style_button_account {
      transform: none;
      fill: #1855B8; }
  .primary_nav_link.selected {
    color: #1D66DD; }
    @media (min-width: 1200px) {
      .primary_nav_link.selected {
        width: auto; } }
    .primary_nav_link.selected::before {
      content: "";
      background-color: #1D66DD;
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: 0; }
      @media (min-width: 1200px) {
        .primary_nav_link.selected::before {
          width: calc(100% + 16px);
          left: -8px; } }
    .primary_nav_link.selected.style_button_account::before {
      background-color: transparent; }
    .primary_nav_link.selected > svg {
      transform: rotate(180deg);
      fill: #1D66DD; }
    .primary_nav_link.selected > svg.style_button_account:last-child {
      transform: rotate(180deg); }
  .primary_nav_link span {
    white-space: nowrap; }
  .primary_nav_link > svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    fill: #7B7B7B;
    transition-duration: 0.25s !important;
    transform-origin: center;
    transition-property: all; }

.secondary_nav_link {
  font-family: 'Open Sans' !important;
  font-size: 12px;
  font-weight: 500;
  color: #222;
  line-height: 20px;
  text-decoration: none !important;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 12px 10px 32px;
  border: none;
  cursor: pointer;
  position: relative; }
  .secondary_nav_link:hover {
    color: #1D66DD; }
  @media (min-width: 1200px) {
    .secondary_nav_link {
      background-color: #fff;
      padding: 10px 12px;
      z-index: 2; } }
  .secondary_nav_link.selected {
    color: #1D66DD; }
    .secondary_nav_link.selected > svg {
      fill: #1D66DD;
      transform: rotate(180deg); }
      @media (min-width: 1200px) {
        .secondary_nav_link.selected > svg {
          transform: rotate(-90deg); } }
  .secondary_nav_link > svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    fill: #7B7B7B;
    transition-duration: 0.25s !important;
    transform-origin: center;
    transition-property: all;
    transform: rotate(0); }
    @media (min-width: 1200px) {
      .secondary_nav_link > svg {
        transform: rotate(-90deg); } }

.tertiary_nav_link {
  font-family: 'Open Sans' !important;
  font-size: 12px;
  font-weight: 500;
  color: #222;
  line-height: 20px;
  text-decoration: none !important;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 0 10px 56px;
  border: none;
  cursor: pointer;
  position: relative; }
  .tertiary_nav_link:hover {
    color: #1D66DD; }
  @media (min-width: 1200px) {
    .tertiary_nav_link {
      background-color: #fff;
      padding: 10px 12px; } }
  .tertiary_nav_link.selected {
    color: #1D66DD; }
