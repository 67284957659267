/*
* ink colors (with variations only for black and white)
*/
.cartax__products {
  margin-bottom: calc(5.25 * 1rem); }
  .cartax__products__head {
    display: grid;
    grid-gap: calc(0.5 * 1rem);
    grid-template-columns: auto auto;
    height: 155px; }
    .cartax__products__head--title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: #ffffff; }
    .cartax__products__head--subtitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #ffffff; }
    .cartax__products__head__texts--backBtn {
      position: relative;
      top: -10px; }
      .cartax__products__head__texts--backBtn img {
        cursor: pointer;
        height: 30px;
        background: #ffffff;
        border-radius: 50%;
        padding: calc(0.25 * 1rem); }
    .cartax__products__head__image {
      width: 100%;
      height: inherit; }
      .cartax__products__head__image img {
        width: 100%;
        position: relative;
        top: 25%;
        border-radius: calc(0.25 * 1rem); }
    @media (min-width: 992px) {
      .cartax__products__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 350px; }
        .cartax__products__head--title {
          font-size: 32px;
          line-height: 1.8; }
        .cartax__products__head--subtitle {
          font-size: 20px;
          line-height: 2; }
        .cartax__products__head__texts {
          display: flex;
          flex-direction: column;
          justify-content: center; }
        .cartax__products__head__image img {
          position: relative;
          top: 10%;
          left: 30%;
          width: 60%; }
        .cartax__products__head__texts, .cartax__products__head__image {
          width: 43%; } }
  .cartax__products__head-product {
    display: block;
    height: 100px; }
    .cartax__products__head-product .cartax__products__head__texts {
      position: relative; }
      .cartax__products__head-product .cartax__products__head__texts--backBtn {
        position: absolute;
        left: 13px;
        bottom: -108px; }
        .cartax__products__head-product .cartax__products__head__texts--backBtn img {
          cursor: pointer;
          height: 30px;
          background: #ffffff;
          border-radius: 50%;
          padding: calc(0.25 * 1rem); }
    .cartax__products__head-product .cartax__products__head__image {
      display: flex;
      align-items: center;
      justify-content: center; }
      .cartax__products__head-product .cartax__products__head__image img {
        position: unset;
        width: 50%;
        background: #e4eefd; }
    @media (min-width: 992px) {
      .cartax__products__head-product {
        height: unset;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: calc(1.25 * 1rem) 0; }
        .cartax__products__head-product .cartax__products__head__texts {
          width: 50%; }
          .cartax__products__head-product .cartax__products__head__texts--backBtn {
            position: unset; }
            .cartax__products__head-product .cartax__products__head__texts--backBtn img {
              height: 40px; }
        .cartax__products__head-product .cartax__products__head__image {
          width: 40%; } }
  .cartax__products .head-default {
    border-bottom-left-radius: 100% 30%;
    border-bottom-right-radius: 100% 30%;
    background: #1D66DD;
    padding: calc(1.25 * 1rem) 0; }
  .cartax__products .head-default-product {
    background: #1D66DD;
    padding: calc(0.5 * 1rem) 0 calc(1.25 * 1rem); }
  .cartax__products__body {
    margin-top: calc(1.25 * 1rem); }
    .cartax__products__body--landingTitle {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      margin: calc(1.25 * 1rem) 0; }
      @media (min-width: 992px) {
        .cartax__products__body--landingTitle {
          font-size: 28px;
          line-height: 26px;
          font-weight: 700; } }
    .cartax__products__body--list {
      display: grid;
      gap: calc(0.75 * 1rem);
      grid-template-columns: auto auto; }
      .cartax__products__body--list--card {
        border-radius: calc(0.5 * 1rem);
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.24);
        padding-bottom: calc(0.5 * 1rem); }
        .cartax__products__body--list--card img {
          width: 100%;
          background: #e4eefd;
          object-fit: contain; }
        .cartax__products__body--list--card--title {
          font-size: 10px;
          line-height: 14px;
          font-weight: 700;
          padding: calc(0.25 * 1rem) calc(0.5 * 1rem);
          margin: 0; }
        .cartax__products__body--list--card--btn {
          font-size: 10px;
          line-height: 14px;
          font-weight: 500;
          border-radius: calc(0.5 * 1rem);
          padding: calc(0.25 * 1rem);
          background: #00b27b;
          color: #ffffff;
          width: 100%;
          text-align: center;
          line-height: 2; }
          .cartax__products__body--list--card--btn--container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 calc(0.5 * 1rem); }
          .cartax__products__body--list--card--btn:hover {
            color: #ffffff;
            text-decoration: none; }
      @media (min-width: 992px) {
        .cartax__products__body--list {
          display: flex;
          flex-direction: row; }
          .cartax__products__body--list--card {
            width: 200px;
            margin-right: calc(0.5 * 1rem);
            padding-bottom: calc(0.75 * 1rem); }
            .cartax__products__body--list--card img {
              object-fit: cover;
              height: 125px; }
            .cartax__products__body--list--card--title {
              font-size: 14px;
              line-height: 18px;
              font-weight: 700;
              line-height: 1.5;
              margin-bottom: calc(0.5 * 1rem);
              min-height: 50px; }
            .cartax__products__body--list--card--btn {
              font-size: 14px;
              line-height: 18px;
              font-weight: 500;
              line-height: 24px; }
              .cartax__products__body--list--card--btn--container {
                display: flex;
                align-items: center;
                justify-content: center; } }
    .cartax__products__body--head {
      margin: calc(0.5 * 1rem); }
      .cartax__products__body--head--title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        padding-left: calc(0.75 * 1rem); }
      .cartax__products__body--head--subtitle {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        padding: calc(0.5 * 1rem) 0 calc(0.75 * 1rem) calc(0.75 * 1rem);
        line-height: 1.67; }
      @media (min-width: 992px) {
        .cartax__products__body--head {
          position: absolute;
          top: 40%;
          padding: 0 calc(5.25 * 1rem);
          z-index: 2; }
          .cartax__products__body--head--title, .cartax__products__body--head--subtitle {
            color: #ffffff; }
          .cartax__products__body--head--title {
            font-size: 28px;
            line-height: 26px;
            font-weight: 700;
            margin-bottom: calc(0.75 * 1rem); }
          .cartax__products__body--head--subtitle {
            font-size: 22px;
            line-height: 24px;
            font-weight: 400; } }
    .cartax__products__body--nav {
      border-bottom: 1px solid #e2e8f0;
      display: flex;
      padding-left: calc(0.75 * 1rem); }
      .cartax__products__body--nav button {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        border: none;
        padding: calc(0.5 * 1rem);
        background: transparent; }
        @media (min-width: 992px) {
          .cartax__products__body--nav button:last-child {
            background: #1D66DD;
            margin-top: calc(0.75 * 1rem);
            margin-left: auto;
            width: 300px; } }
      .cartax__products__body--nav .btnActive {
        color: #1D66DD;
        border-bottom: 2px solid #1D66DD; }
    .cartax__products__body--tab {
      padding: calc(0.75 * 1rem); }
      .cartax__products__body--tab--title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        line-height: 2; }
      .cartax__products__body--tab--content {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: calc(0.25 * 1rem) 0 calc(0.75 * 1rem);
        line-height: 1.67; }
      .cartax__products__body--tab ol,
      .cartax__products__body--tab ul {
        margin: 0 0 calc(0.5 * 1rem);
        padding-left: calc(1.25 * 1rem); }
    .cartax__products__body--cta {
      padding: calc(0.75 * 1rem);
      position: fixed;
      margin: 0 auto;
      left: 0;
      bottom: 0;
      z-index: 1500;
      width: 100%;
      margin: 0 auto;
      background: #ffffff; }
    @media (min-width: 992px) {
      .cartax__products__body--nav {
        padding: 0;
        margin-top: calc(1.25 * 1rem); }
        .cartax__products__body--nav button {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          padding: calc(1.25 * 1rem) calc(2 * 1rem); }
        .cartax__products__body--nav button:focus {
          font-weight: bold; }
      .cartax__products__body--tab {
        padding: 0 0 28px;
        margin-top: calc(1.25 * 1rem); }
        .cartax__products__body--tab--title {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          margin-bottom: calc(0.75 * 1rem); }
        .cartax__products__body--tab--subtitle {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400; }
      .cartax__products__body--cta {
        float: right;
        width: 30%;
        padding-bottom: 0; } }
  .cartax__products__footer {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    border-top: 1px solid #dfdfdf;
    margin-top: calc(2 * 1rem);
    padding: calc(0.75 * 1rem);
    text-align: center;
    width: 100%; }
    @media (min-width: 992px) {
      .cartax__products__footer {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: calc(1.25 * 1rem) 0;
        margin-top: calc(2 * 1rem);
        text-align: center; } }
