/*
* ink colors (with variations only for black and white)
*/
.benefits__wrapper {
  margin-right: auto;
  margin-left: auto;
  transition: all .5s ease .1s; }
  @media (min-width: 768px) {
    .benefits__wrapper {
      margin-top: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem));
      max-width: 750px; } }
  @media (min-width: 992px) {
    .benefits__wrapper {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .benefits__wrapper {
      max-width: 1170px; } }
  .benefits__wrapper--back {
    color: #1D66DD;
    margin: calc(2 * 1rem) calc(0.75 * 1rem);
    cursor: pointer;
    width: max-content; }
  .benefits__wrapper--body {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    grid-gap: calc(1.25 * 1rem); }
    @media (max-width: 991.98px) {
      .benefits__wrapper--body {
        grid-template-columns: 1fr; } }
    @media (min-width: 768px) {
      .benefits__wrapper--body {
        margin-top: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
    .benefits__wrapper--body .body__left--product {
      padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
      border-radius: calc(0.25 * 1rem);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
      .benefits__wrapper--body .body__left--product p:last-child {
        color: #c2c2c2; }
      @media (max-width: 991.98px) {
        .benefits__wrapper--body .body__left--product {
          display: flex; }
          .benefits__wrapper--body .body__left--product .product__right {
            margin-left: calc(1.25 * 1rem);
            padding: calc(0.75 * 1rem) 0; } }
