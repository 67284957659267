/*
* ink colors (with variations only for black and white)
*/
.transaction__history-wrapper {
  padding: calc(1.25 * 1rem);
  display: grid;
  width: 30vw;
  margin: 20px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff; }
  @media (max-width: 991.98px) {
    .transaction__history-wrapper {
      width: 100vw;
      margin: 0; }
      .transaction__history-wrapper .border-radius-xs {
        border-radius: 0 !important; } }
  .transaction__history-wrapper .product__card {
    margin: calc(1.25 * 1rem) 0;
    padding-bottom: calc(0.75 * 1rem); }
    .transaction__history-wrapper .product__card:not(:last-child) {
      border-bottom: 1px solid #c2c2c2; }
    .transaction__history-wrapper .product__card--header {
      display: flex; }
      .transaction__history-wrapper .product__card--header .order__spec {
        display: inline-flex;
        flex-wrap: wrap; }
        .transaction__history-wrapper .product__card--header .order__spec--type {
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          width: 100%; }
        .transaction__history-wrapper .product__card--header .order__spec--id, .transaction__history-wrapper .product__card--header .order__spec--date {
          font-size: 10px;
          line-height: 14px;
          font-weight: 400; }
        .transaction__history-wrapper .product__card--header .order__spec span:not(:first-child) {
          margin: calc(0.75 * 1rem) 0; }
        .transaction__history-wrapper .product__card--header .order__spec span:last-child {
          margin-left: calc(calc(1.25 * 1rem) + calc(1.25 * 1rem)); }
      .transaction__history-wrapper .product__card--header .order__status {
        min-width: 30%;
        display: inline-flex;
        flex-direction: column; }
        .transaction__history-wrapper .product__card--header .order__status .active__badge {
          white-space: initial;
          max-height: 50px; }
    .transaction__history-wrapper .product__card--body {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .transaction__history-wrapper .product__card--body .product__name {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700; }
      .transaction__history-wrapper .product__card--body .product__price {
        font-size: 10px;
        line-height: 14px;
        font-weight: 700; }
    .transaction__history-wrapper .product__card--tax {
      display: flex;
      align-items: center;
      justify-content: flex-end !important; }
      .transaction__history-wrapper .product__card--tax .product__price {
        font-size: 10px;
        line-height: 14px;
        font-weight: 700; }

.transaction__history--body .product__list--empty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(2 * 1rem); }
