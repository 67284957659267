/*
* ink colors (with variations only for black and white)
*/
.agent__register--header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc(2 * 1rem);
  padding: 0 calc(1.25 * 1rem); }
  @media (min-width: 768px) {
    .agent__register--header {
      text-align: center; } }
  .agent__register--header .agent__referral--name {
    white-space: pre-line;
    width: 75%; }
    .agent__register--header .agent__referral--name span {
      color: #1D66DD; }

.agent__register--body {
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: calc(1.25 * 1rem);
  margin: calc(1.25 * 1rem) 0; }
