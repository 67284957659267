/*
* ink colors (with variations only for black and white)
*/
.application__shimmer {
  padding: 12px;
  max-width: 100%;
  height: 77px;
  background-color: #fff;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: calc(0.25 * 1rem);
  margin-bottom: calc(1.25 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .application__shimmer .background {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 50px;
    position: relative; }
  .application__shimmer div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0; }
    .application__shimmer div.a {
      height: 40px;
      left: 40px;
      right: auto;
      top: 0;
      width: 8px; }
    .application__shimmer div.b {
      height: 8px;
      left: 48px;
      top: 0; }
    .application__shimmer div.c {
      left: 1000px;
      top: 8px; }
    .application__shimmer div.d {
      height: 12px;
      left: 48px;
      top: 14px; }
    .application__shimmer div.e {
      left: 1000px;
      top: 26px; }
    .application__shimmer div.f {
      height: 12px;
      left: 48px;
      top: 32px; }
    .application__shimmer div.g {
      height: 20px;
      top: 40px; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
