/*
* ink colors (with variations only for black and white)
*/
.dropbox__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .dropbox__wrapper {
      margin-top: calc(2 * 1rem); } }
  .dropbox__wrapper .dropbox__hero--section {
    background-color: #1D66DD;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(2 * 1rem); }
    @media (min-width: 768px) {
      .dropbox__wrapper .dropbox__hero--section {
        padding-bottom: calc(1.25 * 1rem);
        justify-content: center; } }
    .dropbox__wrapper .dropbox__hero--section img {
      height: 50% !important;
      width: 50% !important;
      margin-bottom: calc(0.75 * 1rem); }
      @media (max-width: 991.98px) {
        .dropbox__wrapper .dropbox__hero--section img {
          height: 100% !important;
          width: 100% !important; } }
    .dropbox__wrapper .dropbox__hero--section h3 {
      color: #ffffff; }
    .dropbox__wrapper .dropbox__hero--section button {
      width: 50%;
      border-radius: calc(1.25 * 1rem);
      margin-top: calc(0.75 * 1rem); }
      .dropbox__wrapper .dropbox__hero--section button .icon__wrapper {
        margin-left: calc(0.75 * 1rem);
        font-size: 18px;
        color: #1D66DD; }
  .dropbox__wrapper .upload__form--area {
    padding: 0 calc(1.25 * 1rem) calc(1.25 * 1rem);
    margin: calc(1.25 * 1rem) auto;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .dropbox__wrapper .upload__form--area {
        width: 100%; } }
    .dropbox__wrapper .upload__form--area .upload__box {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .dropbox__wrapper .upload__form--area .upload__box:not(:last-child) {
        margin-bottom: calc(1.25 * 1rem); }
      .dropbox__wrapper .upload__form--area .upload__box .upload__title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700; }
      .dropbox__wrapper .upload__form--area .upload__box .upload__description {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400; }
  .dropbox__wrapper .disable {
    pointer-events: none;
    opacity: 0.5; }
