/*
* ink colors (with variations only for black and white)
*/
.bengkel__pagination {
  margin-top: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  display: flex;
  justify-content: center; }
  .bengkel__pagination ul {
    padding: 0;
    display: inline-flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0; }
    .bengkel__pagination ul li {
      cursor: pointer;
      color: #c2c2c2; }
      .bengkel__pagination ul li:not(:first-child):not(:last-child) {
        margin: 0 calc(0.75 * 1rem); }
      .bengkel__pagination ul li.bordered {
        padding: 5px 10px;
        border-radius: calc(0.25 * 1rem);
        border: 1px solid #d3d3d3; }
        .bengkel__pagination ul li.bordered:hover {
          transition: 0.5s;
          border: 1px solid #1D66DD;
          color: #1D66DD; }
      .bengkel__pagination ul li.selected {
        padding: 5px 10px;
        border: 1px solid #1D66DD;
        color: #1D66DD; }
      .bengkel__pagination ul li i {
        font-size: large; }
