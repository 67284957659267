/*
* ink colors (with variations only for black and white)
*/
.g-review-wrapper {
  background-color: white;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .g-review-wrapper {
      padding: 48px 0; } }
  .g-review-wrapper .g-review-header {
    display: flex;
    padding: 0 16px;
    flex-direction: column; }
    @media (min-width: 576px) {
      .g-review-wrapper .g-review-header {
        padding: 0;
        margin: 0 auto;
        width: 1120px;
        justify-content: space-between;
        flex-direction: row; } }
    .g-review-wrapper .g-review-header .g-review-title {
      font-size: 20px;
      font-weight: bold;
      margin: 0; }
      @media (min-width: 576px) {
        .g-review-wrapper .g-review-header .g-review-title {
          font-size: 28px;
          width: 630px; } }
    .g-review-wrapper .g-review-header .g-review-google-card {
      border-radius: 4px;
      padding: 12px;
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      border: 1px solid #d6d6d6; }
      @media (min-width: 576px) {
        .g-review-wrapper .g-review-header .g-review-google-card {
          font-size: 28px;
          width: 330px;
          margin: 0; } }
      .g-review-wrapper .g-review-header .g-review-google-card .g-review-google-card-image {
        height: 48px;
        width: 48px; }
      .g-review-wrapper .g-review-header .g-review-google-card .google-card-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        width: 100%; }
        .g-review-wrapper .g-review-header .g-review-google-card .google-card-detail .google-cart-title {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          margin: 0px;
          padding: 0px; }
        .g-review-wrapper .g-review-header .g-review-google-card .google-card-detail .google-cart-rate {
          display: flex;
          flex-direction: row; }
          .g-review-wrapper .g-review-header .g-review-google-card .google-card-detail .google-cart-rate .google-star {
            margin: 0px;
            padding: 0px;
            height: 25px;
            color: gold; }
          .g-review-wrapper .g-review-header .g-review-google-card .google-card-detail .google-cart-rate .google-start-text {
            font-size: 12px;
            font-weight: 400;
            margin: 3px 3px;
            color: gray; }
  .g-review-wrapper .g-review-card-wrapper {
    margin-top: 0px; }
    @media (min-width: 576px) {
      .g-review-wrapper .g-review-card-wrapper {
        margin-top: 24px; } }
    .g-review-wrapper .g-review-card-wrapper .g-review-card-item {
      flex-direction: column; }
      .g-review-wrapper .g-review-card-wrapper .g-review-card-item .g-review-card-icon {
        height: 32px;
        width: 40px;
        margin-right: 20px;
        top: 16px;
        position: relative;
        float: right; }
      .g-review-wrapper .g-review-card-wrapper .g-review-card-item .g-review-card {
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 8px;
        background-color: #f5f5f5;
        width: 296px;
        height: fit-content; }
        @media (min-width: 576px) {
          .g-review-wrapper .g-review-card-wrapper .g-review-card-item .g-review-card {
            width: 262px; } }
        .g-review-wrapper .g-review-card-wrapper .g-review-card-item .g-review-card .g-review-card-title {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          padding-top: 4px; }
        .g-review-wrapper .g-review-card-wrapper .g-review-card-item .g-review-card .g-review-card-comment {
          font-size: 16px;
          margin: 0;
          padding-top: 4px; }
    .g-review-wrapper .g-review-card-wrapper .carousel__inner-slide {
      width: 312px !important; }
      @media (min-width: 576px) {
        .g-review-wrapper .g-review-card-wrapper .carousel__inner-slide {
          width: 286px !important; } }
    .g-review-wrapper .g-review-card-wrapper .carousel__slide {
      width: 312px !important; }
      @media (min-width: 576px) {
        .g-review-wrapper .g-review-card-wrapper .carousel__slide {
          width: 286px !important; } }
