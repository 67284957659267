/*
* ink colors (with variations only for black and white)
*/
.qualification-thank-you-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 200px;
  gap: 20px; }
  @media (min-width: 576px) {
    .qualification-thank-you-wrapper {
      padding-top: 120px;
      gap: 40px; } }
  .qualification-thank-you-wrapper .thank-you-text {
    font-size: 16px;
    font-weight: 700;
    color: #134493;
    text-align: center;
    padding-top: 20px; }
