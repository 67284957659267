/*
* ink colors (with variations only for black and white)
*/
.homepage-promo-wrapper {
  background-color: white;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .homepage-promo-wrapper {
      padding: 48px 0; } }
  .homepage-promo-wrapper .homepage-promo-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    padding: 0px 16px;
    color: #333333;
    margin: 0; }
    @media (min-width: 576px) {
      .homepage-promo-wrapper .homepage-promo-title {
        font-size: 28px;
        line-height: 36px;
        width: 1120px;
        padding: 0px;
        margin: 0 auto 0 auto; } }
  .homepage-promo-wrapper .homepage-promo-carousel-wrapper {
    margin: 16px 0 0 0; }
    @media (min-width: 576px) {
      .homepage-promo-wrapper .homepage-promo-carousel-wrapper {
        margin: 24px 0 0 0; } }
    .homepage-promo-wrapper .homepage-promo-carousel-wrapper .homepage-promo-image {
      height: 113px;
      width: 199px; }
      @media (min-width: 576px) {
        .homepage-promo-wrapper .homepage-promo-carousel-wrapper .homepage-promo-image {
          height: 202px;
          width: 357px; } }
    .homepage-promo-wrapper .homepage-promo-carousel-wrapper .carousel__inner-slide {
      width: 215px !important; }
      @media (min-width: 576px) {
        .homepage-promo-wrapper .homepage-promo-carousel-wrapper .carousel__inner-slide {
          width: 381px !important; } }
    .homepage-promo-wrapper .homepage-promo-carousel-wrapper .carousel__slide {
      width: 215px !important; }
      @media (min-width: 576px) {
        .homepage-promo-wrapper .homepage-promo-carousel-wrapper .carousel__slide {
          width: 381px !important; } }
