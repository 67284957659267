/*
* ink colors (with variations only for black and white)
*/
@media (max-width: 991.98px) {
  .member__form {
    padding-top: calc(0.75 * 1rem);
    padding-bottom: calc(5.25 * 1rem); } }

.member__form--field {
  margin-bottom: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)); }
  .member__form--field .field__label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: calc(0.5 * 1rem); }
  .member__form--field .multicolumn__field {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .member__form--field .multicolumn__field--col-2 {
      width: 48%; }
      @media (max-width: 991.98px) {
        .member__form--field .multicolumn__field--col-2 {
          width: 100%; }
          .member__form--field .multicolumn__field--col-2:first-child {
            margin-bottom: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)); } }
    .member__form--field .multicolumn__field--col-3 {
      width: 32%; }
      @media (max-width: 991.98px) {
        .member__form--field .multicolumn__field--col-3 {
          width: 100%; }
          .member__form--field .multicolumn__field--col-3:first-child {
            margin-bottom: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)); } }
  .member__form--field .field__district {
    display: flex; }
    .member__form--field .field__district--col {
      width: 10%; }
      @media (max-width: 991.98px) {
        .member__form--field .field__district--col {
          width: 30%; } }
      .member__form--field .field__district--col:last-child {
        margin-left: calc(0.75 * 1rem); }

@media (min-width: 768px) {
  .member__form--button {
    display: none; } }

.member__form--additional {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: calc(1.25 * 1rem);
  border-radius: calc(0.25 * 1rem); }
