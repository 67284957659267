/*
* ink colors (with variations only for black and white)
*/
.text-area-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }

.h-12 {
  height: 48px !important; }

.text-sm {
  font-size: 14px !important; }

.area_box {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: solid 1px #1D66DD;
  background-color: #F6F9FE;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  color: #1D66DD;
  overflow-y: hidden; }
  .area_box:invalid {
    background-color: #F6F9FE; }
  .area_box:valid {
    background-color: #1D66DD;
    color: white; }

::placeholder {
  color: #1D66DD; }
