/*
* ink colors (with variations only for black and white)
*/
.agentProfile__card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .agentProfile__card--header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column; }
    .agentProfile__card--header .profile__details {
      display: inline-flex;
      padding: calc(1.25 * 1rem) calc(0.75 * 1rem);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
      .agentProfile__card--header .profile__details .profile__left--image {
        height: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem)) !important;
        width: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem)) !important;
        border-radius: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem)); }
      .agentProfile__card--header .profile__details .profile__left--icon {
        height: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem));
        width: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem));
        border-radius: calc(calc(3.25 * 1rem) + calc(0.25 * 1rem));
        background: #ef6767;
        color: #ffffff;
        font-size: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-right: calc(0.75 * 1rem); }
      .agentProfile__card--header .profile__details .profile__right {
        display: flex;
        align-items: flex-start;
        flex-direction: column; }
        .agentProfile__card--header .profile__details .profile__right h4:first-child {
          text-transform: capitalize; }
        .agentProfile__card--header .profile__details .profile__right h4:last-child {
          align-self: flex-start; }
    .agentProfile__card--header .balance__info {
      display: inline-flex;
      flex-direction: column;
      padding: 0 calc(0.75 * 1rem) calc(0.75 * 1rem); }
      .agentProfile__card--header .balance__info--summary {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: calc(0.5 * 1rem); }
        .agentProfile__card--header .balance__info--summary:first-child {
          margin: calc(0.5 * 1rem) 0; }
      .agentProfile__card--header .balance__info .withdraw {
        background: #f2f7ff;
        border-radius: 4px; }
        .agentProfile__card--header .balance__info .withdraw span {
          color: #1D66DD; }
      .agentProfile__card--header .balance__info button {
        margin-top: calc(0.75 * 1rem);
        padding: calc(0.5 * 1rem); }
  .agentProfile__card--body .body__list {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: calc(1.25 * 1rem);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    transition: 0.5s ease-in-out all; }
    .agentProfile__card--body .body__list--active {
      transition: 0.5s ease-in-out all; }
      .agentProfile__card--body .body__list--active:before {
        content: '';
        background: #1D66DD;
        position: absolute;
        height: 62px;
        left: 0;
        width: 5px;
        top: 0; }
    .agentProfile__card--body .body__list--disabled {
      background: #f8f8f8;
      cursor: not-allowed; }
    .agentProfile__card--body .body__list--l span {
      color: #000000; }
    @media (min-width: 768px) {
      .agentProfile__card--body .body__list--r {
        display: none; } }
    .agentProfile__card--body .body__list--r i {
      color: #1D66DD; }
