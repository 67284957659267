/*
* ink colors (with variations only for black and white)
*/
.loginRegister__wrapper {
  width: 100%;
  position: relative; }
  .loginRegister__wrapper--col {
    display: grid;
    width: 25vw;
    margin: 20px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.5 * 1rem);
    background-color: #ffffff;
    position: relative;
    top: 10vh;
    padding: calc(1.25 * 1rem); }
    @media (max-width: 991.98px) {
      .loginRegister__wrapper--col {
        width: 100vw;
        margin: 0;
        top: 0;
        border-radius: 0 !important; }
        .loginRegister__wrapper--col .border-radius-xs {
          border-radius: 0 !important; } }
