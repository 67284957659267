/*
* ink colors (with variations only for black and white)
*/
@media (min-width: 768px) {
  .bpjs__wrapper {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    min-height: calc(100vh - 75px); } }

@media (max-width: 991.98px) {
  .bpjs__wrapper {
    padding-left: 0;
    padding-right: 0; } }

.bpjs__wrapper--main .main__box {
  border-radius: calc(0.25 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (max-width: 991.98px) {
    .bpjs__wrapper--main .main__box {
      min-height: calc(100vh - 50px); } }
  .bpjs__wrapper--main .main__box hr {
    margin: 0; }
  .bpjs__wrapper--main .main__box--header {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    padding-bottom: 0 !important;
    display: flex; }
    .bpjs__wrapper--main .main__box--header .header__item {
      width: 20%;
      text-align: center;
      padding-bottom: calc(0.5 * 1rem);
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .bpjs__wrapper--main .main__box--header .header__item {
          width: 50%; } }
      .bpjs__wrapper--main .main__box--header .header__item.active {
        color: #1D66DD;
        border-bottom: 1px solid #1D66DD; }
      .bpjs__wrapper--main .main__box--header .header__item.disabled {
        color: #717171;
        pointer-events: none; }
  .bpjs__wrapper--main .main__box--body {
    padding: calc(1.25 * 1rem) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
    @media (min-width: 768px) {
      .bpjs__wrapper--main .main__box--body {
        width: 90%; } }
    @media (max-width: 991.98px) {
      .bpjs__wrapper--main .main__box--body .body__card {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        border-radius: calc(0.25 * 1rem);
        padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); } }
