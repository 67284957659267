/*
* ink colors (with variations only for black and white)
*/
.policy-member__tabs {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (min-width: 768px) {
    .policy-member__tabs {
      border-radius: calc(0.5 * 1rem); } }
  .policy-member__tabs--header {
    display: flex;
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    padding-bottom: 0 !important; }
    .policy-member__tabs--header .header__item {
      margin-bottom: 0;
      cursor: pointer; }
      .policy-member__tabs--header .header__item--text {
        width: max-content;
        margin-bottom: 0;
        padding-bottom: calc(0.75 * 1rem); }
        .policy-member__tabs--header .header__item--text.active {
          color: #1D66DD;
          border-bottom: 1px solid #1D66DD; }
  .policy-member__tabs--divider {
    margin: -1px 0 0 0;
    background: #f2f7ff; }
  .policy-member__tabs--body {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
    .policy-member__tabs--body .body__item {
      display: flex;
      width: 100%; }
      .policy-member__tabs--body .body__item--label {
        width: 40%; }
      .policy-member__tabs--body .body__item--value {
        width: 60%; }
