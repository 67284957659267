/*
* ink colors (with variations only for black and white)
*/
.beneficiaryCard__wrapper {
  padding-top: calc(1.25 * 1rem);
  margin-bottom: calc(1.25 * 1rem);
  border-radius: calc(0.25 * 1rem);
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22); }
  .beneficiaryCard__wrapper--header {
    position: relative;
    padding: 15px 20px !important;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    border-right: none !important;
    box-shadow: none !important; }
    .beneficiaryCard__wrapper--headerOpen {
      border-bottom: 1px solid lightgrey;
      padding-bottom: 30px; }
  .beneficiaryCard__wrapper .dob__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding-left: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    padding-right: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
    .beneficiaryCard__wrapper .dob__wrapper--col {
      flex: 0 0 20%;
      max-width: 20%;
      position: relative;
      margin-right: calc(calc(0.25 * 1rem) + calc(0.75 * 1rem)); }
