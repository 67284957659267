/*
* ink colors (with variations only for black and white)
*/
.loginRegister__card {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .loginRegister__card--header {
    padding: 16px 24px;
    border-bottom: 1px solid #dfdfdf; }
  .loginRegister__card--body {
    padding: 24px; }
    @media (min-width: 768px) {
      .loginRegister__card--body.right__shift {
        padding-right: 50%; } }
    .loginRegister__card--body .body__row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .loginRegister__card--body .body__row--width {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px; }
        @media (max-width: 991.98px) {
          .loginRegister__card--body .body__row--width {
            flex: 0 0 100%;
            max-width: 100%; } }
        .loginRegister__card--body .body__row--width .additional__button {
          display: flex;
          justify-content: center;
          margin-top: calc(1.25 * 1rem); }
          @media (max-width: 991.98px) {
            .loginRegister__card--body .body__row--width .additional__button {
              display: none; } }
    .loginRegister__card--body a.loginRegister {
      font-size: 12px;
      color: #1d66dd;
      cursor: pointer;
      margin-bottom: 24px; }
    .loginRegister__card--body .errorMessage {
      font-size: 12px;
      color: #e81a1a; }
