/*
* ink colors (with variations only for black and white)
*/
.payment-link-expired__wrapper .notification__wrapper--card {
  box-shadow: none;
  border: none; }

.payment-link-expired__wrapper .notification__wrapper .card__left--image {
  width: 160px !important;
  height: 160px !important; }

@media (max-width: 991.98px) {
  .payment-link-expired__wrapper .notification__wrapper .card__left {
    margin: calc(2 * 1rem) 0; } }

.payment-link-expired__wrapper .notification__wrapper .card__right .redirecting {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(1.25 * 1rem); }
  .payment-link-expired__wrapper .notification__wrapper .card__right .redirecting__text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; }
    .payment-link-expired__wrapper .notification__wrapper .card__right .redirecting__text--timer {
      color: #1d66dd;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500; }
