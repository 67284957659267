/*
* ink colors (with variations only for black and white)
*/
.installment__form {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: calc(0.75 * 1rem); }
  .installment__form label {
    cursor: pointer; }
