/*
* ink colors (with variations only for black and white)
*/
.questions-root {
  background-color: #134493; }
  @media (min-width: 576px) {
    .questions-root {
      background-color: #F6F9FE; } }
  .questions-root .qq-content {
    padding-left: 0;
    padding-right: 0;
    background-color: #F6F9FE;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center; }
    @media (min-width: 576px) {
      .questions-root .qq-content {
        padding-top: 8px;
        max-width: 560px; } }
    .questions-root .qq-content .qq-current {
      padding-bottom: calc(100vh - 300px); }
