/*
* ink colors (with variations only for black and white)
*/
.product__search--wrapper {
  position: relative;
  padding: calc(1.25 * 1rem);
  padding-bottom: 0; }
  .product__search--wrapper .mock__background {
    position: absolute;
    width: 100%;
    top: -200px;
    height: 350px;
    border-radius: 25%;
    background: #f5f5fa;
    border-bottom-left-radius: 90%;
    border-bottom-right-radius: 90%;
    z-index: -1;
    left: 0; }
    @media (max-width: 991.98px) {
      .product__search--wrapper .mock__background {
        top: -170px; } }
  .product__search--wrapper .product__search--header .header__title--psf {
    position: relative;
    bottom: -60%;
    color: #717171;
    text-align: center;
    width: 24%;
    white-space: break-spaces;
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      .product__search--wrapper .product__search--header .header__title--psf {
        width: 80%; } }
    .product__search--wrapper .product__search--header .header__title--psf.lead__form {
      text-align: start; }
      .product__search--wrapper .product__search--header .header__title--psf.lead__form .title__list {
        padding-left: calc(0.75 * 1rem) !important; }
        @media (max-width: 991.98px) {
          .product__search--wrapper .product__search--header .header__title--psf.lead__form .title__list {
            padding-left: 0 !important; } }
        .product__search--wrapper .product__search--header .header__title--psf.lead__form .title__list li {
          margin-bottom: calc(0.5 * 1rem);
          font-size: 12px;
          line-height: 16px;
          font-weight: 500; }
          .product__search--wrapper .product__search--header .header__title--psf.lead__form .title__list li::marker {
            font-family: "lifepal-icon";
            content: "\E914";
            color: #1D66DD;
            font-size: 14px; }
          .product__search--wrapper .product__search--header .header__title--psf.lead__form .title__list li span {
            margin-left: calc(0.5 * 1rem); }
  .product__search--wrapper .product__search--body {
    margin-top: calc(3.5 * calc(2 * 1rem));
    min-height: 100vh; }
    @media (max-width: 991.98px) {
      .product__search--wrapper .product__search--body {
        margin-top: calc(6 * calc(1.25 * 1rem)); } }
    .product__search--wrapper .product__search--body .product__search--container {
      width: 350px;
      margin: 0 auto;
      z-index: 1; }
      @media (max-width: 991.98px) {
        .product__search--wrapper .product__search--body .product__search--container {
          width: 100%; } }

.selected__item--view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(2 * 1rem);
  width: 100%; }
  .selected__item--view h4 {
    color: #707070; }

.minified-view {
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer; }
  .minified-view h4 {
    min-width: 80px;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .minified-view i {
    font-size: 18px;
    transition: 0.35s ease-in-out; }
    .minified-view i:hover {
      transform: scale(1.5);
      transition: 0.35s ease-in-out; }

.dob__action--block {
  margin-top: calc(1.25 * 1rem);
  display: flex;
  align-items: center;
  justify-content: center; }
  .dob__action--block button {
    height: 40px;
    width: 100%; }

.insurance__search--flow {
  display: flex;
  flex-direction: column; }
  .insurance__search--flow .life__memeber--psf .CheckboxForm .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .insurance__search--flow .life__memeber--psf .CheckboxForm .Wrapper li {
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
    .insurance__search--flow .life__memeber--psf .CheckboxForm .Wrapper label {
      width: 100%; }
    .insurance__search--flow .life__memeber--psf .CheckboxForm .Wrapper .InputWrapper {
      border-radius: 0; }

.travel__member--psf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: calc(0.25 * 1rem);
  padding-left: calc(0.75 * 1rem);
  margin-bottom: calc(0.5 * 1rem);
  box-sizing: border-box;
  width: 100%; }
  .travel__member--psf > label {
    margin-left: calc(0.75 * 1rem);
    margin-bottom: 0;
    color: #707070;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; }
  .travel__member--psf .DropdownForm {
    margin-left: auto;
    width: 150px;
    max-height: 40px; }
    .travel__member--psf .DropdownForm .css-1li0unn-control {
      border: none; }
  .travel__member--psf .show__member--age {
    visibility: visible; }
  .travel__member--psf .hide__member--age {
    visibility: hidden; }

.travel__date--period {
  color: #717171; }
  .travel__date--period h4 {
    margin-top: calc(0.75 * 1rem);
    text-align: center; }
  @media (max-width: 991.98px) {
    .travel__date--period .card {
      width: 60%;
      margin: 0 auto; } }

.member__form--action {
  display: flex;
  flex-direction: column;
  margin-top: calc(1.25 * 1rem); }
  .member__form--action button {
    margin-bottom: calc(1.25 * 1rem); }

.travel__insurance--psf {
  display: flex;
  flex-direction: column; }

.frequent__traveler--question {
  margin-top: calc(0.75 * 1rem);
  margin-left: calc(0.75 * 1rem); }
  .frequent__traveler--question span:first-child {
    margin-right: calc(2 * 1rem); }
  .frequent__traveler--question span label {
    margin-left: calc(0.5 * 1rem); }

.dob__selector input {
  text-align: center;
  color: #333333;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700; }

.DatePicker {
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .DatePicker .custom__date--picker {
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    line-height: 1.29;
    letter-spacing: normal;
    border-radius: 4px;
    width: 100%; }
    @media (max-width: 991.98px) {
      .DatePicker .custom__date--picker {
        height: 40px; } }
  .DatePicker .selected__day {
    background-color: #f2f7ff;
    color: #1D66DD; }

.life__member--item {
  padding: calc(0.5 * 1rem) !important;
  padding-left: 0;
  margin-bottom: calc(0.75 * 1rem);
  border-radius: calc(0.5 * 1rem); }
  .life__member--item:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .life__member--item label {
    margin-bottom: 0; }
    .life__member--item label .InputWrapper {
      border: 0 !important; }
      .life__member--item label .InputWrapper:hover {
        background: transparent; }
