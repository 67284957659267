/*
* ink colors (with variations only for black and white)
*/
.payment-details__wrapper {
  padding: 0 !important; }
  .payment-details__wrapper--header {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
  .payment-details__wrapper--body .body__item {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
    .payment-details__wrapper--body .body__item--detail {
      display: flex;
      flex-direction: row; }
      .payment-details__wrapper--body .body__item--detail .detail__content {
        margin-left: auto; }
      .payment-details__wrapper--body .body__item--detail .color--green {
        color: #00bd56; }
      .payment-details__wrapper--body .body__item--detail .button {
        color: #1D66DD;
        cursor: pointer; }
    .payment-details__wrapper--body .body__item--col {
      display: flex;
      flex-direction: column; }
  .payment-details__wrapper--body .no--border {
    border: none !important;
    padding-bottom: 0; }
  .payment-details__wrapper--body .body--blue {
    background-color: rgba(29, 102, 221, 0.04); }
  .payment-details__wrapper--body .block {
    display: block;
    animation: 0.5s anim-lineUp ease-in; }
  .payment-details__wrapper--body .none {
    display: none; }
  .payment-details__wrapper--footer .footer__confirmation {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-bottom: 1px solid #dfdfdf; }
  .payment-details__wrapper--footer .footer__submit {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
    .payment-details__wrapper--footer .footer__submit--button button {
      width: 100%; }
    .payment-details__wrapper--footer .footer__submit--total {
      display: flex;
      margin-bottom: calc(0.75 * 1rem); }
      .payment-details__wrapper--footer .footer__submit--total .total__amount {
        margin-left: calc(0.75 * 1rem); }

.footer__submit {
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
  @media (max-width: 991.98px) {
    .footer__submit {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      border-top-right-radius: calc(0.75 * 1rem);
      border-top-left-radius: calc(0.75 * 1rem);
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
      background-color: #ffffff; } }
  .footer__submit .footer__submit--total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(0.75 * 1rem); }
  .footer__submit .footer__submit--button button {
    width: 100%; }
