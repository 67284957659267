/*
* ink colors (with variations only for black and white)
*/
.displayNone {
  top: -100px; }

.simpleNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
  background: #ffffff;
  z-index: 9; }
  .simpleNavigation__blue {
    background: #1D66DD; }
  .simpleNavigation__backBtn {
    position: absolute;
    top: 5px;
    left: 5px;
    padding: calc(0.25 * 1rem);
    width: 35px;
    height: 35px; }
    .simpleNavigation__backBtn img {
      width: 24px;
      height: 24px; }
  .simpleNavigation__backBtnWhite {
    position: absolute;
    top: 25px;
    left: 15px;
    padding: calc(0.25 * 1rem);
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 50%;
    background: #1D66DD; }
    .simpleNavigation__backBtnWhite img {
      width: 24px;
      height: 24px; }
  .simpleNavigation__logo img {
    width: 90px;
    height: 30px; }

.space__between {
  justify-content: space-between; }

.flex__start {
  justify-content: flex-start; }

.simpleNavigationBlue {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #1D66DD;
  z-index: 1000; }
  @media (min-width: 992px) {
    .simpleNavigationBlue {
      height: 75px; } }
  .simpleNavigationBlue .header__invisble {
    visibility: hidden; }
  .simpleNavigationBlue__backBtn {
    height: 30px;
    width: 30px;
    padding: 3px;
    margin-left: calc(1.25 * 1rem); }
    .simpleNavigationBlue__backBtn i {
      font-size: 24px;
      color: #ffffff;
      font-weight: 600; }
  .simpleNavigationBlue__logo {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 991.98px) {
      .simpleNavigationBlue__logo {
        width: 75%; } }
    .simpleNavigationBlue__logo img {
      width: auto;
      height: 30px;
      text-align: center; }
  .simpleNavigationBlue .profile .profile__dropdown {
    border-radius: 50%;
    background-color: #ffffff;
    color: #ffffff;
    outline: none;
    border: none;
    height: 30px;
    width: 30px;
    margin-right: calc(0.75 * 1rem);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .simpleNavigationBlue .profile .profile__dropdown i {
      font-size: 22px;
      color: #1D66DD;
      vertical-align: middle; }
  .simpleNavigationBlue .profile .profile__menu--list {
    position: absolute;
    width: 75px;
    height: auto;
    right: 10px;
    background-color: #ffffff;
    list-style: none;
    padding: calc(0.5 * 1rem) calc(1.25 * 1rem) 0 calc(0.5 * 1rem);
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
    .simpleNavigationBlue .profile .profile__menu--list li {
      display: block;
      margin-bottom: calc(0.5 * 1rem); }

.dropdown-menu.show {
  border: none; }
  @media (min-width: 768px) {
    .dropdown-menu.show {
      border: 1px solid rgba(0, 0, 0, 0.15); } }

.dropdown-item:hover {
  color: #1D66DD;
  font-weight: 500; }

.dropdown-item:focus {
  background-color: white; }

.dropdown-menu.columns-2 {
  min-width: 350px; }

@media (max-width: 991.98px) {
  .navbar {
    height: 50px; } }

@media (max-width: 991.98px) {
  .navbar .container {
    display: block; } }

.navbar .container .navbarToggler {
  display: inline-block;
  padding: calc(0.25 * 1rem) calc(0.75 * 1rem);
  padding-left: 0px; }
  .navbar .container .navbarToggler i {
    color: #1d66dd;
    font-size: 20px; }
  @media (min-width: 768px) {
    .navbar .container .navbarToggler {
      display: none; } }

.navbar .container .account {
  display: inline;
  padding: calc(0.25 * 1rem) calc(0.75 * 1rem);
  padding-right: 0;
  float: right; }
  .navbar .container .account.active {
    background-color: #f2f7ff; }
  .navbar .container .account i {
    color: #1d66dd;
    font-size: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem)); }
  @media (min-width: 768px) {
    .navbar .container .account {
      display: none; } }

.navbar .container .search {
  display: inline-block;
  padding: calc(0.25 * 1rem) calc(0.75 * 1rem);
  float: right; }
  .navbar .container .search i {
    color: #1d66dd;
    font-size: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem)); }
  @media (min-width: 768px) {
    .navbar .container .search {
      display: none; } }

.nav-link.phone {
  text-align: center;
  margin-top: 0.5em; }
  @media (min-width: 768px) {
    .nav-link.phone {
      text-align: initial;
      margin-top: 0; } }

.nav-tabs {
  cursor: pointer; }

.navbar-brand.back-arrow {
  display: none;
  margin-right: 0; }
  @media (max-width: 576px) {
    .navbar-brand.back-arrow {
      display: inline-block; } }

.navbar-brand.hidden-div {
  display: none; }
  @media (max-width: 576px) {
    .navbar-brand.hidden-div {
      display: inline-block; } }

.orderNavbar .step-line {
  top: 20%;
  left: 50%;
  width: 100%;
  display: block;
  position: absolute;
  border-top: 3px solid #b6b6b6; }
  .orderNavbar .step-line.done {
    border-top: 3px solid black; }

.orderNavbar .circle {
  position: relative;
  height: 18px;
  width: 18px;
  background-color: #b6b6b6;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  cursor: pointer; }
  .orderNavbar .circle.isBayar, .orderNavbar .circle.notYet {
    cursor: auto; }
  .orderNavbar .circle .process-icon {
    margin: 1.5px;
    background-color: white;
    display: inline-block;
    border-radius: 50%;
    height: 9px;
    width: 9px; }
  .orderNavbar .circle.done {
    background-color: #333330; }
    .orderNavbar .circle.done .done-icon {
      color: white; }
  .orderNavbar .circle.active {
    cursor: auto;
    background-color: #1d66dd; }
    .orderNavbar .circle.active .active-icon {
      width: 12px;
      height: 12px;
      border-width: 1.5px;
      border-top-color: transparent;
      border-right-color: white;
      border-left-color: transparent;
      animation: 0;
      vertical-align: 1%; }

.orderNavbar .text-indicator {
  font-size: 12px;
  color: #b6b6b6; }
  .orderNavbar .text-indicator.done {
    color: #333330; }
  .orderNavbar .text-indicator.active {
    color: #1d66dd; }
