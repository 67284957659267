/*
* ink colors (with variations only for black and white)
*/
.application__wrapper {
  margin-top: calc(1.25 * 1rem);
  margin-right: auto;
  margin-left: auto;
  transition: all .5s ease .1s; }
  @media (min-width: 768px) {
    .application__wrapper {
      max-width: 750px; } }
  @media (min-width: 992px) {
    .application__wrapper {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .application__wrapper {
      max-width: 1140px; } }
  @media (max-width: 768px) {
    .application__wrapper {
      margin-bottom: 75px; } }
  .application__wrapper .back__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    width: max-content; }
    .application__wrapper .back__button i {
      font-size: calc(1.25 * 1rem);
      color: #1D66DD;
      margin-right: calc(0.5 * 1rem); }
    .application__wrapper .back__button:hover {
      color: #1D66DD; }
  .application__wrapper .application__title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: calc(2 * 1rem); }
  .application__wrapper--body {
    display: flex; }
    @media (max-width: 768px) {
      .application__wrapper--body {
        flex-direction: column-reverse; } }
    .application__wrapper--body .body__left {
      width: 70%; }
      @media (max-width: 768px) {
        .application__wrapper--body .body__left--content {
          padding-right: 10px;
          padding-left: 10px; } }
      @media (max-width: 768px) {
        .application__wrapper--body .body__left {
          width: 100%; } }
      @media (min-width: 768px) {
        .application__wrapper--body .body__left {
          padding-right: 15px; } }
    .application__wrapper--body .body__right {
      width: 30%; }
      @media (max-width: 768px) {
        .application__wrapper--body .body__right {
          width: 100%; } }
      @media (min-width: 768px) {
        .application__wrapper--body .body__right {
          padding-left: 15px; } }
      @media (min-width: 768px) {
        .application__wrapper--body .body__right--product {
          position: sticky;
          top: calc(75px + 20px); } }

@media (max-width: 991.98px) {
  .cancel__confirmation--modal {
    top: 60px; } }
