/*
* ink colors (with variations only for black and white)
*/
.popular__list--wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: calc(1.25 * 1rem);
  padding-left: calc(0.25 * 1rem);
  list-style: none; }
  .popular__list--wrapper .populat__item {
    text-transform: capitalize;
    padding: calc(0.5 * 1rem) calc(0.25 * 1rem);
    cursor: pointer;
    width: auto; }
    .popular__list--wrapper .populat__item:hover {
      background-color: #f5f5f5; }
