/*
* ink colors (with variations only for black and white)
*/
.progress__bar {
  padding: 0;
  width: 100%;
  height: 10px;
  overflow: hidden;
  background: #e4eefd;
  border-radius: calc(0.25 * 1rem);
  margin-top: calc(0.25 * 1rem); }
  .progress__bar .bar {
    float: left;
    min-width: 1%;
    height: 100%;
    background: #1D66DD; }

.progress__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: calc(0.25 * 1rem) 0; }
  .progress__info span {
    color: #717171; }
