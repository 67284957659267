/*
* ink colors (with variations only for black and white)
*/
.pln__form--field {
  display: flex;
  margin-bottom: calc(0.75 * 1rem); }
  @media (max-width: 991.98px) {
    .pln__form--field {
      flex-direction: column; } }
  .pln__form--field .field__left {
    flex-grow: 1; }
    @media (max-width: 991.98px) {
      .pln__form--field .field__left {
        margin-bottom: calc(0.5 * 1rem); } }
    .pln__form--field .field__left input {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      font-size: 14px !important; }
  .pln__form--field .field__right {
    flex-grow: 0.8; }
    @media (min-width: 768px) {
      .pln__form--field .field__right {
        margin-left: calc(0.75 * 1rem); } }

.pln__form--info {
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
  border-radius: 4px;
  border: solid 1px #1D66DD;
  background-color: #f2f7ff; }
  @media (min-width: 768px) {
    .pln__form--info {
      margin-bottom: calc(1.25 * 1rem); } }
  .pln__form--info .info__list {
    padding-left: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    list-style-type: circle;
    margin-bottom: 0; }

@media (max-width: 991.98px) {
  .pln__form--button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-top: 1px solid #dfdfdf; } }

.pln__form--button button {
  width: 100%; }
