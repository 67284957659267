/*
* ink colors (with variations only for black and white)
*/
.agent__tabs {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.75 * 1rem);
  background-color: #ffffff;
  margin-bottom: calc(1.25 * 1rem); }
  .agent__tabs--header {
    padding: calc(1.25 * 1rem) calc(1.25 * 1rem) 0 calc(1.25 * 1rem);
    display: flex; }
    .agent__tabs--header .header__item {
      width: 100%;
      margin-bottom: 0;
      cursor: pointer; }
      .agent__tabs--header .header__item--text {
        color: #D2E0F8;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: calc(0.75 * 1rem);
        text-align: center; }
        .agent__tabs--header .header__item--text.active {
          color: #1D66DD;
          border-bottom: 1px solid #1D66DD; }
  .agent__tabs--divider {
    margin: -1px 0 0 0;
    background: #f2f7ff; }
  .agent__tabs--body {
    padding: calc(0.75 * 1rem) calc(0.5 * 1rem);
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .agent__tabs--body .body__item--label {
      font-size: 10px;
      color: #77A3EB; }
      .agent__tabs--body .body__item--label.break {
        overflow: hidden;
        word-break: break-all;
        margin-bottom: calc(0.5 * 1rem); }
    .agent__tabs--body .body__item--value {
      color: #717171; }
