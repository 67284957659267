/*
* ink colors (with variations only for black and white)
*/
.cm-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 6px; }
  .cm-header .cm-title {
    font-size: 20px;
    line-height: 24px;
    color: white;
    font-weight: 700;
    margin-bottom: unset;
    padding: 0px 6px 10px 6px;
    text-align: left; }

.cm-body {
  padding: 16px 16px 32px 16px;
  border-radius: 8px 8px 0px 0px;
  background-color: white; }
  .cm-body .lead__callme_btn {
    font-size: 16px !important;
    font-weight: 700 !important; }
