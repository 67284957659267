.mobile-navigation-container {
  display: flex;
  width: 100%;
  background: transparent;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }
  .mobile-navigation-container .mobile-navigation-menus {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    justify-content: space-evenly;
    border-radius: calc(1 * 1rem) calc(1 * 1rem) 0 0;
    width: 100%;
    position: relative;
    padding: 10px 0 10px 0;
    z-index: 1030; }
    .mobile-navigation-container .mobile-navigation-menus .navigation-menu {
      box-shadow: none;
      border: none;
      border-radius: unset;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #333333;
      background: transparent;
      outline: none;
      border: none;
      margin: 0 10px;
      height: 45px;
      cursor: pointer;
      position: relative; }
      .mobile-navigation-container .mobile-navigation-menus .navigation-menu span {
        text-align: center; }
        .mobile-navigation-container .mobile-navigation-menus .navigation-menu span.active {
          color: #1d66dd;
          font-weight: 500; }
      .mobile-navigation-container .mobile-navigation-menus .navigation-menu div {
        box-shadow: none;
        border: none;
        border-radius: unset;
        padding: unset;
        height: 4px;
        width: 80%; }
      .mobile-navigation-container .mobile-navigation-menus .navigation-menu:hover {
        color: #1d66dd;
        font-weight: 500; }
        .mobile-navigation-container .mobile-navigation-menus .navigation-menu:hover i::before {
          color: #1d66dd; }
        .mobile-navigation-container .mobile-navigation-menus .navigation-menu:hover div {
          height: 4px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background: #1d66dd; }
    @media (min-width: 768px) {
      .mobile-navigation-container .mobile-navigation-menus {
        width: 480px; } }
