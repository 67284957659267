/*
* ink colors (with variations only for black and white)
*/
.qualification-container {
  font-family: "Open Sans", sans-serif !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: linear-gradient(to left, #4380E3, #1855B8, #134493); }
  .qualification-container * {
    font-family: "Open Sans", sans-serif !important; }
  .qualification-container #qqc-content {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    background-color: #F6F9FE;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px; }
    @media (min-width: 576px) {
      .qualification-container #qqc-content {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px; } }
  .qualification-container .qq-skip {
    position: sticky;
    bottom: 0;
    text-align: center;
    padding: 0px 16px 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0A224A;
    font-size: 12px;
    background-color: white; }
    @media (min-width: 576px) {
      .qualification-container .qq-skip {
        font-size: 14px; } }
    .qualification-container .qq-skip .qq-skip-link {
      color: #1D66DD;
      border-bottom: 1.6px dashed #1D66DD;
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
