/*
* ink colors (with variations only for black and white)
*/
.lead__form--wrapper {
  max-width: 750px; }
  .lead__form--wrapper .lead__form--short {
    background-color: #1D66DD;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: calc(0.75 * 1rem);
    padding: calc(1.25 * 1rem); }
    @media (max-width: 991.98px) {
      .lead__form--wrapper .lead__form--short {
        display: block; }
        .lead__form--wrapper .lead__form--short .userInformation__wrapper--group {
          margin-bottom: calc(0.5 * 1rem); } }
    .lead__form--wrapper .lead__form--short .grid__full {
      grid-column: 1/5;
      color: #ffffff; }
    .lead__form--wrapper .lead__form--short .gender__selector {
      margin-top: calc(0.75 * 1rem); }
      @media (max-width: 991.98px) {
        .lead__form--wrapper .lead__form--short .gender__selector {
          margin-top: 0; } }
      .lead__form--wrapper .lead__form--short .gender__selector span:first-child {
        margin-right: calc(0.75 * 1rem); }
      .lead__form--wrapper .lead__form--short .gender__selector label {
        margin-left: calc(0.25 * 1rem); }
    .lead__form--wrapper .lead__form--short .grid__center {
      grid-column: 2/4; }
    .lead__form--wrapper .lead__form--short button {
      width: 100%; }
    .lead__form--wrapper .lead__form--short .Help {
      color: #ef6767; }
  .lead__form--wrapper .Caption {
    color: #ffffff !important; }
