/*
* ink colors (with variations only for black and white)
*/
.search__container {
  margin-top: calc(2 * 1rem); }

.preferensi__wrapper--psf {
  padding: calc(0.5 * 1rem) calc(0.25 * 1rem);
  min-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden; }
  @media (max-width: 991.98px) {
    .preferensi__wrapper--psf {
      min-height: 500px; } }
  .preferensi__wrapper--psf .fiturs__wrapper {
    display: block; }
    .preferensi__wrapper--psf .fiturs__wrapper .fitur__box {
      display: inline-flex;
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      border-radius: calc(0.25 * 1rem);
      width: 100%;
      margin-bottom: calc(1.25 * 1rem); }
      .preferensi__wrapper--psf .fiturs__wrapper .fitur__box .lp-checkbox__wrapper {
        width: 100%; }

.preferensi__category--action {
  width: 100%;
  padding-top: calc(1.25 * 1rem); }
  .preferensi__category--action .action__item {
    margin-bottom: calc(0.75 * 1rem); }
    .preferensi__category--action .action__item button {
      width: 100% !important;
      padding: calc(0.5 * 1rem) !important; }
