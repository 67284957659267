/*
* ink colors (with variations only for black and white)
*/
.dm-root {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background-color: #fff;
  width: 248px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition-duration: 0.25s;
  transition-property: all;
  transform: translateX(-105%);
  box-shadow: 0px 2px 8px 0px rgba(0, 58, 109, 0.16); }
  @media (min-width: 1200px) {
    .dm-root {
      display: block;
      flex-grow: 1;
      width: auto;
      height: auto;
      position: relative;
      transform: none;
      box-shadow: none; } }
  .dm-root.is-open {
    transition-duration: 0.5s;
    transform: translateX(0); }
  .dm-root .ul, .dm-root .ul_parent {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0; }
  .dm-root .ul_parent {
    flex-direction: column;
    width: 100%; }
    @media (min-width: 1200px) {
      .dm-root .ul_parent {
        flex-direction: row; } }

.dm-root-header {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 48px;
  padding-left: 16px; }

.dm-root-body {
  overflow-y: auto;
  display: block;
  flex-grow: 1;
  width: 100%; }
  @media (min-width: 1200px) {
    .dm-root-body {
      overflow-y: visible; } }
