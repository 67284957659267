/*
* ink colors (with variations only for black and white)
*/
.bengkel__article {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  height: 500px;
  overflow: hidden;
  margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)) 0;
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border-radius: calc(0.5 * 1rem); }
  .bengkel__article.expanded {
    height: unset;
    overflow: unset; }
  @media (max-width: 991.98px) {
    .bengkel__article {
      margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
  .bengkel__article--title {
    font-size: 20px; }
  .bengkel__article--sub-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: calc(0.5 * 1rem); }
  .bengkel__article p {
    margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
  .bengkel__article--shadow {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 155px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #ffffff 70%);
    display: flex;
    align-items: flex-end;
    justify-content: center; }
    .bengkel__article--shadow input {
      display: none; }
    .bengkel__article--shadow .text__button {
      color: #1D66DD;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); }
