/*
* ink colors (with variations only for black and white)
*/
.verify__otp {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .verify__otp--title {
    margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
  .verify__otp--field {
    display: flex;
    height: 50px;
    margin-bottom: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem)); }
    .verify__otp--field .field__number {
      display: flex;
      justify-content: center;
      width: 20%; }
      .verify__otp--field .field__number .otp__input {
        width: 60%;
        border-radius: calc(0.25 * 1rem);
        background-color: #f2f7ff;
        padding: calc(0.5 * 1rem);
        font-size: 18px;
        border: 1px solid #ffffff;
        text-align: center;
        outline: none; }
        .verify__otp--field .field__number .otp__input .otp__input::-webkit-inner-spin-button,
        .verify__otp--field .field__number .otp__input .otp__input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .verify__otp--field .field__number .otp__input .otp__input::-moz-inner-spin-button,
        .verify__otp--field .field__number .otp__input .otp__input::-moz-outer-spin-button {
          -moz-appearance: none;
          margin: 0; }
  .verify__otp--countdown .countdown__button {
    color: #1D66DD;
    cursor: pointer; }
    .verify__otp--countdown .countdown__button:hover {
      text-decoration: underline; }
  .verify__otp--countdown .countdown__info {
    color: #c2c2c2; }
  .verify__otp--button {
    width: 100%; }
  .verify__otp--success {
    text-align: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    margin-bottom: calc(1.25 * 1rem);
    color: white;
    background-color: #5cc197; }
