/*
* ink colors (with variations only for black and white)
*/
.agentCard__wrapper {
  height: 100%;
  min-height: 250px;
  background: #1D66DD;
  padding: calc(1.25 * 1rem);
  border-radius: calc(0.5 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  width: 100%; }
  @media (max-width: 991.98px) {
    .agentCard__wrapper {
      border-radius: calc(calc(0.25 * 1rem) + calc(0.75 * 1rem));
      margin-right: calc(calc(0.25 * 1rem) + calc(0.75 * 1rem)); } }
  .agentCard__wrapper--head {
    display: flex;
    height: 20%;
    position: relative; }
    .agentCard__wrapper--head .head__item {
      width: 50%; }
      .agentCard__wrapper--head .head__item--icon {
        position: absolute;
        right: 20px;
        float: right;
        width: 50px;
        border-radius: calc(0.25 * 1rem);
        background-color: #ffffff;
        color: #1D66DD;
        display: flex;
        justify-content: center;
        padding: calc(0.25 * 1rem);
        cursor: pointer; }
        .agentCard__wrapper--head .head__item--icon:hover {
          background: #1D66DD;
          color: #ffffff;
          border: 1px solid #ffffff;
          transition: .6s; }
  .agentCard__wrapper--body {
    height: 50%; }
    .agentCard__wrapper--body p {
      color: #ffffff; }
      .agentCard__wrapper--body p.body__item--name {
        text-transform: capitalize;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold; }
  .agentCard__wrapper--foot {
    height: 33.33%;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .agentCard__wrapper--foot {
        align-items: end; } }
    .agentCard__wrapper--foot .foot__item {
      color: #ffffff;
      width: 100%; }
      .agentCard__wrapper--foot .foot__item--icon {
        display: inline;
        border: 1px solid;
        border-radius: 67px;
        padding: 0 5px;
        margin-right: calc(0.5 * 1rem); }
        .agentCard__wrapper--foot .foot__item--icon i {
          font-size: 10px; }
      .agentCard__wrapper--foot .foot__item--divider {
        display: flex; }
        .agentCard__wrapper--foot .foot__item--divider .foot__left {
          width: 60%;
          padding-right: calc(0.5 * 1rem); }
          .agentCard__wrapper--foot .foot__item--divider .foot__left hr {
            background: #ffffff; }
        .agentCard__wrapper--foot .foot__item--divider .foot__right {
          display: flex;
          align-items: center;
          width: 30%; }
