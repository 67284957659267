/*
* ink colors (with variations only for black and white)
*/
.verification__wrapper {
  display: flex;
  justify-content: center;
  height: calc(100vh - 75px);
  padding-top: calc(1.25 * 1rem); }
  @media (max-width: 991.98px) {
    .verification__wrapper {
      height: calc(100vh - 50px); } }
  .verification__wrapper--card {
    min-width: 350px;
    height: fit-content;
    padding: 16px;
    border-radius: calc(0.5 * 1rem); }
    @media (min-width: 768px) {
      .verification__wrapper--card {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); } }
    .verification__wrapper--card .card__title {
      font-size: 22px;
      line-height: 24px;
      font-weight: 700; }
      .verification__wrapper--card .card__title span {
        font-size: 22px;
        line-height: 24px;
        font-weight: 400; }
    .verification__wrapper--card .card__body {
      margin-top: calc(2 * 1rem); }
      .verification__wrapper--card .card__body--field {
        margin-bottom: calc(0.75 * 1rem); }
      .verification__wrapper--card .card__body--row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: calc(1.25 * 1rem); }
      .verification__wrapper--card .card__body .kyc__selector {
        margin: calc(0.75 * 1rem) 0;
        display: flex;
        align-items: center;
        margin-top: calc(0.75 * 1rem); }
        .verification__wrapper--card .card__body .kyc__selector span:first-child {
          margin-right: calc(0.75 * 1rem); }
        .verification__wrapper--card .card__body .kyc__selector span {
          cursor: pointer;
          padding: calc(0.5 * 1rem);
          border-radius: calc(0.75 * 1rem); }
          .verification__wrapper--card .card__body .kyc__selector span input:checked span {
            background: #f2f7ff; }
          .verification__wrapper--card .card__body .kyc__selector span:hover {
            background: #f2f7ff; }
          .verification__wrapper--card .card__body .kyc__selector span:focus {
            background: #f2f7ff;
            outline: none; }
          .verification__wrapper--card .card__body .kyc__selector span label {
            margin-left: calc(0.5 * 1rem);
            margin-bottom: 0; }
        .verification__wrapper--card .card__body .kyc__selector .kyc__selected {
          cursor: pointer;
          border-radius: calc(0.75 * 1rem);
          background: #f2f7ff; }
  .verification__wrapper .field__label {
    font-size: 12px;
    margin-bottom: calc(0.5 * 1rem); }
