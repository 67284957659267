/*
* ink colors (with variations only for black and white)
*/
@media (max-width: 991.98px) {
  .levelInformation__card--body {
    padding: calc(1.25 * 1rem) 0; } }

.levelInformation__card--body .card__row {
  display: flex;
  flex-wrap: wrap;
  padding: calc(1.25 * 1rem);
  padding-top: 0;
  justify-content: space-between; }

.levelInformation__card--body .card__col {
  position: relative;
  height: 92px;
  width: 92px;
  margin-right: calc(0.5 * 1rem);
  margin-bottom: calc(1.25 * 1rem); }
  .levelInformation__card--body .card__col--box {
    cursor: pointer;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.25 * 1rem);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .levelInformation__card--body .card__col--box:hover {
      border: solid 1px #1D66DD; }
    .levelInformation__card--body .card__col--box.active {
      border: solid 1px #1D66DD; }
      .levelInformation__card--body .card__col--box.active p {
        color: #717171;
        font-weight: 700; }
    .levelInformation__card--body .card__col--box .disable__level {
      position: relative; }
    .levelInformation__card--body .card__col--box.disable img {
      opacity: 0.3; }
    .levelInformation__card--body .card__col--box img {
      height: 40px !important;
      width: 40px !important; }
    .levelInformation__card--body .card__col--box .check-me {
      position: absolute;
      right: calc(0.25 * 1rem);
      top: -1px;
      font-size: large;
      color: #28bc0c; }
    .levelInformation__card--body .card__col--box .lock-me {
      position: absolute;
      left: 40%;
      top: 15%;
      font-size: large;
      color: #333333; }

.levelInformation__card--footer {
  margin: calc(1.25 * 1rem);
  margin-top: 0; }
  @media (max-width: 991.98px) {
    .levelInformation__card--footer {
      margin: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem)); } }
  .levelInformation__card--footer ul {
    padding-inline-start: calc(1.25 * 1rem); }
  .levelInformation__card--footer .agent__level--footer {
    border-radius: calc(0.75 * 1rem);
    padding: calc(0.75 * 1rem);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
    .levelInformation__card--footer .agent__level--footer p {
      color: #717171; }
    .levelInformation__card--footer .agent__level--footer ul li {
      color: #717171; }
