/*
* ink colors (with variations only for black and white)
*/
.kyc__tax__identity__wrapper {
  width: 100%;
  padding: calc(0.75 * 1rem) 0;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24); }
  .kyc__tax__identity__wrapper .head {
    padding: calc(1.25 * 1rem) 0;
    border-bottom: 1px dotted #333333; }
    .kyc__tax__identity__wrapper .head img {
      width: 17.5px;
      height: 12.5px; }
    .kyc__tax__identity__wrapper .head .title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      margin-left: calc(1.25 * 1rem); }

.kyc__tax__identity__field {
  padding: calc(1.25 * 1rem) 0 0;
  display: flex;
  flex-direction: column; }
  .kyc__tax__identity__field__file {
    padding: calc(0.5 * 1rem) 0; }
    .kyc__tax__identity__field__file .title {
      font-weight: 500; }
    .kyc__tax__identity__field__file button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      width: 50%;
      margin: 5% 25%; }
  .kyc__tax__identity__field label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500; }
  .kyc__tax__identity__field input {
    padding: calc(0.5 * 1rem);
    border-radius: calc(0.25 * 1rem);
    border: 1px solid #c2c2c2; }
  .kyc__tax__identity__field div {
    padding-bottom: calc(0.5 * 1rem); }
    .kyc__tax__identity__field div .radio__selector label {
      padding: 0 10px; }
