/*
* ink colors (with variations only for black and white)
*/
.psf-shimmer {
  display: flex !important;
  height: 250px !important; }

.shimmer .shine {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  height: 20px;
  width: 270px;
  margin: 0 auto;
  margin-top: 4px; }

.shimmer .avatar {
  align-self: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-left: 0; }

.shimmer .shimmer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(0.75 * 1rem);
  margin-top: calc(0.75 * 1rem); }

.shimmer p {
  height: 10px !important;
  margin-top: 10px;
  width: 100px !important;
  margin-bottom: calc(0.5 * 1rem); }

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
