/*
* ink colors (with variations only for black and white)
*/
.bantuan__container {
  width: 30vw;
  margin: 20px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (max-width: 991.98px) {
    .bantuan__container {
      height: calc(100vh + 250px);
      width: 100vw;
      margin: 0; } }
  .bantuan__container .header {
    min-height: 60px;
    background-color: #eef3fc;
    padding: calc(1.25 * 1rem);
    display: flex;
    justify-content: space-between; }
    .bantuan__container .header .creative__wrapper {
      height: 60px;
      width: 60px; }
      .bantuan__container .header .creative__wrapper img {
        transform: scale(1.5); }
  .bantuan__container .bantuan__form {
    padding: calc(0.75 * 1rem); }
    .bantuan__container .bantuan__form .disable {
      opacity: 0.5;
      pointer-events: none; }
    .bantuan__container .bantuan__form .input__group textarea {
      min-height: 50px;
      width: 100%;
      padding: calc(0.5 * 1rem);
      border: 1px solid #c2c2c2; }
      .bantuan__container .bantuan__form .input__group textarea::placeholder {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400; }
      .bantuan__container .bantuan__form .input__group textarea:focus {
        border-color: #1D66DD;
        outline: none; }
    .bantuan__container .bantuan__form .input__group .lp__input--group {
      margin: calc(0.75 * 1rem) 0; }
      .bantuan__container .bantuan__form .input__group .lp__input--group .lp__input {
        padding: 0 calc(0.25 * 1rem); }
    .bantuan__container .bantuan__form .input__group .input__group--title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #333333; }
    .bantuan__container .bantuan__form .input__group .input__group--subtitle {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #707070; }
    .bantuan__container .bantuan__form .input__group .image__placeholder {
      width: 20%;
      min-height: 70px;
      background-color: #eaeaea;
      margin: calc(0.75 * 1rem) auto;
      border-radius: calc(0.75 * 1rem);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .bantuan__container .bantuan__form .input__group .image__placeholder .upload__icon--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #1D66DD;
        text-align: center; }
    .bantuan__container .bantuan__form .input__group .file__name--container {
      background: white;
      border: 1px solid #c2c2c2;
      padding: calc(1.25 * 1rem);
      margin-bottom: calc(1.25 * 1rem);
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .bantuan__container .bantuan__form .input__group .file__name--container span:first-child {
        color: #1D66DD; }
      .bantuan__container .bantuan__form .input__group .file__name--container i {
        font-size: 18px;
        cursor: pointer; }
