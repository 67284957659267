/*
* ink colors (with variations only for black and white)
*/
.product__card {
  padding: 0 calc(1.25 * 1rem);
  border-radius: calc(0.25 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (max-width: 991.98px) {
    .product__card {
      margin-bottom: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
      padding: calc(0.25 * 1rem) calc(1.25 * 1rem); } }
  .product__card--header {
    padding: calc(calc(1.25 * 1rem) + calc(0.25 * 1rem)) 0;
    border-bottom: 1px solid #dfdfdf; }
  .product__card--body {
    padding: calc(1.25 * 1rem) 0; }
    .product__card--body .body__product {
      margin-top: calc(0.5 * 1rem); }
      .product__card--body .body__product--name {
        font-size: 14px;
        font-weight: 500; }
      .product__card--body .body__product--charges {
        font-size: 12px;
        color: #b6b6b6;
        margin-bottom: 0; }
      .product__card--body .body__product--premium {
        display: flex;
        align-items: center; }
        .product__card--body .body__product--premium .premium__with-tooltip {
          margin-left: auto; }
  .product__card--notification {
    text-align: center;
    margin-top: calc(0.5 * 1rem); }
    .product__card--notification .notification__error {
      color: #E81A1A; }
  .product__card--footer {
    padding-bottom: calc(1.25 * 1rem); }
    .product__card--footer .button__checkout, .product__card--footer .button__save, .product__card--footer .button__cancel {
      height: 40px;
      width: 100%;
      margin-bottom: calc(0.5 * 1rem);
      border-radius: calc(0.25 * 1rem);
      font-weight: 500;
      transition: 0.3s; }
    .product__card--footer .button__save {
      background: #ffffff;
      border: 1px solid #1D66DD;
      color: #1D66DD; }
      .product__card--footer .button__save:disabled {
        background: #A5C2F1;
        color: #ffffff;
        border: 1px solid #ffffff;
        cursor: not-allowed; }
      .product__card--footer .button__save:hover:enabled {
        background: #1D66DD;
        color: #ffffff; }
    .product__card--footer .button__cancel {
      color: #E81A1A;
      background: #ffffff;
      border: none; }
      .product__card--footer .button__cancel:disabled {
        color: #ef6767;
        cursor: not-allowed; }
      .product__card--footer .button__cancel:hover:enabled {
        background: #E81A1A;
        color: #ffffff; }
