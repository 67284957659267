/*
* ink colors (with variations only for black and white)
*/
.qualification-widget-wrapper {
  background-color: white;
  border-bottom: 0.5px solid #D6D6D6;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  position: sticky;
  top: 90px;
  z-index: 3; }
  @media (min-width: 576px) {
    .qualification-widget-wrapper {
      padding-bottom: 16px;
      top: 129px; } }
  .qualification-widget-wrapper .qualification-widget {
    background: linear-gradient(180deg, #4380E3 0%, #1D66DD 48.5%, #1855B8 100%);
    border-radius: 8px;
    cursor: pointer;
    padding-bottom: 6px; }
    .qualification-widget-wrapper .qualification-widget .text-content {
      padding: 14px 16px 0;
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      flex-direction: row;
      justify-content: left; }
      @media (min-width: 576px) {
        .qualification-widget-wrapper .qualification-widget .text-content {
          align-items: end;
          padding: 14px 16px 4px; } }
      .qualification-widget-wrapper .qualification-widget .text-content .text {
        font-size: 12px;
        font-weight: bold;
        color: white; }
        @media (min-width: 576px) {
          .qualification-widget-wrapper .qualification-widget .text-content .text {
            font-size: 16px; } }
      .qualification-widget-wrapper .qualification-widget .text-content .back-icon {
        height: 24px;
        width: 24px;
        margin-right: 4px; }
    .qualification-widget-wrapper .qualification-widget .progress-bar-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 16px;
      margin-left: -15px;
      margin-right: -15px; }
      .qualification-widget-wrapper .qualification-widget .progress-bar-wrapper .lp-pgbar-root {
        height: 8px; }
      .qualification-widget-wrapper .qualification-widget .progress-bar-wrapper .love-icon {
        width: 17.74px;
        height: 14.13px; }
      .qualification-widget-wrapper .qualification-widget .progress-bar-wrapper .bar-progress {
        color: white;
        font-size: 12px;
        font-weight: 700;
        margin-left: 4px; }
        @media (min-width: 576px) {
          .qualification-widget-wrapper .qualification-widget .progress-bar-wrapper .bar-progress {
            font-size: 16px; } }
