/*
* ink colors (with variations only for black and white)
*/
.recommended-articles-wrapper {
  background-color: white;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .recommended-articles-wrapper {
      padding: 48px 0; } }
  @media (min-width: 576px) {
    .recommended-articles-wrapper .recommended-articles-container {
      width: 1120px;
      margin: 0 auto; } }
  .recommended-articles-wrapper .recommended-articles-container .recommended-articles-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 4%;
    margin: 0; }
    @media (min-width: 576px) {
      .recommended-articles-wrapper .recommended-articles-container .recommended-articles-title {
        font-size: 28px;
        padding: 0; } }
  .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list {
    margin-top: 16px; }
    @media (min-width: 576px) {
      .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 24px; } }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card {
      display: flex;
      flex-direction: column;
      height: 244px;
      width: 262px;
      border-radius: 4px; }
      .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-image {
        height: 147px; }
      .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-title {
        padding: 0px 12px;
        margin-top: 12px;
        margin-bottom: 16px; }
      .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-details {
        padding: 0px 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px; }
        .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-details .recommended-articles-card-label {
          padding: 2px 6px;
          border-radius: 28px;
          border: 1px solid #8EB2EE;
          color: #1D66DD;
          font-size: 10px;
          font-weight: 600; }
        .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-details .recommended-articles-card-label:hover {
          border: 1px solid #1D66DD;
          color: white;
          background-color: #1D66DD; }
        .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card .recommended-articles-card-details .recommended-articles-card-date {
          font-size: 12px; }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card:hover {
      box-shadow: 0 2px 40px #00000029;
      text-decoration: none; }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card:hover .recommended-articles-card-title {
      font-weight: bold; }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .recommended-articles-card:visited .recommended-articles-card-title {
      font-weight: bold; }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .carousel__inner-slide {
      width: 278px !important; }
      @media (min-width: 576px) {
        .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .carousel__inner-slide {
          width: 278px !important; } }
    .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .carousel__slide {
      width: 278px !important; }
      @media (min-width: 576px) {
        .recommended-articles-wrapper .recommended-articles-container .recommended-articles-list .carousel__slide {
          width: 278px !important; } }
