/*
* ink colors (with variations only for black and white)
*/
.kyc__workplace {
  padding-bottom: calc(0.75 * 1rem); }
  @media (min-width: 768px) {
    .kyc__workplace {
      width: 400px;
      margin: auto; } }
  .kyc__workplace .dealer {
    padding: calc(1.25 * 1rem) 0;
    border-bottom: 1px dashed #333333; }
    .kyc__workplace .dealer .head {
      padding: calc(0.75 * 1rem) 0;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .kyc__workplace .dealer .head img {
        width: 40px;
        height: 40px; }
      .kyc__workplace .dealer .head span:last-child {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin-left: calc(0.5 * 1rem); }
    .kyc__workplace .dealer .body {
      display: flex;
      flex-direction: row; }
      .kyc__workplace .dealer .body .field {
        margin-right: calc(0.75 * 1rem);
        display: flex;
        flex-direction: row;
        align-items: center; }
        .kyc__workplace .dealer .body .field label {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          margin: 0;
          margin-left: calc(0.25 * 1rem); }
        .kyc__workplace .dealer .body .field input {
          width: 15px;
          height: 15px; }
  .kyc__workplace .dealer__brand {
    padding: calc(0.75 * 1rem) 0; }
    .kyc__workplace .dealer__brand label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500; }
  .kyc__workplace .work__location {
    padding: calc(0.75 * 1rem) 0; }
    .kyc__workplace .work__location .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500; }
    .kyc__workplace .work__location button {
      margin: calc(0.5 * 1rem) 0;
      width: 100%;
      display: grid;
      grid-template-columns: 10% 90%;
      background: none;
      border-radius: calc(0.25 * 1rem);
      border: 1px solid #c2c2c2; }
      .kyc__workplace .work__location button img {
        width: 30px;
        height: 30px; }
      .kyc__workplace .work__location button span:last-child {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #707070;
        padding: calc(0.5 * 1rem) 0;
        text-align: left; }
  .kyc__workplace .tnc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: calc(0.5 * 1rem) 0; }
    @media (min-width: 992px) {
      .kyc__workplace .tnc {
        margin: calc(0.75 * 1rem) 0; } }
    .kyc__workplace .tnc input {
      width: 15px;
      height: 15px;
      margin-right: calc(0.5 * 1rem); }
  .kyc__workplace .search__workplace {
    padding: calc(0.75 * 1rem) 0; }
    .kyc__workplace .search__workplace .field__wrap {
      border: 1px solid #c2c2c2;
      border-radius: calc(0.25 * 1rem);
      padding: calc(1.25 * 1rem) calc(0.75 * 1rem); }
    .kyc__workplace .search__workplace .field {
      border: 1px solid #c2c2c2;
      border-radius: calc(0.25 * 1rem);
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: calc(0.5 * 1rem); }
      .kyc__workplace .search__workplace .field img {
        width: 30px;
        height: 30px; }
      .kyc__workplace .search__workplace .field input {
        width: 100%;
        border: none; }
    .kyc__workplace .search__workplace .search__result {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      padding: calc(0.75 * 1rem) calc(0.25 * 1rem);
      border-radius: calc(0.25 * 1rem);
      width: 100%; }
      .kyc__workplace .search__workplace .search__result:hover {
        background: #e4eefd; }
      .kyc__workplace .search__workplace .search__result span:first-child {
        width: 20%;
        margin-left: calc(0.25 * 1rem); }
      .kyc__workplace .search__workplace .search__result img {
        width: 30px;
        height: 30px; }
      @media (min-width: 768px) {
        .kyc__workplace .search__workplace .search__result {
          justify-content: left; }
          .kyc__workplace .search__workplace .search__result span:first-child {
            width: 15%; }
          .kyc__workplace .search__workplace .search__result img {
            width: 35px;
            height: 35px; } }
