/*
* ink colors (with variations only for black and white)
*/
.lp-pgbar-root {
  height: 8px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden; }
  @media (min-width: 576px) {
    .lp-pgbar-root {
      height: 16px; } }
  .lp-pgbar-root .lp-pgbar-bar {
    height: 100%;
    transition: width 0.3s; }
