/*
* ink colors (with variations only for black and white)
*/
.agent__profile {
  width: 100%;
  overflow-x: hidden; }
  @media (min-width: 768px) {
    .agent__profile {
      max-width: 1140px;
      padding: 15px;
      margin-right: auto;
      margin-left: auto; } }
  .agent__profile--row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .agent__profile--row .row__left {
      flex: 0 0 35%;
      max-width: 35%; }
      @media (max-width: 991.98px) {
        .agent__profile--row .row__left {
          flex: 0 0 100%;
          max-width: 100%; } }
    .agent__profile--row .row__right {
      flex: 0 0 65%;
      max-width: 65%; }
      @media (max-width: 991.98px) {
        .agent__profile--row .row__right {
          flex: 0 0 100%;
          max-width: 100%; } }
  .agent__profile .agent__profile--row .row__left, .agent__profile--row .agent__profile .row__left, .agent__profile .agent__profile--row .row__right, .agent__profile--row .agent__profile .row__right {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
