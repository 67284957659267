/*
* ink colors (with variations only for black and white)
*/
.dn-root {
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: 48px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
  background-color: #ffffff; }
  @media (min-width: 1200px) {
    .dn-root {
      height: 68px; } }
  .dn-root .container {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    transition: all 0.5s ease 0.1s;
    width: 100%;
    height: 100%; }
    @media (min-width: 768px) {
      .dn-root .container {
        max-width: 750px; } }
    @media (min-width: 992px) {
      .dn-root .container {
        max-width: 970px; } }
    @media (min-width: 1200px) {
      .dn-root .container {
        max-width: 1120px; } }
    @media (max-width: 768px) {
      .dn-root .container {
        align-items: center;
        height: inherit; } }

.offcanvas-backdrop {
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1029;
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: all; }
  .offcanvas-backdrop.show {
    display: block;
    opacity: 1; }
  @media (min-width: 1200px) {
    .offcanvas-backdrop {
      display: none !important; } }
