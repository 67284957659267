/*
* ink colors (with variations only for black and white)
*/
.policyMember__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .policyMember__wrapper--head {
    display: flex;
    flex-direction: row;
    overflow: auto;
    border-bottom: 1px solid #dfdfdf; }
    .policyMember__wrapper--head .item__title {
      display: inline-flex;
      padding: calc(1.25 * 1rem);
      cursor: pointer; }
      .policyMember__wrapper--head .item__title.active {
        color: #1D66DD;
        border-bottom: 2px solid #1D66DD; }
      @media (max-width: 991.98px) {
        .policyMember__wrapper--head .item__title span {
          width: max-content;
          display: flex;
          align-items: center; } }
  .policyMember__wrapper--body {
    padding: calc(calc(0.25 * 1rem) + calc(1.25 * 1rem)); }
    .policyMember__wrapper--body div {
      display: flex; }
      .policyMember__wrapper--body div:not(:last-child) {
        margin-bottom: calc(1.25 * 1rem); }
      .policyMember__wrapper--body div span {
        width: 15%; }
        @media (max-width: 991.98px) {
          .policyMember__wrapper--body div span {
            width: 50%; } }
      .policyMember__wrapper--body div span:last-child {
        width: auto; }
        @media (max-width: 991.98px) {
          .policyMember__wrapper--body div span:last-child {
            width: 50%;
            word-wrap: break-word; } }
