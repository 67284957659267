/*
* ink colors (with variations only for black and white)
*/
.payment-method__view {
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
  .payment-method__view--item {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) 0;
    border-bottom: 1px solid #dfdfdf; }
    .payment-method__view--item .item__header {
      display: flex;
      cursor: pointer; }
      .payment-method__view--item .item__header--right {
        margin-left: auto;
        color: #1D66DD; }
    .payment-method__view--item .item__body {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
      margin-top: calc(0.75 * 1rem); }
      .payment-method__view--item .item__body label {
        cursor: pointer; }

@media (min-width: 768px) {
  .payment-detail__view .navbar.navbar-expand-lg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10; } }
