/*
* ink colors (with variations only for black and white)
*/
.tso__dashboard {
  background-color: #f2f7ff;
  padding: calc(1.25 * 1rem) 0; }
  @media (max-width: 991.98px) {
    .tso__dashboard {
      padding: calc(1.25 * 1rem); } }
  .tso__dashboard .refresh__wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: calc(0.75 * 1rem); }
    .tso__dashboard .refresh__wrapper button {
      color: #1D66DD; }
      .tso__dashboard .refresh__wrapper button i {
        margin-right: calc(0.5 * 1rem); }
  .tso__dashboard .tool__bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(1.25 * 1rem);
    padding-left: 0;
    cursor: pointer; }
    .tso__dashboard .tool__bar span {
      color: #1D66DD;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400; }
    .tso__dashboard .tool__bar i {
      margin-right: calc(0.25 * 1rem);
      vertical-align: middle; }
    .tso__dashboard .tool__bar button {
      color: #1D66DD; }
  .tso__dashboard .tso__list--table {
    padding: calc(0.75 * 1rem) 0;
    margin-top: calc(1.25 * 1rem); }
    .tso__dashboard .tso__list--table .search_container {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 767.98px) {
        .tso__dashboard .tso__list--table .search_container {
          display: block; } }
      .tso__dashboard .tso__list--table .search_container .search__box {
        width: 350px;
        margin-right: calc(1.25 * 1rem); }
        @media (max-width: 767.98px) {
          .tso__dashboard .tso__list--table .search_container .search__box {
            margin-bottom: calc(0.75 * 1rem);
            width: 100%; } }
        .tso__dashboard .tso__list--table .search_container .search__box input {
          border-radius: calc(1.25 * 1rem); }
      @media (min-width: 992px) {
        .tso__dashboard .tso__list--table .search_container .lead__dropdown {
          width: 800px; } }
      @media (max-width: 1199.98px) {
        .tso__dashboard .tso__list--table .search_container .lead__dropdown {
          width: 100%; } }
    .tso__dashboard .tso__list--table .TableData {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      margin-top: calc(1.25 * 1rem);
      border-radius: calc(0.5 * 1rem); }
  .tso__dashboard .supervisor__metrics {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    grid-gap: calc(1.25 * 1rem);
    padding: 0; }
    @media (max-width: 767.98px) {
      .tso__dashboard .supervisor__metrics {
        grid-template-columns: 1fr; } }
    .tso__dashboard .supervisor__metrics .metric__card {
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      border-radius: calc(0.75 * 1rem);
      padding: calc(0.75 * 1rem);
      display: grid;
      grid-template-columns: 1fr 0.75fr;
      grid-gap: calc(1.25 * 1rem);
      position: relative; }
      @media (max-width: 767.98px) {
        .tso__dashboard .supervisor__metrics .metric__card {
          grid-template-columns: 1fr !important; } }
      @media (min-width: 768px) {
        .tso__dashboard .supervisor__metrics .metric__card .left__panel--metrics {
          border-right: 1px solid #a5c2f1; } }
      @media (max-width: 767.98px) {
        .tso__dashboard .supervisor__metrics .metric__card .left__panel--metrics {
          border-bottom: 1px solid #a5c2f1; } }
      .tso__dashboard .supervisor__metrics .metric__card .metrics {
        display: flex;
        align-items: center;
        margin-bottom: calc(0.5 * 1rem); }
        .tso__dashboard .supervisor__metrics .metric__card .metrics span {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          width: 50%; }
        .tso__dashboard .supervisor__metrics .metric__card .metrics .metric__label {
          color: #77a3eb;
          width: 85%; }
        @media (max-width: 991.98px) {
          .tso__dashboard .supervisor__metrics .metric__card .metrics .metric__value {
            text-align: center; } }
    .tso__dashboard .supervisor__metrics .detail__view--status {
      grid-template-columns: 0.75fr 1fr; }

.head__name {
  margin-bottom: 0; }
