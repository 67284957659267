/*
* ink colors (with variations only for black and white)
*/
.question-date-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%; }
  .question-date-wrapper .date-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 12px; }
    .question-date-wrapper .date-content .date-box-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .question-date-wrapper .date-content .date-box-wrapper .date_box {
        height: 52px;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #1D66DD;
        background-color: #F6F9FE;
        text-align: center;
        font-size: 18px !important;
        font-weight: 600;
        outline: none; }
        .question-date-wrapper .date-content .date-box-wrapper .date_box:invalid {
          background-color: #F6F9FE; }
        .question-date-wrapper .date-content .date-box-wrapper .date_box:valid {
          background-color: #1D66DD;
          color: white; }
        @media (min-width: 576px) {
          .question-date-wrapper .date-content .date-box-wrapper .date_box {
            height: 56px; } }
      .question-date-wrapper .date-content .date-box-wrapper .date_box_label {
        font-size: 14px;
        margin-top: 12px; }
