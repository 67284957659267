/*
* ink colors (with variations only for black and white)
*/
.why-lifepal-wrapper {
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media (min-width: 576px) {
    .why-lifepal-wrapper {
      border-radius: 40px 40px 0px 0px;
      padding: 48px 0; } }
  .why-lifepal-wrapper .why-lifepal-title {
    font-size: 20px;
    font-weight: bold;
    padding: 0 16px;
    margin: 0; }
    @media (min-width: 576px) {
      .why-lifepal-wrapper .why-lifepal-title {
        font-size: 28px;
        padding: 0;
        width: 1120px;
        margin: 0 auto; } }
  .why-lifepal-wrapper .why-lifepal-subtitle {
    font-size: 14px;
    padding: 0 16px;
    margin: 8px 0 0 0; }
    @media (min-width: 576px) {
      .why-lifepal-wrapper .why-lifepal-subtitle {
        font-size: 14px;
        padding: 0;
        width: 1120px;
        margin: 8px auto 0 auto; } }
  .why-lifepal-wrapper .why-lifepal-card-wrapper {
    margin-top: 16px; }
    @media (min-width: 576px) {
      .why-lifepal-wrapper .why-lifepal-card-wrapper {
        margin-top: 24px; } }
    .why-lifepal-wrapper .why-lifepal-card-wrapper .why-lifepal-card {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 8px;
      background-color: #F5F5F5;
      height: 272px;
      width: 285px; }
      @media (min-width: 576px) {
        .why-lifepal-wrapper .why-lifepal-card-wrapper .why-lifepal-card {
          width: 262px; } }
      .why-lifepal-wrapper .why-lifepal-card-wrapper .why-lifepal-card .why-lifepal-card-icon {
        height: 56px;
        width: 56px; }
      .why-lifepal-wrapper .why-lifepal-card-wrapper .why-lifepal-card .why-lifepal-card-title {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        padding-top: 4px; }
      .why-lifepal-wrapper .why-lifepal-card-wrapper .why-lifepal-card .why-lifepal-card-description {
        font-size: 16px;
        margin: 0;
        padding-top: 4px; }
    .why-lifepal-wrapper .why-lifepal-card-wrapper .carousel__inner-slide {
      width: 301px !important; }
      @media (min-width: 576px) {
        .why-lifepal-wrapper .why-lifepal-card-wrapper .carousel__inner-slide {
          width: 286px !important; } }
    .why-lifepal-wrapper .why-lifepal-card-wrapper .carousel__slide {
      width: 301px !important; }
      @media (min-width: 576px) {
        .why-lifepal-wrapper .why-lifepal-card-wrapper .carousel__slide {
          width: 286px !important; } }
