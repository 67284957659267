/*
* ink colors (with variations only for black and white)
*/
.lead__dropdown .main {
  display: flex;
  flex-wrap: wrap; }

.lead__dropdown .wrapper {
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: auto;
  z-index: 101; }
  @media (max-width: 991.98px) {
    .lead__dropdown .wrapper {
      flex: 0 0 100%;
      max-width: 100%; } }

.lead__dropdown .css-tlfecz-indicatorContainer, .lead__dropdown .css-1gtu0rj-indicatorContainer {
  color: #1D66DD; }
  .lead__dropdown .css-tlfecz-indicatorContainer:hover, .lead__dropdown .css-1gtu0rj-indicatorContainer:hover {
    color: #1D66DD; }

.lead__dropdown .css-1hwfws3 {
  display: flex;
  justify-content: center; }

.lead__dropdown .css-g1d714-ValueContainer {
  position: static; }
