/*
* ink colors (with variations only for black and white)
*/
body {
  padding-top: 50px !important; }
  @media (min-width: 1200px) {
    body {
      padding-top: 70px !important; } }

.qualify-header-root {
  background-color: #134493;
  background-image: linear-gradient(to left, #4380E3, #1855B8, #134493); }
  @media (min-width: 576px) {
    .qualify-header-root {
      padding-bottom: 12px; } }
  .qualify-header-root .qh-content {
    background-color: transparent;
    padding-top: 14px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 576px) {
      .qualify-header-root .qh-content {
        margin-bottom: 14px;
        align-items: end; } }
    .qualify-header-root .qh-content .qh-bubble-wrap {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-right: 20px; }
      .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text {
        display: flex;
        padding: 8px 20px;
        width: 100%;
        border-radius: 12px;
        background-color: #f6f9fe; }
        @media (min-width: 576px) {
          .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text {
            width: 986px; } }
        .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text {
          color: #134493;
          line-height: 16px;
          word-wrap: break-word;
          font-weight: 700; }
          @media (min-width: 576px) {
            .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text {
              color: #134493;
              font-size: 18px;
              line-height: 24px; } }
          @media (min-width: 992px) {
            .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text {
              color: #134493;
              font-size: 28px;
              line-height: 36px; } }
          .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text .qh-subtext {
            font-weight: 400;
            line-height: 20px; }
            @media (min-width: 576px) {
              .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text .qh-subtext {
                line-height: 28px; } }
            @media (min-width: 992px) {
              .qualify-header-root .qh-content .qh-bubble-wrap .qh-bubble-text .qh-text .qh-subtext {
                line-height: 48px; } }
      .qualify-header-root .qh-content .qh-bubble-wrap .qh-right-arrow {
        margin-left: -2px;
        margin-top: 30px;
        display: flex;
        flex-direction: column; }
        @media (min-width: 576px) {
          .qualify-header-root .qh-content .qh-bubble-wrap .qh-right-arrow {
            margin-top: 40px; } }
        .qualify-header-root .qh-content .qh-bubble-wrap .qh-right-arrow .qh-right-arrow-item {
          flex-direction: column;
          border: 6px solid #f6f9fe;
          border-top-color: transparent;
          border-right-color: transparent; }
          @media (min-width: 576px) {
            .qualify-header-root .qh-content .qh-bubble-wrap .qh-right-arrow .qh-right-arrow-item {
              border-width: 10px; } }
          .qualify-header-root .qh-content .qh-bubble-wrap .qh-right-arrow .qh-right-arrow-item:last-of-type {
            border-top-color: #f6f9fe;
            border-bottom-color: transparent; }
    .qualify-header-root .qh-content .qh-mascot {
      width: 95px;
      height: 99px;
      margin-bottom: -32px;
      margin-left: -53px; }
      @media (min-width: 576px) {
        .qualify-header-root .qh-content .qh-mascot {
          width: 151px;
          height: 161px;
          margin-bottom: -62px;
          margin-right: 53px; } }
  .qualify-header-root .qh-pgbar {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 16px;
    margin-left: -15px;
    margin-right: 61px; }
    @media (min-width: 576px) {
      .qualify-header-root .qh-pgbar {
        margin-right: 91px; } }
    .qualify-header-root .qh-pgbar .love-icon {
      width: 17.74px;
      height: 14.13px; }
      @media (min-width: 576px) {
        .qualify-header-root .qh-pgbar .love-icon {
          width: 28.74px;
          height: 22.89px; } }
    .qualify-header-root .qh-pgbar .bar-progress {
      color: #fff;
      font-size: 12px;
      font-weight: 700; }
      @media (min-width: 576px) {
        .qualify-header-root .qh-pgbar .bar-progress {
          font-size: 20px;
          line-height: 24px;
          min-width: 53px; } }
