/*
* ink colors (with variations only for black and white)
*/
.member__list--psf .member__item {
  margin-top: calc(0.75 * 1rem); }

.member__list--psf .member__form--action {
  display: flex;
  flex-direction: column;
  margin-top: calc(1.25 * 1rem); }
  .member__list--psf .member__form--action button:last-child {
    margin-top: calc(0.75 * 1rem); }
