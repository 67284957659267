/*
* ink colors (with variations only for black and white)
*/
.bantuan__form {
  margin-top: calc(1.25 * 1rem); }
  .bantuan__form h1 {
    font-size: 40px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    margin-bottom: calc(0.75 * 1rem); }
    @media (max-width: 991.98px) {
      .bantuan__form h1 {
        font-size: 28px; } }
  .bantuan__form .form__instruction {
    white-space: nowrap; }
    @media (max-width: 991.98px) {
      .bantuan__form .form__instruction {
        white-space: pre-line; } }
  .bantuan__form .detail__instruction {
    white-space: pre-line; }
  @media (min-width: 768px) {
    .bantuan__form .issue__dropdown {
      width: 50%; } }
  .bantuan__form_container {
    margin-top: calc(1.25 * 1rem); }
    .bantuan__form_container--field {
      margin-bottom: calc(1.25 * 1rem); }
      .bantuan__form_container--field option {
        color: #333333; }
      .bantuan__form_container--field .DropdownForm .Wrapper select {
        color: #333333;
        background: transparent; }
      .bantuan__form_container--field .subtitle {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #717171; }
      .bantuan__form_container--field .lampiranText {
        margin-bottom: calc(0.5 * 1rem); }
      .bantuan__form_container--field .uploadBtn {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        width: 100%;
        color: #717171;
        border: 1px solid #c2c2c2;
        background: transparent;
        padding: calc(0.75 * 1rem) calc(0.25 * 1rem); }
      .bantuan__form_container--field .fileName--container {
        margin: calc(0.25 * 1rem) 0;
        display: flex;
        flex-direction: row; }
    .bantuan__form_container--submitBtn {
      width: 30%;
      border: none;
      border-radius: calc(0.25 * 1rem);
      padding: calc(0.5 * 1rem) calc(0.25 * 1rem);
      background: #1D66DD;
      color: #ffffff; }
  .bantuan__form_footer {
    margin-top: calc(3.25 * 1rem); }
  .bantuan__form_complaint {
    margin-top: 20px;
    font-size: 14px; }
    .bantuan__form_complaint .support__email {
      white-space: nowrap; }
      @media (max-width: 991.98px) {
        .bantuan__form_complaint .support__email {
          white-space: pre-line; } }
    .bantuan__form_complaint_title {
      font-size: 12px;
      margin-top: 20px;
      font-weight: 700; }
      @media (min-width: 992px) {
        .bantuan__form_complaint_title {
          font-size: 14px; } }
    .bantuan__form_complaint_address {
      font-size: 12px;
      margin-top: 20px;
      font-weight: 500; }
      @media (min-width: 992px) {
        .bantuan__form_complaint_address {
          font-size: 14px; } }
