/*
* ink colors (with variations only for black and white)
*/
.payment-status__wrapper {
  min-height: calc(100vh - 75px);
  max-width: 30vw;
  margin: calc(1.25 * 1rem) auto; }
  @media (max-width: 991.98px) {
    .payment-status__wrapper {
      max-width: 100%; } }
  .payment-status__wrapper--body {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: calc(0.5 * 1rem);
    padding: calc(2 * 1rem) calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
    text-align: center; }
    .payment-status__wrapper--body .body__icon {
      text-align: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 70px;
      margin-bottom: calc(1.25 * 1rem);
      color: white; }
      .payment-status__wrapper--body .body__icon.success {
        background-color: #5cc197; }
      .payment-status__wrapper--body .body__icon.failure {
        background-color: #ea4b4b; }
    .payment-status__wrapper--body .body__button {
      width: 100%; }
  .payment-status__wrapper .ion-md-arrow-back {
    visibility: hidden; }
