/*
* ink colors (with variations only for black and white)
*/
.agentInfo__card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: calc(0.75 * 1rem);
  background-color: #ffffff;
  padding: calc(1.25 * 1rem);
  margin-bottom: calc(1.25 * 1rem); }
  .agentInfo__card--head {
    display: flex; }
    .agentInfo__card--head .head__name {
      color: #717171;
      margin-left: calc(0.25 * 1rem);
      text-transform: capitalize; }
