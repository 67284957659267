.ModalSpecification {
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  background-color: white; }
  @media (min-width: 992px) {
    .ModalSpecification {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .ModalSpecification {
      box-shadow: none;
      padding: 10px 20px 0;
      border-bottom: 0.5px solid #dfdfdf;
      border-top: 0.5px solid #dfdfdf; } }
  .ModalSpecification--planInformation {
    margin-bottom: 10px; }
    @media (max-width: 767.98px) {
      .ModalSpecification--planInformation {
        padding-right: 0px; } }
    .ModalSpecification--planInformation h1 {
      color: #333333;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      text-transform: capitalize; }
    .ModalSpecification--planInformation span {
      font-size: 12px;
      color: #b6b6b6; }
  .ModalSpecification--actionPlanInformation button {
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    color: #333333;
    font-size: 12px;
    font-weight: 500; }
    @media (min-width: 992px) {
      .ModalSpecification--actionPlanInformation button {
        width: 113px; } }
  .ModalSpecification--DesktopOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1031; }
  .ModalSpecification--Wrapping {
    position: relative;
    width: 100%;
    height: 100%; }
    .ModalSpecification--Wrapping_hide {
      display: none; }
  .ModalSpecification--isModalClose {
    display: none; }
  .ModalSpecification--modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1032;
    border-radius: 12px; }
    @media (max-width: 767.98px) {
      .ModalSpecification--modal {
        border-radius: 20px 20px 5px 5px;
        width: 100%;
        height: auto !important;
        bottom: 0;
        top: auto;
        left: 0;
        overflow: auto;
        transform: none; } }
    .ModalSpecification--modal .Header {
      display: flex;
      flex-direction: column;
      border-bottom: unset;
      margin: 0;
      padding-top: 12px;
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 10px;
      top: 10; }
      .ModalSpecification--modal .Header h1 {
        font-size: 20px;
        font-weight: bold;
        font-weight: 700;
        color: #FFFFFF;
        margin-top: 12px; }
      .ModalSpecification--modal .Header div {
        display: flex;
        flex: 1;
        cursor: pointer; }
      .ModalSpecification--modal .Header div:last-child {
        justify-content: right; }
      .ModalSpecification--modal .Header h2 {
        font-size: 14px;
        font-weight: bold;
        color: #1D66DD; }
      .ModalSpecification--modal .Header span {
        margin: 5px 10px auto;
        font-weight: bold;
        font-size: 17px;
        width: 25px;
        text-align: center;
        color: #dfdfdf;
        height: 25px;
        margin-left: auto; }
      .ModalSpecification--modal .Header i {
        font-size: 25px;
        font-weight: 700;
        color: #1d66dd;
        cursor: pointer; }
  .ModalSpecification--life {
    max-width: 700px; }
    @media (max-width: 767.98px) {
      .ModalSpecification--life {
        width: 100%;
        height: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        overflow: auto; } }
  .ModalSpecification--travel {
    max-width: 700px; }
    @media (max-width: 767.98px) {
      .ModalSpecification--travel {
        width: 100%;
        height: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        overflow: auto; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .ModalSpecification--travel {
        height: calc(100vh - 7rem);
        overflow: auto; } }
    .ModalSpecification--travel .Header {
      border: none; }
  .ModalSpecification--health_individu, .ModalSpecification--health_family {
    width: 629px;
    heigth: auto; }
    @media (max-width: 767.98px) {
      .ModalSpecification--health_individu, .ModalSpecification--health_family {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: auto; } }
  .ModalSpecification--auto {
    background-color: #4380E3; }
  .ModalSpecification--moto, .ModalSpecification--micro-rawat-inap, .ModalSpecification--gadget, .ModalSpecification--bike, .ModalSpecification--pet, .ModalSpecification--personal-accident, .ModalSpecification--health, .ModalSpecification--syariah, .ModalSpecification--property {
    max-width: 700px;
    max-height: 80vh;
    overflow: auto; }
    @media (max-width: 767.98px) {
      .ModalSpecification--moto, .ModalSpecification--micro-rawat-inap, .ModalSpecification--gadget, .ModalSpecification--bike, .ModalSpecification--pet, .ModalSpecification--personal-accident, .ModalSpecification--health, .ModalSpecification--syariah, .ModalSpecification--property {
        width: 100%;
        height: 82%;
        bottom: 0;
        left: 0;
        overflow: auto; } }
  .ModalSpecification--middle {
    max-width: 440px;
    border-radius: 12px;
    max-height: unset;
    overflow: auto; }
