/*
* ink colors (with variations only for black and white)
*/
.offer__wrapper {
  min-height: calc(100vh - 75px);
  background: #1D66DD;
  padding-top: calc(2 * 1rem); }
  @media (max-width: 991.98px) {
    .offer__wrapper {
      min-height: calc(100vh - 50px);
      padding-top: calc(1.25 * 1rem); } }
  .offer__wrapper img {
    height: 32px !important;
    width: 32px !important; }
  .offer__wrapper--title {
    font-size: 32px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin-bottom: calc(2 * 1rem); }
    @media (max-width: 991.98px) {
      .offer__wrapper--title {
        font-size: 22px;
        margin-bottom: calc(0.75 * 1rem); } }
  .offer__wrapper--body {
    display: flex;
    max-width: 800px;
    margin: 0 auto; }
    .offer__wrapper--body .body__row {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 768px) {
        .offer__wrapper--body .body__row {
          margin-right: -15px;
          margin-left: -15px; } }
      .offer__wrapper--body .body__row--item {
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 15px;
        padding-left: 15px; }
        @media (max-width: 991.98px) {
          .offer__wrapper--body .body__row--item {
            flex: 0 0 50%;
            max-width: 50%; }
            .offer__wrapper--body .body__row--item:nth-child(odd) {
              padding-right: 7.5px; }
            .offer__wrapper--body .body__row--item:nth-child(even) {
              padding-left: 7.5px; } }
        .offer__wrapper--body .body__row--item .card__wrapper {
          padding: calc(0.5 * 1rem) 0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
          border-radius: calc(0.25 * 1rem);
          margin-bottom: calc(0.75 * 1rem);
          background: #ffffff;
          cursor: pointer; }
          .offer__wrapper--body .body__row--item .card__wrapper--image {
            display: flex;
            justify-content: center;
            margin-bottom: calc(0.25 * 1rem); }
          .offer__wrapper--body .body__row--item .card__wrapper--title {
            margin-bottom: 0;
            text-align: center;
            color: #717171; }
