/*
* ink colors (with variations only for black and white)
*/
.search__box {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background: #FFFFFF;
  border: 1px solid rgba(105, 105, 116, 0.4);
  border-radius: 4px; }
  .search__box i {
    align-self: center;
    position: absolute;
    font-size: 18px; }
  .search__box .search-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    padding: calc(1.25 * 1rem);
    padding-left: calc(2 * 1rem); }
    .search__box .search-input:focus {
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .search__box .search__suggestion--container {
    position: absolute;
    top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 180px;
    overflow: scroll;
    background: #fff;
    list-style: none;
    margin-left: 0;
    width: 100%;
    padding-left: 0;
    padding-top: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border-radius: calc(0.25 * 1rem); }
  .search__box .search__suggestion--item {
    text-transform: capitalize;
    cursor: pointer;
    color: #717171;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: calc(0.5 * 1rem); }
    .search__box .search__suggestion--item:hover {
      background-color: #f2f7ff;
      color: #1D66DD; }
