/*
* ink colors (with variations only for black and white)
*/
.question-button-wrapper {
  position: relative; }
  .question-button-wrapper .button-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px; }
    .question-button-wrapper .button-content .button-content__btn {
      border: 1px solid #1D66DD;
      border-radius: 8px;
      color: #1D66DD;
      height: unset;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      word-wrap: break-word;
      width: 100%;
      display: flex;
      padding: 16px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch; }
      @media (min-width: 992px) {
        .question-button-wrapper .button-content .button-content__btn {
          font-size: 18px;
          line-height: 24px;
          gap: 4px; } }
    .question-button-wrapper .button-content .active {
      background-color: #1D66DD;
      color: #ffffff; }
  .question-button-wrapper .question-list-wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #1D66DD;
    border-radius: 8px;
    padding: 12px 24px;
    margin-bottom: 12px;
    height: 200px;
    overflow-y: scroll; }
    .question-button-wrapper .question-list-wrapper .item {
      margin-bottom: 8px; }
