/*
* ink colors (with variations only for black and white)
*/
.consult-sticky-container {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 16px 0px rgba(0, 58, 109, 0.32); }
  .consult-sticky-container .cps-label {
    padding: 4px 0px;
    background: linear-gradient(245.39deg, #4380E3 0%, #1855B8 50%, #134493 100%);
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    /* 125% */
    margin: 0 auto; }
  .consult-sticky-container .cps-content {
    position: relative;
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center; }
    .consult-sticky-container .cps-content .cps-blueman-contact {
      position: absolute;
      top: -27px;
      margin: 0 16px;
      z-index: -1;
      transition: top 1s;
      transition-timing-function: ease-out; }
      .consult-sticky-container .cps-content .cps-blueman-contact.cps-animate {
        top: -103px; }
  .consult-sticky-container .cps-buttons {
    display: flex;
    width: 100%; }
  .consult-sticky-container .cps-button {
    font-weight: 700;
    border-radius: 0px;
    padding: 21px 0 16px;
    font-size: 14px; }
    .consult-sticky-container .cps-button img {
      height: 20px;
      width: 20px; }
  .consult-sticky-container .call-button img {
    filter: invert(98%) sepia(5%) saturate(299%) hue-rotate(235deg) brightness(120%) contrast(100%);
    color: white; }
